var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content widfix" },
    [
      _c("div", { staticClass: "location" }, [
        _c("ul", [
          _vm._m(0),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "http://52.231.38.202/pubcom/UTIL030G010.html#",
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.038")))]
            ),
          ]),
          _c("li", [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.014")) + " ")]),
        ]),
      ]),
      _c("form", { attrs: { id: "frm" } }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(" " + _vm._s(_vm.$t("msg.MYIN010G010.014")) + " "),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("p", { staticClass: "color_black mt5" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.MYIN010G010.015")) + " "),
          ]),
          _c(
            "p",
            { staticClass: "agree_check all" },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "agree_check all",
                  on: {
                    change: function ($event) {
                      return _vm.checkboxControl("all")
                    },
                  },
                  model: {
                    value: _vm.check.all,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "all", $$v)
                    },
                    expression: "check.all",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("msg.MYIN010G010.016")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "agree_check mt15" },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "agree_check mt15",
                  on: {
                    change: function ($event) {
                      return _vm.checkboxControl("a")
                    },
                  },
                  model: {
                    value: _vm.check.a,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "a", $$v)
                    },
                    expression: "check.a",
                  },
                },
                [
                  _c("span", { staticClass: "color_red" }, [
                    _vm._v("[" + _vm._s(_vm.$t("msg.MYIN010G010.017")) + "] "),
                  ]),
                  _vm._v(_vm._s(_vm.$t("msg.MYIN010G010.018")) + " "),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "agree_box" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("approval.001")) + " ")]),
            _c("p", { staticClass: "tit mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.002")) + " "),
            ]),
            _c("ul", [
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.003")) + " ")]),
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.004")) + " ")]),
            ]),
            _c("p", { staticClass: "tit mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.005")) + " "),
            ]),
            _c("ul", [
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.006")) + " ")]),
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.007")) + " ")]),
            ]),
            _c("p", { staticClass: "tit mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.008")) + " "),
            ]),
            _c("ul", [
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.009")) + " ")]),
            ]),
            _c("p", { staticClass: "tit mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.010")) + " "),
            ]),
            _c("ul", [
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.011")) + " ")]),
            ]),
            _c("p", { staticClass: "mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.032")) + " "),
            ]),
          ]),
          _c(
            "p",
            { staticClass: "agree_check" },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "agree_check mt15",
                  on: {
                    change: function ($event) {
                      return _vm.checkboxControl("b")
                    },
                  },
                  model: {
                    value: _vm.check.b,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "b", $$v)
                    },
                    expression: "check.b",
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("approval.012")))])]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "agree_box" }, [
            _c("p", { staticClass: "tit" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.013")) + " "),
            ]),
            _c("ul", [
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.014")) + " ")]),
            ]),
            _c("p", { staticClass: "tit mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.015")) + " "),
            ]),
            _c("ul", [
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.016")) + " ")]),
            ]),
            _c("p", { staticClass: "tit mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.017")) + " "),
            ]),
            _c("ul", [
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.018")) + " ")]),
            ]),
            _c("p", { staticClass: "tit mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.019")) + " "),
            ]),
            _c("ul", [
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("approval.020")) + " ")]),
            ]),
            _c("p", { staticClass: "mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.029")) + " "),
            ]),
          ]),
          _c(
            "p",
            { staticClass: "agree_check" },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "agree_check mt15",
                  on: {
                    change: function ($event) {
                      return _vm.checkboxControl("c")
                    },
                  },
                  model: {
                    value: _vm.check.c,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "c", $$v)
                    },
                    expression: "check.c",
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("approval.021")))])]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "agree_box" }, [
            _c("p", { staticClass: "tit" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.022")) + " "),
            ]),
            _c("ul", [
              _c("li", [_vm._v(_vm._s(_vm.$t("approval.023")))]),
              _vm._v(" "),
              _c("li", [_vm._v(_vm._s(_vm.$t("approval.024")))]),
              _vm._v(" "),
              _c("li", [_vm._v(_vm._s(_vm.$t("approval.025")))]),
              _vm._v(" "),
              _c("li", [_vm._v(_vm._s(_vm.$t("approval.026")))]),
            ]),
            _c("p", { staticClass: "tit mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.027")) + " "),
            ]),
            _c("p", { staticClass: "mt20" }, [
              _vm._v(" " + _vm._s(_vm.$t("approval.030")) + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flex_box mt10" }, [
          _vm.$route.params.checkView === "fromProfile" ||
          _vm.$route.params.checkView === "beforeProfileHeader" ||
          _vm.$route.params.checkView === "selectInProfile" ||
          _vm.$route.params.checkView === "beforeProfile"
            ? _c(
                "button",
                {
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.saveProfileInfo.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("msg.MYIN050T010.056")) + " ")]
              )
            : _vm.$route.params.checkView === "changeProfile"
            ? _c(
                "button",
                {
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.changeProfileAlert.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("msg.MYIN050T010.056")) + " ")]
              )
            : _c(
                "button",
                {
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.joinMemberForm.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("msg.MYIN050T010.056")) + " ")]
              ),
          _vm.$route.params.checkView === "fromProfile" ||
          _vm.$route.params.checkView === "selectInProfile"
            ? _c(
                "button",
                {
                  staticClass: "button gray lg",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.openCheckAlert(
                        _vm.$t("msg.MYIN010G010.057"),
                        _vm.backToProfile,
                        false
                      )
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX010P100.033")) + " ")]
              )
            : _c(
                "button",
                {
                  staticClass: "button gray lg",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$router.push("/main")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX010P100.033")) + " ")]
              ),
        ]),
      ]),
      _c("EHeaderUtil", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "EHeaderUtil",
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          staticClass: "home",
          attrs: { href: "http://52.231.38.202/pubcom/UTIL030G010.html#" },
        },
        [_vm._v("home")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }