var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container_ekmtc" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("h1", { staticClass: "page_title" }, [_vm._v("팝업 리스트")]),
        _c(
          "win-layer-pop",
          { staticClass: "sample_popup_list" },
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: { "parent-info": _vm.parentInfo },
                  on: { close: _vm.closePopup },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_type" }, [
            _vm._m(0),
            _vm._m(1),
            _c("tbody", [
              _c("tr", [
                _vm._m(2),
                _c("td", [_vm._v("/trans/bookings/transCompany")]),
                _c("td"),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("TransCompanyPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    "@parentSelect= 자식function select시 부모 컴포넌트에서 매핑"
                  ),
                ]),
              ]),
              _c("tr", [
                _vm._m(3),
                _c("td", [_vm._v("/trans/pickup/findPickUpPlace")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" selectRow='선택한 Place Row No' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PickUpSearchPop.selectRow,
                          expression: "popupParams.PickUpSearchPop.selectRow",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PickUpSearchPop.selectRow,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PickUpSearchPop,
                            "selectRow",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" cntrSize='컨테이너 사이즈' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PickUpSearchPop.cntrSize,
                          expression: "popupParams.PickUpSearchPop.cntrSize",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PickUpSearchPop.cntrSize,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PickUpSearchPop,
                            "cntrSize",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" cntrType='컨테이너 타입' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PickUpSearchPop.cntrType,
                          expression: "popupParams.PickUpSearchPop.cntrType",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PickUpSearchPop.cntrType,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PickUpSearchPop,
                            "cntrType",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polPortCd='항구 CODE' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PickUpSearchPop.polPortCd,
                          expression: "popupParams.PickUpSearchPop.polPortCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PickUpSearchPop.polPortCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PickUpSearchPop,
                            "polPortCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" pickupDate='컨테이너 픽업날짜' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PickUpSearchPop.pickupDate,
                          expression: "popupParams.PickUpSearchPop.pickupDate",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PickUpSearchPop.pickupDate,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PickUpSearchPop,
                            "pickupDate",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("PickUpSearchPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _vm._m(4),
              ]),
              _c("tr", [
                _vm._m(5),
                _c("td", [_vm._v("/trans/sr/pick-up-Place")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" selectRow='선택한 컨테이너 row No' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SrPickUpPlacePop.selectRow,
                          expression: "popupParams.SrPickUpPlacePop.selectRow",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SrPickUpPlacePop.selectRow,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SrPickUpPlacePop,
                            "selectRow",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v("hidPolCtrCode='부킹 지역 코드'"),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" cntrSize='컨테이너 사이즈' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SrPickUpPlacePop.cntrSize,
                          expression: "popupParams.SrPickUpPlacePop.cntrSize",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SrPickUpPlacePop.cntrSize,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SrPickUpPlacePop,
                            "cntrSize",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" cntrType='컨테이너 타입' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SrPickUpPlacePop.cntrType,
                          expression: "popupParams.SrPickUpPlacePop.cntrType",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SrPickUpPlacePop.cntrType,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SrPickUpPlacePop,
                            "cntrType",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" pickUpDate='컨테이너 픽업날짜' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SrPickUpPlacePop.pickUpDate,
                          expression: "popupParams.SrPickUpPlacePop.pickUpDate",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SrPickUpPlacePop.pickUpDate,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SrPickUpPlacePop,
                            "pickUpDate",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" hidPolPortCode='부킹지역코드(세션)' "),
                    _c("span", { staticClass: "txt_fr" }, [
                      _vm._v(
                        _vm._s(
                          this.memberDetail !== undefined &&
                            this.memberDetail.bkgPlcCd !== undefined
                            ? this.memberDetail.bkgPlcCd
                            : "로그인필요!!"
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("SrPickUpPlacePop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _vm._m(6),
              ]),
              _c("tr", [
                _vm._m(7),
                _c("td", [_vm._v("/trans/doc/insertDangerDoc")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" bkgNo='위험물이 포함된 부킹번호' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DangerDocReqPop.bkgNo,
                          expression: "popupParams.DangerDocReqPop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.DangerDocReqPop.bkgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DangerDocReqPop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openPopup("DangerDocReqPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td", [_vm._v("위험물반입신고 요청서는 bkgNo 기준")]),
              ]),
              _c("tr", [
                _vm._m(8),
                _c("td", [_vm._v("/trans/bookings/dg-oog-certi")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      " taskCatCd='업무 구분코드( B => Booking 등록 / S => SR 등록 )' "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DgOogCertiPop.taskCatCd,
                          expression: "popupParams.DgOogCertiPop.taskCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.DgOogCertiPop.taskCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DgOogCertiPop,
                            "taskCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      "*********************************** type 분기 설명 ***********************************"
                    ),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      "Booking 등록 // DG/OOG 구분값( 'D' = DG / 'O' = OOG )"
                    ),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      "SR 등록 // 'SR' (SR 등록 시 DG(위험물) Certificate 파일만 첨부)"
                    ),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      "**************************************************************************************"
                    ),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" type "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DgOogCertiPop.type,
                          expression: "popupParams.DgOogCertiPop.type",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.DgOogCertiPop.type },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DgOogCertiPop,
                            "type",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      "=============================== Booking 등록 ==============================="
                    ),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" reqRno='요청관리번호(첨부파일 키값)' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DgOogCertiPop.reqRno,
                          expression: "popupParams.DgOogCertiPop.reqRno",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.DgOogCertiPop.reqRno },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DgOogCertiPop,
                            "reqRno",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" count "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DgOogCertiPop.count,
                          expression: "popupParams.DgOogCertiPop.count",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.DgOogCertiPop.count },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DgOogCertiPop,
                            "count",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" dgUpLoadFlag "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DgOogCertiPop.dgUpLoadFlag,
                          expression: "popupParams.DgOogCertiPop.dgUpLoadFlag",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.DgOogCertiPop.dgUpLoadFlag,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DgOogCertiPop,
                            "dgUpLoadFlag",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" bkgNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DgOogCertiPop.bkgNo,
                          expression: "popupParams.DgOogCertiPop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.DgOogCertiPop.bkgNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DgOogCertiPop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" scgAppNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DgOogCertiPop.scgAppNo,
                          expression: "popupParams.DgOogCertiPop.scgAppNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.DgOogCertiPop.scgAppNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DgOogCertiPop,
                            "scgAppNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      "================================= SR 등록 ================================="
                    ),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" blNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DgOogCertiPop.blNo,
                          expression: "popupParams.DgOogCertiPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.DgOogCertiPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DgOogCertiPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("DgOogCertiPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(9),
                _c("td", [_vm._v("/trans/vgms")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" methCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.VgmPop.methCd,
                          expression: "popupParams.VgmPop.methCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.VgmPop.methCd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VgmPop,
                            "methCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" type "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.VgmPop.type,
                          expression: "popupParams.VgmPop.type",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.VgmPop.type },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VgmPop,
                            "type",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" blNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.VgmPop.blNo,
                          expression: "popupParams.VgmPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.VgmPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VgmPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" uiFlag "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.VgmPop.uiFlag,
                          expression: "popupParams.VgmPop.uiFlag",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.VgmPop.uiFlag },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VgmPop,
                            "uiFlag",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("VgmPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(10),
                _c("td", [_vm._v("/trans/bookings/oog-loi")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" kmdVslCheck='KMTC 모선여부' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.OogLoiPop.kmdVslCheck,
                          expression: "popupParams.OogLoiPop.kmdVslCheck",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.OogLoiPop.kmdVslCheck,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.OogLoiPop,
                            "kmdVslCheck",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("OogLoiPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(11),
                _c("td", [_vm._v("/schedule/popup/ScheduleLegPop")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polCtrCd='선적항 국가 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ScheduleLegPop.polCtrCd,
                          expression: "popupParams.ScheduleLegPop.polCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ScheduleLegPop.polCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ScheduleLegPop,
                            "polCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polPlcCd='선적항 지역 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ScheduleLegPop.polPlcCd,
                          expression: "popupParams.ScheduleLegPop.polPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ScheduleLegPop.polPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ScheduleLegPop,
                            "polPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" podCtrCd='양하항 국가 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ScheduleLegPop.podCtrCd,
                          expression: "popupParams.ScheduleLegPop.podCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ScheduleLegPop.podCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ScheduleLegPop,
                            "podCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" podPlcCd='양하항 지역 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ScheduleLegPop.podPlcCd,
                          expression: "popupParams.ScheduleLegPop.podPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ScheduleLegPop.podPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ScheduleLegPop,
                            "podPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ScheduleLegPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(12),
                _c("td", [_vm._v("/schedule/email-schedule")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polCtrCd='선적항 국가 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.EmailSchedulePop.polCtrCd,
                          expression: "popupParams.EmailSchedulePop.polCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmailSchedulePop.polCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmailSchedulePop,
                            "polCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polPlcCd='선적항 지역 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.EmailSchedulePop.polPlcCd,
                          expression: "popupParams.EmailSchedulePop.polPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmailSchedulePop.polPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmailSchedulePop,
                            "polPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polPlcNm='선적항 지역 명' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.EmailSchedulePop.polPlcNm,
                          expression: "popupParams.EmailSchedulePop.polPlcNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmailSchedulePop.polPlcNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmailSchedulePop,
                            "polPlcNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" podCtrCd='양하항 국가 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.EmailSchedulePop.podCtrCd,
                          expression: "popupParams.EmailSchedulePop.podCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmailSchedulePop.podCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmailSchedulePop,
                            "podCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" podPlcCd='양하항 지역 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.EmailSchedulePop.podPlcCd,
                          expression: "popupParams.EmailSchedulePop.podPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmailSchedulePop.podPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmailSchedulePop,
                            "podPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" podPlcNm='양하항 지역 명' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.EmailSchedulePop.podPlcNm,
                          expression: "popupParams.EmailSchedulePop.podPlcNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmailSchedulePop.podPlcNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmailSchedulePop,
                            "podPlcNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" eiCatCd='수출입 구분 코드('O'=수출/'I'=수입)' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.EmailSchedulePop.eiCatCd,
                          expression: "popupParams.EmailSchedulePop.eiCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmailSchedulePop.eiCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmailSchedulePop,
                            "eiCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("EmailSchedulePop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(13),
                _c("td", [_vm._v("/trans/unno/{unno}")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" unno='unno(위험물 번호)' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.UnnoPop.unno,
                          expression: "popupParams.UnnoPop.unno",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.UnnoPop.unno },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.UnnoPop,
                            "unno",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("UnnoPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(14),
                _c("td", [
                  _vm._v("/trans/bookings/booking-cancel-reason-popup"),
                ]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" bkgNo='Booking No.' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BookingCancelPop.bkgNo,
                          expression: "popupParams.BookingCancelPop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text", placeholder: "required" },
                      domProps: {
                        value: _vm.popupParams.BookingCancelPop.bkgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingCancelPop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" cboTerm='POL Term (01:CY, 02:CFS)' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BookingCancelPop.cboTerm,
                          expression: "popupParams.BookingCancelPop.cboTerm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text", placeholder: "01:CY, 02:CFS" },
                      domProps: {
                        value: _vm.popupParams.BookingCancelPop.cboTerm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingCancelPop,
                            "cboTerm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" lclCancelYn='LCL 부킹 여부(미사용 예정)' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BookingCancelPop.lclCancelYn,
                          expression:
                            "popupParams.BookingCancelPop.lclCancelYn",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text", placeholder: "Y/N" },
                      domProps: {
                        value: _vm.popupParams.BookingCancelPop.lclCancelYn,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingCancelPop,
                            "lclCancelYn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      " cancelChargeYn='태국 웹 부킹 취소 시 수수료발생 대상인 경우' "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.BookingCancelPop.cancelChargeYn,
                          expression:
                            "popupParams.BookingCancelPop.cancelChargeYn",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text", placeholder: "Y/N" },
                      domProps: {
                        value: _vm.popupParams.BookingCancelPop.cancelChargeYn,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingCancelPop,
                            "cancelChargeYn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" overUi='선적확정 화면에서 부킹캔슬 시 플래그' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BookingCancelPop.overUi,
                          expression: "popupParams.BookingCancelPop.overUi",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text", placeholder: "선적확정인 경우 Y" },
                      domProps: {
                        value: _vm.popupParams.BookingCancelPop.overUi,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingCancelPop,
                            "overUi",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BookingCancelPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(15),
                _c("td"),
                _c("td"),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ContainerPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(16),
                _c("td", [_vm._v("/schedule/vsl-schedule-info")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" vslCd='선박 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.VesselScheduleInfoPop.vslCd,
                          expression: "popupParams.VesselScheduleInfoPop.vslCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.VesselScheduleInfoPop.vslCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VesselScheduleInfoPop,
                            "vslCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" voyNo='항해 번호' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.VesselScheduleInfoPop.voyNo,
                          expression: "popupParams.VesselScheduleInfoPop.voyNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.VesselScheduleInfoPop.voyNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VesselScheduleInfoPop,
                            "voyNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polPortCd='선적항 항구 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.VesselScheduleInfoPop.polPortCd,
                          expression:
                            "popupParams.VesselScheduleInfoPop.polPortCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.VesselScheduleInfoPop.polPortCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VesselScheduleInfoPop,
                            "polPortCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polPortCd='양하항 항구 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.VesselScheduleInfoPop.podPortCd,
                          expression:
                            "popupParams.VesselScheduleInfoPop.podPortCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.VesselScheduleInfoPop.podPortCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VesselScheduleInfoPop,
                            "podPortCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" bound='수출입 구분 코드('O'=수출/'I'=수입)' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.VesselScheduleInfoPop.bound,
                          expression: "popupParams.VesselScheduleInfoPop.bound",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.VesselScheduleInfoPop.bound,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VesselScheduleInfoPop,
                            "bound",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polTrmlCd='선적항 터미널 코드' "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.VesselScheduleInfoPop.polTrmlCd,
                          expression:
                            "popupParams.VesselScheduleInfoPop.polTrmlCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.VesselScheduleInfoPop.polTrmlCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VesselScheduleInfoPop,
                            "polTrmlCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("VesselScheduleInfoPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("불러오기 팝업")]),
                _c("td", [_vm._v("NEWB010P020")]),
                _c("td"),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BookingCallPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", [_vm._v("불만수집 팝업")]),
                _c("td", [_vm._v("NEWB010P150")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" docNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BookingVOCPop.docNo,
                          expression: "popupParams.BookingVOCPop.docNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.BookingVOCPop.docNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingVOCPop,
                            "docNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BookingVOCPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(17),
                _c("td"),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" 유저ID "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.RfDgRequestPop.userId,
                          expression: "popupParams.RfDgRequestPop.userId",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.RfDgRequestPop.userId,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.RfDgRequestPop,
                            "userId",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("RfDgRequestPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _vm._m(18),
                _c("td"),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.CaHistoryPop.blNo,
                          expression: "popupParams.CaHistoryPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.CaHistoryPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.CaHistoryPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("CaHistoryPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("DEM/DET 계산기 팝업"),
                ]),
                _c("td", [_vm._v("NEWB010P110")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" ctrCd=국가코드(KR,JP,IN..) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DemDetCalculatorPop.ctrCd,
                          expression: "popupParams.DemDetCalculatorPop.ctrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.DemDetCalculatorPop.ctrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DemDetCalculatorPop,
                            "ctrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" portCd=포트코드(HZR,AXT..) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DemDetCalculatorPop.portCd,
                          expression: "popupParams.DemDetCalculatorPop.portCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.DemDetCalculatorPop.portCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DemDetCalculatorPop,
                            "portCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" eta=출항일(선적예정일) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DemDetCalculatorPop.etd,
                          expression: "popupParams.DemDetCalculatorPop.etd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text", placeholder: "YYYY-MM-DD" },
                      domProps: {
                        value: _vm.popupParams.DemDetCalculatorPop.etd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DemDetCalculatorPop,
                            "etd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" containerList.cgoTypCd=컨테이너 특수화물구분 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.DemDetCalculatorPop.containerList[0]
                              .cgoTypCd,
                          expression:
                            "popupParams.DemDetCalculatorPop.containerList[0].cgoTypCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value:
                          _vm.popupParams.DemDetCalculatorPop.containerList[0]
                            .cgoTypCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DemDetCalculatorPop
                              .containerList[0],
                            "cgoTypCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" containerList.cntrQty=컨테이너 수량 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.DemDetCalculatorPop.containerList[0]
                              .cntrQty,
                          expression:
                            "popupParams.DemDetCalculatorPop.containerList[0].cntrQty",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value:
                          _vm.popupParams.DemDetCalculatorPop.containerList[0]
                            .cntrQty,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DemDetCalculatorPop
                              .containerList[0],
                            "cntrQty",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      " containerList.cntrSzCd=컨테이너 사이즈(20,40,45) "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.DemDetCalculatorPop.containerList[0]
                              .cntrSzCd,
                          expression:
                            "popupParams.DemDetCalculatorPop.containerList[0].cntrSzCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value:
                          _vm.popupParams.DemDetCalculatorPop.containerList[0]
                            .cntrSzCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DemDetCalculatorPop
                              .containerList[0],
                            "cntrSzCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" containerList.cntrTypCd=컨테이너 타입(GP,SR..) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.DemDetCalculatorPop.containerList[0]
                              .cntrTypCd,
                          expression:
                            "popupParams.DemDetCalculatorPop.containerList[0].cntrTypCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value:
                          _vm.popupParams.DemDetCalculatorPop.containerList[0]
                            .cntrTypCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DemDetCalculatorPop
                              .containerList[0],
                            "cntrTypCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" containerList.pcupReqDt=픽업예정일 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.DemDetCalculatorPop.containerList[0]
                              .pcupReqDt,
                          expression:
                            "popupParams.DemDetCalculatorPop.containerList[0].pcupReqDt",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text", placeholder: "YYYY-MM-DD" },
                      domProps: {
                        value:
                          _vm.popupParams.DemDetCalculatorPop.containerList[0]
                            .pcupReqDt,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DemDetCalculatorPop
                              .containerList[0],
                            "pcupReqDt",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("DemDetCalculatorPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("사업자 등록번호 검색 팝업"),
                ]),
                _c("td", [_vm._v("MYIN040P020")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" bsnNm=사업자명 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BusinessNumberPop.bsnNm,
                          expression: "popupParams.BusinessNumberPop.bsnNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BusinessNumberPop.bsnNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BusinessNumberPop,
                            "bsnNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" bzrgNo=사업자번호 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BusinessNumberPop.bzrgNo,
                          expression: "popupParams.BusinessNumberPop.bzrgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BusinessNumberPop.bzrgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BusinessNumberPop,
                            "bzrgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" cstNo=회사코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BusinessNumberPop.cstNo,
                          expression: "popupParams.BusinessNumberPop.cstNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BusinessNumberPop.cstNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BusinessNumberPop,
                            "cstNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" addr=주소 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BusinessNumberPop.addr,
                          expression: "popupParams.BusinessNumberPop.addr",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BusinessNumberPop.addr,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BusinessNumberPop,
                            "addr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" faxNo=팩스 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BusinessNumberPop.faxNo,
                          expression: "popupParams.BusinessNumberPop.faxNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BusinessNumberPop.faxNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BusinessNumberPop,
                            "faxNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" telNo=전번 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BusinessNumberPop.telNo,
                          expression: "popupParams.BusinessNumberPop.telNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BusinessNumberPop.telNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BusinessNumberPop,
                            "telNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BusinessNumberPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("운임 등록 요청 팝업"),
                ]),
                _c("td", [_vm._v("ONEX010P060")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Bl No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FareRegisReqPop.blNo,
                          expression: "popupParams.FareRegisReqPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.FareRegisReqPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FareRegisReqPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" eiCatCd (수출/수입 여부) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FareRegisReqPop.eiCatCd,
                          expression: "popupParams.FareRegisReqPop.eiCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FareRegisReqPop.eiCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FareRegisReqPop,
                            "eiCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("FareRegisReqPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("선적 로그 조회"),
                ]),
                _c("td", [_vm._v(" ONEX010T070, ONEX010T080 ")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Booking No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ShippingLogPop.bkgNo,
                          expression: "popupParams.ShippingLogPop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.ShippingLogPop.bkgNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ShippingLogPop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ShippingLogPop.blNo,
                          expression: "popupParams.ShippingLogPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.ShippingLogPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ShippingLogPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ShippingLogPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("선적 로그 상세(C/A DETAIL)"),
                ]),
                _c("td", [_vm._v("ONEX010T090")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" blNo=B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ShippingLogBLDetailPop.blNo,
                          expression: "popupParams.ShippingLogBLDetailPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ShippingLogBLDetailPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ShippingLogBLDetailPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ShippingLogBLDetailPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _vm._m(19),
              ]),
              _c("tr", [
                _vm._m(20),
                _c("td", [_vm._v("/trans/bl-payment/{blNo}/account-less")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BLPaymentAccountlessPop.blNo,
                          expression:
                            "popupParams.BLPaymentAccountlessPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BLPaymentAccountlessPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BLPaymentAccountlessPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BLPaymentAccountlessPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("수출 담당자 팝업"),
                ]),
                _c("td", [_vm._v("ONEX010P030")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" vslCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ExportManagerPop.vslCd,
                          expression: "popupParams.ExportManagerPop.vslCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ExportManagerPop.vslCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ExportManagerPop,
                            "vslCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" voyNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ExportManagerPop.voyNo,
                          expression: "popupParams.ExportManagerPop.voyNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ExportManagerPop.voyNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ExportManagerPop,
                            "voyNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polPortCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ExportManagerPop.polPortCd,
                          expression: "popupParams.ExportManagerPop.polPortCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ExportManagerPop.polPortCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ExportManagerPop,
                            "polPortCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" podPortCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ExportManagerPop.podPortCd,
                          expression: "popupParams.ExportManagerPop.podPortCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ExportManagerPop.podPortCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ExportManagerPop,
                            "podPortCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polTrmlCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ExportManagerPop.polTrmlCd,
                          expression: "popupParams.ExportManagerPop.polTrmlCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ExportManagerPop.polTrmlCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ExportManagerPop,
                            "polTrmlCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ExportManagerPop.blNo,
                          expression: "popupParams.ExportManagerPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ExportManagerPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ExportManagerPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" D/O 발행상태 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ExportManagerPop.doStatus,
                          expression: "popupParams.ExportManagerPop.doStatus",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ExportManagerPop.doStatus,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ExportManagerPop,
                            "doStatus",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ExportManagerPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Free Time Print 팝업"),
                ]),
                _c("td", [_vm._v("SETT060P050")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" blNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreeTimePrintPop.blNo,
                          expression: "popupParams.FreeTimePrintPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreeTimePrintPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreeTimePrintPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" demDetCatCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreeTimePrintPop.demDetCatCd,
                          expression:
                            "popupParams.FreeTimePrintPop.demDetCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreeTimePrintPop.demDetCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreeTimePrintPop,
                            "demDetCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" eiCatCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreeTimePrintPop.eiCatCd,
                          expression: "popupParams.FreeTimePrintPop.eiCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreeTimePrintPop.eiCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreeTimePrintPop,
                            "eiCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("FreeTimePrintPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Office / Agent 팝업"),
                ]),
                _c("td", [_vm._v("SETT060P070")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" portCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.OfficeAgentPop.portCd,
                          expression: "popupParams.OfficeAgentPop.portCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.OfficeAgentPop.portCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.OfficeAgentPop,
                            "portCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("OfficeAgentPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Free Time 연장요청"),
                ]),
                _c("td", [_vm._v("SETT060P040")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" blNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreeTimeExtensReqPop.blNo,
                          expression: "popupParams.FreeTimeExtensReqPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreeTimeExtensReqPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreeTimeExtensReqPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" eiCatCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreeTimeExtensReqPop.eiCatCd,
                          expression:
                            "popupParams.FreeTimeExtensReqPop.eiCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreeTimeExtensReqPop.eiCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreeTimeExtensReqPop,
                            "eiCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" sr "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreeTimeExtensReqPop.sr,
                          expression: "popupParams.FreeTimeExtensReqPop.sr",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreeTimeExtensReqPop.sr,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreeTimeExtensReqPop,
                            "sr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("FreeTimeExtensReqPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [_vm._v("지도 팝업")]),
                _c("td", [_vm._v("SCHD010P060")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" catCd - 업무 구분 코드(V=선박위치, P=항구위치) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PortLocationPop.catCd,
                          expression: "popupParams.PortLocationPop.catCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: {
                        value: _vm.popupParams.PortLocationPop.catCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PortLocationPop,
                            "catCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" portCd - 포트 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PortLocationPop.portCd,
                          expression: "popupParams.PortLocationPop.portCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PortLocationPop.portCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PortLocationPop,
                            "portCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("PortLocationPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [_vm._v("Route 팝업")]),
                _c("td", [_vm._v("SCHD010P041")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" vesselNm - 선박명 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.RoutePop.vesselNm,
                          expression: "popupParams.RoutePop.vesselNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.RoutePop.vesselNm },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.RoutePop,
                            "vesselNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" rteCd - 항로 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.RoutePop.rteCd,
                          expression: "popupParams.RoutePop.rteCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.RoutePop.rteCd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.RoutePop,
                            "rteCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("RoutePop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("세금계산서 Template"),
                ]),
                _c("td", [_vm._v("ONEX070G050")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(
                      " tplRno - 템플릿 관리 번호(PK) - 수정 시에만 입력 "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.TaxTemplatePop.tplRno,
                          expression: "popupParams.TaxTemplatePop.tplRno",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.TaxTemplatePop.tplRno,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.TaxTemplatePop,
                            "tplRno",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" tplCatCd - 템플릿 구분 코드(PK) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.TaxTemplatePop.tplCatCd,
                          expression: "popupParams.TaxTemplatePop.tplCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: {
                        value: _vm.popupParams.TaxTemplatePop.tplCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.TaxTemplatePop,
                            "tplCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" templateNm - 템플릿 명 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.TaxTemplatePop.templateNm,
                          expression: "popupParams.TaxTemplatePop.templateNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.TaxTemplatePop.templateNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.TaxTemplatePop,
                            "templateNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" rcivNm - 상호 명 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.TaxTemplatePop.rcivNm,
                          expression: "popupParams.TaxTemplatePop.rcivNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.TaxTemplatePop.rcivNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.TaxTemplatePop,
                            "rcivNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" rcivAddr - 주소 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.TaxTemplatePop.rcivAddr,
                          expression: "popupParams.TaxTemplatePop.rcivAddr",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.TaxTemplatePop.rcivAddr,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.TaxTemplatePop,
                            "rcivAddr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" rcivBzrgNo - 사업자 번호 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.TaxTemplatePop.rcivBzrgNo,
                          expression: "popupParams.TaxTemplatePop.rcivBzrgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.TaxTemplatePop.rcivBzrgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.TaxTemplatePop,
                            "rcivBzrgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" rcivPicNm - 담당자 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.TaxTemplatePop.rcivPicNm,
                          expression: "popupParams.TaxTemplatePop.rcivPicNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.TaxTemplatePop.rcivPicNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.TaxTemplatePop,
                            "rcivPicNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" rcivCtfc - 연락처 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.TaxTemplatePop.rcivCtfc,
                          expression: "popupParams.TaxTemplatePop.rcivCtfc",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.TaxTemplatePop.rcivCtfc,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.TaxTemplatePop,
                            "rcivCtfc",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" emlAddr - E-Mail "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.TaxTemplatePop.emlAddr,
                          expression: "popupParams.TaxTemplatePop.emlAddr",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.TaxTemplatePop.emlAddr,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.TaxTemplatePop,
                            "emlAddr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("TaxTemplatePop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Booking Combine Detail"),
                ]),
                _c("td"),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Booking No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BookingCombineDetailPop.bkgNo,
                          expression:
                            "popupParams.BookingCombineDetailPop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BookingCombineDetailPop.bkgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingCombineDetailPop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BookingCombineDetailPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Booking Split Detail"),
                ]),
                _c("td"),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Booking No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BookingSplitDetailPop.bkgNo,
                          expression: "popupParams.BookingSplitDetailPop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BookingSplitDetailPop.bkgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingSplitDetailPop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BookingSplitDetailPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("운임확인(1) 팝업"),
                ]),
                _c("td", [_vm._v("SCHD010P070")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" porCtrCd - 출발 국가 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.porCtrCd,
                          expression: "popupParams.FreSurchargePop.porCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.porCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "porCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" porPlcCd - 출발 항구 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.porPlcCd,
                          expression: "popupParams.FreSurchargePop.porPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.porPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "porPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" dlyCtrCd - 도착 국가 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.dlyCtrCd,
                          expression: "popupParams.FreSurchargePop.dlyCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.dlyCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "dlyCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" dlyPlcCd - 도착 항구 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.dlyPlcCd,
                          expression: "popupParams.FreSurchargePop.dlyPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.dlyPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "dlyPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" etd - 출항 일자 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.etd,
                          expression: "popupParams.FreSurchargePop.etd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.FreSurchargePop.etd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "etd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" frtAppNo - 운임 APPLICATION 번호 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.frtAppNo,
                          expression: "popupParams.FreSurchargePop.frtAppNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.frtAppNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "frtAppNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" frtEstnYn - 운임 견적 여부 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.frtEstnYn,
                          expression: "popupParams.FreSurchargePop.frtEstnYn",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.frtEstnYn,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "frtEstnYn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" eiCatCd - 수출입 구분 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.eiCatCd,
                          expression: "popupParams.FreSurchargePop.eiCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.eiCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "eiCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" frtResult - R/A No 조회결과 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.frtResult,
                          expression: "popupParams.FreSurchargePop.frtResult",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.frtResult,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "frtResult",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" reqRno - 요청 관리 번호 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.reqRno,
                          expression: "popupParams.FreSurchargePop.reqRno",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.reqRno,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "reqRno",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" bkgClose - 부킹 가능여부 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.bkgClose,
                          expression: "popupParams.FreSurchargePop.bkgClose",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.bkgClose,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "bkgClose",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" raTsParam - T/S PARAMETER "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.raTsParam,
                          expression: "popupParams.FreSurchargePop.raTsParam",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.raTsParam,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "raTsParam",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polNm - 출발지명 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.polNm,
                          expression: "popupParams.FreSurchargePop.polNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.polNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "polNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" podNm - 도착지명 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.podNm,
                          expression: "popupParams.FreSurchargePop.podNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.podNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "podNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" startDt - 운임 시작일 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.startDt,
                          expression: "popupParams.FreSurchargePop.startDt",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.startDt,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "startDt",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" endDt - 운임 종료일 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop.endDt,
                          expression: "popupParams.FreSurchargePop.endDt",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop.endDt,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop,
                            "endDt",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("FreSurchargePop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("운임확인(2) 팝업"),
                ]),
                _c("td", [_vm._v("SCHD010P071")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" porCtrCd - 출발 국가 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.porCtrCd,
                          expression: "popupParams.FreSurchargePop2.porCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.porCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "porCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" porPlcCd - 출발 항구 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.porPlcCd,
                          expression: "popupParams.FreSurchargePop2.porPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.porPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "porPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" dlyCtrCd - 도착 국가 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.dlyCtrCd,
                          expression: "popupParams.FreSurchargePop2.dlyCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.dlyCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "dlyCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" dlyPlcCd - 도착 항구 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.dlyPlcCd,
                          expression: "popupParams.FreSurchargePop2.dlyPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.dlyPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "dlyPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" etd - 출항 일자 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.etd,
                          expression: "popupParams.FreSurchargePop2.etd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.FreSurchargePop2.etd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "etd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" frtAppNo - 운임 APPLICATION 번호 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.frtAppNo,
                          expression: "popupParams.FreSurchargePop2.frtAppNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.frtAppNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "frtAppNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" eiCatCd - 수출입 구분 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.eiCatCd,
                          expression: "popupParams.FreSurchargePop2.eiCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.eiCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "eiCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" frtResult - R/A No 조회결과 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.frtResult,
                          expression: "popupParams.FreSurchargePop2.frtResult",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.frtResult,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "frtResult",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" reqRno - 요청 관리 번호 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.reqRno,
                          expression: "popupParams.FreSurchargePop2.reqRno",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.reqRno,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "reqRno",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" bkgClose - 부킹 가능여부 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.bkgClose,
                          expression: "popupParams.FreSurchargePop2.bkgClose",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.bkgClose,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "bkgClose",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" raTsParam - T/S PARAMETER "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.raTsParam,
                          expression: "popupParams.FreSurchargePop2.raTsParam",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.raTsParam,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "raTsParam",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" polNm - 출발지명 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.polNm,
                          expression: "popupParams.FreSurchargePop2.polNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.polNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "polNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" podNm - 도착지명 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FreSurchargePop2.podNm,
                          expression: "popupParams.FreSurchargePop2.podNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FreSurchargePop2.podNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FreSurchargePop2,
                            "podNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("FreSurchargePop2")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("운임문의 팝업"),
                ]),
                _c("td", [_vm._v("SCHD010P080")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" porCtrCd - 출발 국가 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.RequestQuotePop.porCtrCd,
                          expression: "popupParams.RequestQuotePop.porCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.RequestQuotePop.porCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.RequestQuotePop,
                            "porCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" porPlcCd - 출발 항구 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.RequestQuotePop.porPlcCd,
                          expression: "popupParams.RequestQuotePop.porPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.RequestQuotePop.porPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.RequestQuotePop,
                            "porPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" dlyCtrCd - 도착 국가 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.RequestQuotePop.dlyCtrCd,
                          expression: "popupParams.RequestQuotePop.dlyCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.RequestQuotePop.dlyCtrCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.RequestQuotePop,
                            "dlyCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" dlyPlcCd - 도착 항구 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.RequestQuotePop.dlyPlcCd,
                          expression: "popupParams.RequestQuotePop.dlyPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.RequestQuotePop.dlyPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.RequestQuotePop,
                            "dlyPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("RequestQuotePop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("PortTerminalCY 팝업"),
                ]),
                _c("td", [_vm._v("SCHD010P050")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" asPortCd - 지역 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PortTerminalCYPop.asPortCd,
                          expression: "popupParams.PortTerminalCYPop.asPortCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PortTerminalCYPop.asPortCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PortTerminalCYPop,
                            "asPortCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" icdPlcCd - ICD 지역 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PortTerminalCYPop.icdPlcCd,
                          expression: "popupParams.PortTerminalCYPop.icdPlcCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PortTerminalCYPop.icdPlcCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PortTerminalCYPop,
                            "icdPlcCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" asSvcLang - 서비스 언어 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PortTerminalCYPop.asSvcLang,
                          expression: "popupParams.PortTerminalCYPop.asSvcLang",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PortTerminalCYPop.asSvcLang,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PortTerminalCYPop,
                            "asSvcLang",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("PortTerminalCYPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("선명/항차 조회 팝업"),
                ]),
                _c("td", [_vm._v("VoyVslSearchPop")]),
                _c("td"),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("VoyVslSearchPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Vessel Inquiry 조회 팝업"),
                ]),
                _c("td", [_vm._v("CSCT060G061")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" vslNm - 선박 명 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.VesselInquiryPop.vslNm,
                          expression: "popupParams.VesselInquiryPop.vslNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.VesselInquiryPop.vslNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.VesselInquiryPop,
                            "vslNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("VesselInquiryPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Booking Cancel Fee 정산"),
                ]),
                _c("td", [_vm._v("CSCT060G061")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Booking No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BookingCancelFeePop.bkgNo,
                          expression: "popupParams.BookingCancelFeePop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BookingCancelFeePop.bkgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingCancelFeePop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BookingCancelFeePop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("선적 취소 요청서 팝업"),
                ]),
                _c("td", [_vm._v("ONEX020P120")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Bl No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BookingCancelRequestPop.blNo,
                          expression:
                            "popupParams.BookingCancelRequestPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BookingCancelRequestPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BookingCancelRequestPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BookingCancelRequestPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("컨테이너 셔틀 요청서 팝업"),
                ]),
                _c("td", [_vm._v("ONEX020P130")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Bl No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ContainerShuttlePop.blNo,
                          expression: "popupParams.ContainerShuttlePop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ContainerShuttlePop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ContainerShuttlePop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ContainerShuttlePop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td", [
                  _vm._v("[TEST] blNo: BLW0006494A (컨테이너 여러개)"),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("COD 요청서 팝업"),
                ]),
                _c("td", [_vm._v("ONEX010P070")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Bl No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.CodRequestPop.blNo,
                          expression: "popupParams.CodRequestPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.CodRequestPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.CodRequestPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("CodRequestPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Full 컨테이너 반출 요청서 팝업"),
                ]),
                _c("td", [_vm._v("ONEX010P095")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Booking No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.FullContainerOutReqPop.bkgNo,
                          expression:
                            "popupParams.FullContainerOutReqPop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.FullContainerOutReqPop.bkgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.FullContainerOutReqPop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("FullContainerOutReqPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("제3국 소재 Shipper LOI 첨부 팝업"),
                ]),
                _c("td", [_vm._v("ONEX050P190")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" BL No. (없으면 SR No) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.IndemnityReqPop.blNo,
                          expression: "popupParams.IndemnityReqPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.IndemnityReqPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.IndemnityReqPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("IndemnityReqPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("컨테이너 번호변경 요청서 팝업"),
                ]),
                _c("td", [_vm._v("ONEX010P100")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" BL No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.CntrNoCorrectReqPop.blNo,
                          expression: "popupParams.CntrNoCorrectReqPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.CntrNoCorrectReqPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.CntrNoCorrectReqPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("CntrNoCorrectReqPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Shipback 요청서 팝업"),
                ]),
                _c("td", [_vm._v("ONEX010P080")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" BL No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ShipbackReqPop.blNo,
                          expression: "popupParams.ShipbackReqPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.ShipbackReqPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ShipbackReqPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ShipbackReqPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("수입 업무 담당자 팝업"),
                ]),
                _c("td", [_vm._v("ONIM010P020")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ImportManagerPop.blNo,
                          expression: "popupParams.ImportManagerPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.ImportManagerPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ImportManagerPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ImportManagerPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("출발지/도착지 스케줄 정보"),
                ]),
                _c("td", [_vm._v("ONEX010P040")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Booking No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PorDlySchedulePop.bkgNo,
                          expression: "popupParams.PorDlySchedulePop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PorDlySchedulePop.bkgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PorDlySchedulePop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("PorDlySchedulePop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("선적지 Surrender 문의 팝업"),
                ]),
                _c("td", [_vm._v("ONIM050P020")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Bl No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SurrenderReqEmailPop.blNo,
                          expression: "popupParams.SurrenderReqEmailPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SurrenderReqEmailPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SurrenderReqEmailPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" voyNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SurrenderReqEmailPop.voyNo,
                          expression: "popupParams.SurrenderReqEmailPop.voyNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SurrenderReqEmailPop.voyNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SurrenderReqEmailPop,
                            "voyNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" vslNm "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SurrenderReqEmailPop.vslNm,
                          expression: "popupParams.SurrenderReqEmailPop.vslNm",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SurrenderReqEmailPop.vslNm,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SurrenderReqEmailPop,
                            "vslNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" podPortCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SurrenderReqEmailPop.podPortCd,
                          expression:
                            "popupParams.SurrenderReqEmailPop.podPortCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SurrenderReqEmailPop.podPortCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SurrenderReqEmailPop,
                            "podPortCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" surrEmailyn "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.SurrenderReqEmailPop.surrEmailyn,
                          expression:
                            "popupParams.SurrenderReqEmailPop.surrEmailyn",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SurrenderReqEmailPop.surrEmailyn,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SurrenderReqEmailPop,
                            "surrEmailyn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" doStsCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SurrenderReqEmailPop.doStsCd,
                          expression:
                            "popupParams.SurrenderReqEmailPop.doStsCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SurrenderReqEmailPop.doStsCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SurrenderReqEmailPop,
                            "doStsCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("SurrenderReqEmailPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td", [
                  _vm._v(
                    "[As-is]surrEmailyn가 Y이거나(이미 Surrender문의를 했거나) doStsCd가 1 또는 2(B/L발행, 접수처리)일 경우 Surrender 문의를 할 수 없음."
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("O B/L 재발행 팝업"),
                ]),
                _c("td", [_vm._v("ONEX070G110")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" kind "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.OriginalBLReIssuePop.kind,
                          expression: "popupParams.OriginalBLReIssuePop.kind",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.OriginalBLReIssuePop.kind,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.OriginalBLReIssuePop,
                            "kind",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.OriginalBLReIssuePop.blNo,
                          expression: "popupParams.OriginalBLReIssuePop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.OriginalBLReIssuePop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.OriginalBLReIssuePop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" reqCatCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.OriginalBLReIssuePop.reqCatCd,
                          expression:
                            "popupParams.OriginalBLReIssuePop.reqCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.OriginalBLReIssuePop.reqCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.OriginalBLReIssuePop,
                            "reqCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("OriginalBLReIssuePop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("B/L 첨부파일 팝업"),
                ]),
                _c("td", [_vm._v("ONEX050P170")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" type "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BLAttachPop.type,
                          expression: "popupParams.BLAttachPop.type",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.BLAttachPop.type },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BLAttachPop,
                            "type",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BLAttachPop.blNo,
                          expression: "popupParams.BLAttachPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.BLAttachPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BLAttachPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" 도착지 (이란인 경우 'IR' 기입) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BLAttachPop.podCtrCd,
                          expression: "popupParams.BLAttachPop.podCtrCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.BLAttachPop.podCtrCd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BLAttachPop,
                            "podCtrCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BLAttachPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("SHA M/F Data 팝업"),
                ]),
                _c("td", [_vm._v("ONEX050P060")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" type "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ShaMfDataPop.type,
                          expression: "popupParams.ShaMfDataPop.type",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.ShaMfDataPop.type },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ShaMfDataPop,
                            "type",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.ShaMfDataPop.blNo,
                          expression: "popupParams.ShaMfDataPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.ShaMfDataPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.ShaMfDataPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ShaMfDataPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Payment 등록 팝업"),
                ]),
                _c("td", [_vm._v("ONIM099P060")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PaymentRegPop.blNo,
                          expression: "popupParams.PaymentRegPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.PaymentRegPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PaymentRegPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" linkPayNo "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PaymentRegPop.linkPayNo,
                          expression: "popupParams.PaymentRegPop.linkPayNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PaymentRegPop.linkPayNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PaymentRegPop,
                            "linkPayNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" linkYn "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PaymentRegPop.linkYn,
                          expression: "popupParams.PaymentRegPop.linkYn",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.PaymentRegPop.linkYn },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PaymentRegPop,
                            "linkYn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" payStsCd "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.PaymentRegPop.payStsCd,
                          expression: "popupParams.PaymentRegPop.payStsCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.PaymentRegPop.payStsCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.PaymentRegPop,
                            "payStsCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("PaymentRegPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("상해 부킹 Cancel 팝업"),
                ]),
                _c("td", [_vm._v("ONEX020P070")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Booking No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SHASRCancelPop.bkgNo,
                          expression: "popupParams.SHASRCancelPop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.SHASRCancelPop.bkgNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SHASRCancelPop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" S/R No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SHASRCancelPop.srRno,
                          expression: "popupParams.SHASRCancelPop.srRno",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.SHASRCancelPop.srRno },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SHASRCancelPop,
                            "srRno",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" 출발항 코드 "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SHASRCancelPop.polPortCd,
                          expression: "popupParams.SHASRCancelPop.polPortCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SHASRCancelPop.polPortCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SHASRCancelPop,
                            "polPortCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("SHASRCancelPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("DG Entry 팝업"),
                ]),
                _c("td", [_vm._v("ONIM010P080")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DGEntryPop.blNo,
                          expression: "popupParams.DGEntryPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.DGEntryPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DGEntryPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("DGEntryPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("OOG Entry 팝업"),
                ]),
                _c("td", [_vm._v("ONIM010P090")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.OOGEntryPop.blNo,
                          expression: "popupParams.OOGEntryPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.OOGEntryPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.OOGEntryPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("OOGEntryPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("RF Entry 팝업"),
                ]),
                _c("td", [_vm._v("ONIM010P010")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.RFEntryPop.blNo,
                          expression: "popupParams.RFEntryPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.RFEntryPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.RFEntryPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("RFEntryPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("긴급양하 신청 팝업"),
                ]),
                _c("td", [_vm._v("ONIM010P070")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. (blNo) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.EmergencyShippingReqPop.blNo,
                          expression:
                            "popupParams.EmergencyShippingReqPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmergencyShippingReqPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmergencyShippingReqPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Port Cd (portCd) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.EmergencyShippingReqPop.portCd,
                          expression:
                            "popupParams.EmergencyShippingReqPop.portCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmergencyShippingReqPop.portCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmergencyShippingReqPop,
                            "portCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" 수출입 구분 코드 (eiCatCd) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.popupParams.EmergencyShippingReqPop.eiCatCd,
                          expression:
                            "popupParams.EmergencyShippingReqPop.eiCatCd",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.EmergencyShippingReqPop.eiCatCd,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.EmergencyShippingReqPop,
                            "eiCatCd",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("EmergencyShippingReqPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("B/L 발행 팝업"),
                ]),
                _c("td", [_vm._v("ONEX070G040")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BLPaymentPop.blNoArr,
                          expression: "popupParams.BLPaymentPop.blNoArr",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.BLPaymentPop.blNoArr },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BLPaymentPop,
                            "blNoArr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BLPaymentPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("D/O 발행 팝업"),
                ]),
                _c("td", [_vm._v("ONIM099P010")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.DOPaymentPop.blNoArr,
                          expression: "popupParams.DOPaymentPop.blNoArr",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.DOPaymentPop.blNoArr },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.DOPaymentPop,
                            "blNoArr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("DOPaymentPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("C/A 요청 팝업 (B/L 수정)"),
                ]),
                _c("td", [_vm._v("ONEX060P012")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L No. (blNo) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.CARequestPop.blNo,
                          expression: "popupParams.CARequestPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.CARequestPop.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.CARequestPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L 발생 여부 (blIssYn) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.CARequestPop.blIssYn,
                          expression: "popupParams.CARequestPop.blIssYn",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.CARequestPop.blIssYn },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.CARequestPop,
                            "blIssYn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Surrender 여부 (surrYn) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.CARequestPop.surrYn,
                          expression: "popupParams.CARequestPop.surrYn",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.CARequestPop.surrYn },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.CARequestPop,
                            "surrYn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" MF 마감 여부 (mfClosDtYn) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.CARequestPop.mfClosDtYn,
                          expression: "popupParams.CARequestPop.mfClosDtYn",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.CARequestPop.mfClosDtYn,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.CARequestPop,
                            "mfClosDtYn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" 임시 C/A No. (tempCaNo) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.CARequestPop.tempCaNo,
                          expression: "popupParams.CARequestPop.tempCaNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.CARequestPop.tempCaNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.CARequestPop,
                            "tempCaNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("CARequestPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("이란 업로드 팝업"),
                ]),
                _c("td"),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" 요청 관리 번호 (reqRno) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.IranUploadPop.reqRno,
                          expression: "popupParams.IranUploadPop.reqRno",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.IranUploadPop.reqRno },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.IranUploadPop,
                            "reqRno",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Container 순번 (count) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.IranUploadPop.count,
                          expression: "popupParams.IranUploadPop.count",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.IranUploadPop.count },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.IranUploadPop,
                            "count",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" 업로드 타입 (type) "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.IranUploadPop.type,
                          expression: "popupParams.IranUploadPop.type",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: { value: _vm.popupParams.IranUploadPop.type },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.IranUploadPop,
                            "type",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("IranUploadPop")
                        },
                      },
                    },
                    [_vm._v("팝업")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("Surrend Request 팝업"),
                ]),
                _c("td", [_vm._v("ONEX070G090")]),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Bl No Array "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.SurrenderRequestPop.blNoArr,
                          expression: "popupParams.SurrenderRequestPop.blNoArr",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.SurrenderRequestPop.blNoArr,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.SurrenderRequestPop,
                            "blNoArr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("SurrenderRequestPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
              _c("tr", [
                _c("td", { staticClass: "temp_name" }, [
                  _vm._v("BLContainerCgoPop"),
                ]),
                _c("td"),
                _c("td", [
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" Booking no. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BLContainerCgoPop.bkgNo,
                          expression: "popupParams.BLContainerCgoPop.bkgNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BLContainerCgoPop.bkgNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BLContainerCgoPop,
                            "bkgNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "p_params" }, [
                    _vm._v(" B/L no. "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.popupParams.BLContainerCgoPop.blNo,
                          expression: "popupParams.BLContainerCgoPop.blNo",
                        },
                      ],
                      staticClass: "input_txt",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.popupParams.BLContainerCgoPop.blNo,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.popupParams.BLContainerCgoPop,
                            "blNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BLContainerCgoPop")
                        },
                      },
                    },
                    [_vm._v(" 팝업 ")]
                  ),
                ]),
                _c("td"),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "200" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("팝업명")]),
        _c("th", [_vm._v("URL")]),
        _c("th", [_vm._v("필수 파라메터")]),
        _c("th"),
        _c("th", [_vm._v("비고")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [
        _vm._v("운송사 정보 리스트 팝업"),
      ]),
      _c("p", [_vm._v("NEWB010P040(U0050P0)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [
        _vm._v("Pick Up 장소 리스트 팝업"),
      ]),
      _c("p", [_vm._v("ONEX100P030(U0050P1)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", [
        _vm._v("@parentSelect= 자식function select시 부모 컴포넌트에서 매핑"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [
        _vm._v("Pick up Place 선택 팝업"),
      ]),
      _c("p", [_vm._v("NEWB020P020(U0050P1)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", [
        _vm._v("@parentSelect= 자식function select시 부모 컴포넌트에서 매핑"),
      ]),
      _c("p", [
        _vm._v(
          "부킹 지역 코드 세션에 따라 검색 리스트 가져오므로 테스트시에만 부킹지역코드(hidPolPortCode,세션) 입력값 사용"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [
        _vm._v("위험물반입신고 요청서 팝업"),
      ]),
      _c("p", [_vm._v("NEWB010P130")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [
        _vm._v("DG/OOG Certificate 팝업"),
      ]),
      _c("p", [_vm._v("NEWB020P030")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [_vm._v("VGM 정보 등록")]),
      _c("p", [_vm._v("ONEX050G020")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [
        _vm._v("ON DECK 선적 관련 안내 팝업"),
      ]),
      _c("p", [_vm._v("NEWB010P140")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [_vm._v("스케줄 조회 팝업")]),
      _c("p", [_vm._v("NEWB010P030")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [_vm._v("스케쥴 구독 팝업")]),
      _c("p", [_vm._v("SCHD010P030")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [_vm._v("스케쥴 Unno 검색 팝업")]),
      _c("p", [_vm._v("SCHD010P020(U0120P0)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [
        _vm._v("부킹 취소 사유 입력 팝업"),
      ]),
      _c("p", [_vm._v("ONEX030P020(U0170)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [_vm._v("컨테이너 정보 팝업")]),
      _c("p", [_vm._v("NEWB010P050")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [_vm._v("선박정보 및 스케줄")]),
      _c("p", [_vm._v("SCHD010P040")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [
        _vm._v("RF 위험물 사전승인번호 선택 팝업"),
      ]),
      _c("p", [_vm._v("NEWB020P020")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [_vm._v("CA내역 조회 팝업")]),
      _c("p", [_vm._v("ONEX060P020")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", [_vm._v("[TEST DATA]")]),
      _c("p", [_vm._v("header: INC3335590")]),
      _c("p", [_vm._v("leg: INC3335590")]),
      _c("p", [_vm._v("body: PUSE000959")]),
      _c("p", [_vm._v("freight: INC3335590")]),
      _c("p", [_vm._v("container: PUSE013769")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", { staticClass: "temp_name" }, [_vm._v("미정산 내역 팝업")]),
      _c("p", [_vm._v("ONEX010P050")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }