var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1000px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.002"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.003"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.004"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.005"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.006"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.007"))),
                ]),
              ]),
              _c("tr", [
                _c("th", { staticClass: "border_left" }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.008"))),
                ]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P010.009")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P010.010")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P010.011")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P010.012")))]),
              ]),
            ]),
            _vm.items.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.items, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(item.cntrNo))]),
                      _c("td", [_vm._v(_vm._s(item.cntrSzCd))]),
                      _c("td", [_vm._v(_vm._s(item.cntrTypCd))]),
                      _c("td", [_vm._v(_vm._s(item.cgoTypCd))]),
                      _c("td", [_vm._v(_vm._s(item.feCatCd))]),
                      _c("td", [_vm._v(_vm._s(item.ovrHght))]),
                      _c("td", [_vm._v(_vm._s(item.ovrFwdLen))]),
                      _c("td", [_vm._v(_vm._s(item.ovrBwrdLen))]),
                      _c("td", [_vm._v(_vm._s(item.ovrPortLen))]),
                      _c("td", [_vm._v(_vm._s(item.ovrStbdLen))]),
                    ])
                  }),
                  0
                )
              : _c("tbody", [
                  _c("tr", [
                    _c("td", { attrs: { colspan: "10" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.015"))),
                    ]),
                  ]),
                ]),
          ]),
          _c("p", { staticClass: "txt_desc asterisk" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONIM010P010.013"))),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "button",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONIM010P010.014")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "19%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }