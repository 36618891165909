var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM010P080.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P080.002")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P080.003")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P080.004")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P080.005")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P080.006")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P080.007")))]),
              ]),
            ]),
            _vm.items.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.items, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(index + 1))]),
                      _c("td", [_vm._v(_vm._s(item.cntrNo))]),
                      _c("td", [_vm._v(_vm._s(item.cntrSzCd))]),
                      _c("td", [_vm._v(_vm._s(item.cntrTypCd))]),
                      _c("td", [_vm._v(_vm._s(item.imdunNo))]),
                      _c("td", [_vm._v(_vm._s(item.imoClasCd))]),
                    ])
                  }),
                  0
                )
              : _c("tbody", [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: { "text-align": "center" },
                        attrs: { colspan: "6" },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONIM010P080.010")))]
                    ),
                  ]),
                ]),
          ]),
          _c("p", { staticClass: "txt_desc asterisk" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONIM010P080.008"))),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "button",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONIM010P080.009")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "35%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "15%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }