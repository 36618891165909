<template>
  <div class="popup_wrap" style="width:800px;height:600px;">
    <!-- 팝업사이즈 참고 : 800*600  popup_wrap -->
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <form id="frm">
      <div class="popup_cont">
        <!-- popup_cont -->

        <h1 class="page_title">{{ $t('msg.CSCT060G061.001') }}</h1><!-- Vessel Inquiry -->

        <div class="content_box">
          <!-- content_box -->
          <table class="tbl_search">
            <colgroup>
              <col width="100px">
              <col width="200px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.CSCT060G061.002') }}</th><!-- 선박명 -->
                <td>
                  <input
                    v-model="params.vslNm"
                    id="ipt_vsl_nm"
                    type="text"
                    @keyup.enter="getVesselInquiryList(1, true)"
                    @keyup="checkVslNm()"
                  >
                  <input type="text" id="_temp" style="display:none;" /><!-- Enter 클릭시 submit 방지용 -->
                </td>
                <td>
                  <a
                    class="button blue sh"
                    href="#"
                    @click.prevent="getVesselInquiryList(1, true)"
                  >{{ $t('msg.CSCT060G061.003') }}</a><!-- 검색 -->
                </td>
              </tr>
            </tbody>
          </table>
        </div> <!-- content_box // -->

        <div class="content_box mt10">
          <!-- content_box -->

          <table class="tbl_col">
            <colgroup>
              <col width="60%">
              <!-- <col width="35%"> -->
              <col width="40%">
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('msg.CSCT060G061.004') }}</th><!-- 선박명 -->
                <!-- <th>{{ $t('msg.CSCT060G061.005') }}</th> -->
                <!-- 항로명 -->
                <th>{{ $t('msg.CSCT060G061.006') }}</th><!-- 선박 코드 -->
              </tr>
            </thead>
            <tbody v-if="vesselInquiryList.length > 0">
              <tr
                v-for="(item, idx) in vesselInquiryList"
                :key="item.vslCd + '_' + idx"
              >
                <td>
                  <a
                    href="#"
                    @click.prevent="$emit('close', item)"
                  >{{ item.vslNm }}</a>
                </td>
                <!-- <td>{{ item.rteEnm }}</td> -->
                <td>{{ item.vslCd }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3">{{ $t('msg.CSCT060G061.007') }}</td><!-- 등록된 내용이 없습니다. -->
              </tr>
            </tbody>
          </table>

          <div
            v-if="vesselInquiryList.length > 0"
            class="paging"
          >
            <!-- paging -->
            <a
              href="#"
              class="btn start"
              @click.prevent="getVesselInquiryList(1, false)"
            >Start</a>
            <a
              href="#"
              class="btn prev"
              @click.prevent="getVesselInquiryList(pagination.prevPage, false)"
            >Prev</a>
            <a
              v-for="idx in pageNumberRange"
              :key="idx"
              :class="[idx === params.curPage ? 'on' : '']"
              href="#"
              @click.prevent="getVesselInquiryList(idx, false)"
            >{{ idx }}</a>
            <a
              href="#"
              class="btn next"
              @click.prevent="getVesselInquiryList(pagination.nextPage, false)"
            >Next</a>
            <a
              href="#"
              class="btn end"
              @click.prevent="getVesselInquiryList(pagination.pageCnt, false)"
            >End</a>
          </div>
        </div><!-- content_box // -->

        <!-- 팝업 하단 버튼 필요시 사용
        <div class="mt10 text_center">
        <a class="button blue lg" href="#">파란버튼</a><a class="button gray lg" href="#">닫기</a>
        </div>
          팝업 하단 버튼 필요시 사용  //-->
      </div><!-- popup_cont -->
    </form>
  </div><!-- popup_wrap // -->
</template>

<script>

import ExchangeRate from '@/api/rest/common/exchangeRate'

export default {
  name: 'VesselInquiryPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          vslNm: ''
        }
      }
    }
  },
  data: function () {
    return {
      // 최초 API(팝업관리 리스트) 호출여부
      isInit: false,

      params: {
        // 현재 페이지
        curPage: 1,
        // 한 페이지당 게시글 수
        pageSize: 10,

        vslNm: this.parentInfo.vslNm
      },
      // Total(전체 글 개수)
      listCountNumber: 0,
      vesselInquiryList: [],
      // 페이징 모듈
      pagination: {}
    }
  },
  computed: {
    pageNumberRange: function () {
      return [...Array(this.pagination.pageNumberSize).keys()].map(i => i + this.pagination.startPage)
    }
  },
  created () {
    if (this.params.vslNm !== undefined) {
      this.getVesselInquiryList(1, true)
    }
  },
  methods: {
    /**
     * param
     * |_ index : 이동할 페이지
     * |_ searcgFlag : 검색유무
     *          true  => 검색 시
     *          false => 페이지 이동
     */
    async getVesselInquiryList (index, searchFlag) {
      if (this.isInit && index < 1) {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.002')) // 처음페이지입니다.
        return
      }
      if (this.isInit && this.pagination && index > this.pagination.pageCnt) {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.001')) // 마지막페이지입니다.
        return
      }

      if (searchFlag && !this.checkVslNm()) return

      // !searchFlag(페이지 이동 시) 현재 페이지와 이동할 페이지 같다면
      if (!searchFlag && this.isInit && this.params.curPage === index) return

      this.params.curPage = index

      const formData = JSON.parse(JSON.stringify(this.params))

      await ExchangeRate.searchExVessel(formData)
        .then((response) => {
          const resp = response.data

          const list = resp.vesselInquiryList
          const pagination = resp.pagination

          this.vesselInquiryList = []
          this.listCountNumber = 0

          if (list !== undefined && list.length > 0) {
            // api response 값 data에 세팅
            this.pagination = pagination

            //전체 글 개수
            this.listCountNumber = list[0].listCnt

            this.vesselInquiryList = list

            if (!this.isInit) this.isInit = true
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    // '선박명' check
    checkVslNm (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm')
      }

      const elemVslNm = frm.querySelector('#ipt_vsl_nm')
      const valVslNm = this.params.vslNm
      let isOk = true

      if (valVslNm === '') {
        let msg = this.$t('msg.CSCT060G061.008') // 선박명을 입력하세요.
        this.$ekmtcCommon.showErrorTooltip(elemVslNm, msg)
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemVslNm)
      }

      return isOk
    }
  }
}
</script>
