var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "400px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.layerClose()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(
            _vm._s(
              this.$ekmtcCommon.isEmpty(_vm.taxTamplateInfo.tplRno)
                ? _vm.$t("msg.ONEX070G050.001")
                : _vm.$t("msg.ONEX070G050.002")
            )
          ),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("p", { staticClass: "txt_desc none" }, [
            _vm._v(
              _vm._s(
                this.$ekmtcCommon.isEmpty(_vm.taxTamplateInfo.tplRno)
                  ? _vm.$t("msg.ONEX070G050.003")
                  : _vm.$t("msg.ONEX070G050.004")
              )
            ),
          ]),
          _c("div", { staticClass: "mt5" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTamplateInfo.templateNm,
                  expression: "taxTamplateInfo.templateNm",
                },
              ],
              staticClass: "col_10",
              attrs: { type: "text" },
              domProps: { value: _vm.taxTamplateInfo.templateNm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.taxTamplateInfo,
                    "templateNm",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg mr5",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setTaxTemplateInfo()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX070G050.005")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.layerClose()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX070G050.006")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }