<template>
  <div class="content widfix">
    <e-breadcrumbs />

    <win-layer-pop>
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="popupParams"
      />
    </win-layer-pop>

    <div
      id="frm"
    >
      <h1 class="page_title">{{ $t('menu.MENU08.010') }}</h1>

      <div class="content_box mt10">
        <!-- content_box -->

        <p class="color_black mt5">
          <!-- 신규회원가입의 경우, 고객님의 정보보안을 위한 심사기간으로 영업일기준 최대 2일이 소요될 수 있습니다.<br />
          회원가입관련 문의사항이 있으시면 고객만족센터(02-311-6114)으로 연락 바랍니다. -->
          {{ $t('msg.MYIN010G010.000') }}<br>
          {{ $t('msg.MYIN010G010.001') }}
        </p>

        <table class="tbl_row mt20">
          <colgroup>
            <col width="130"><col><col width="140"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                {{ $t('msg.CMBA100.0059') }}
              </th>
              <td>
                <div class="idcheck">
                  <!-- 유저 ID -->
                  <span class="inputbox">
                    <input
                      type="text" id="userId"
                      v-model="params.userId"
                      :placeholder="$t('msg.CMBA100.0060_5')"
                      maxlength="10"
                      autocomplete="false"
                      @keyup="checkValidation('ID', $event)"
                    ></span>
                  <span class="chkbutton">
                    <a class="button sm" href="#" @click="checkId">{{ $t('msg.CMBA100.0060') }}
                    </a>
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CMBA100.0061') }}</th>  <!-- 홈페이지 접속 지역 -->
              <td class="full">
                <select
                  id="selectedCtrList"
                  v-model="params.userCtrCd"
                  @change="selectedCtr($event)"
                >
                  <option
                    v-for="ctr in ctrList"
                    :key="ctr.ctrCd"
                    :value="ctr.ctrCd"
                  >
                    {{ ctr.ctrEnm }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.0078') }}</th>  <!-- 비밀번호 -->
              <td>
                <input id="userPwd"
                       v-model="params.userPwd"
                       type="password"
                       @blur="checkValidation('PWD')"
                       autocomplete="new-password"
                >
              </td>
              <th>{{ $t('msg.CMBA100.0079') }}</th> <!-- 비밀번호 확인-->
              <td>
                <input id="userPwd2"
                       v-model="params.userPwd2"
                       type="password"
                       @blur="checkValidation('PWD2')"
                       autocomplete="new-password"
                >
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.0087') }}({{ $t('msg.CMBA100.0080') }})</th><!-- 회사명-->
              <td>
                <input type="text" id="compName" v-model="params.compName" @blur="checkValidation('COMP_NAME')" autocomplete="off">
              </td>
              <th>{{ $t('msg.CMBA100.0087') }}({{ $t('msg.CMBA100.0083') }})</th><!-- 회사명(영문)-->
              <td>
                <input type="text"
                       id="compEName"
                       v-model="params.compEName"
                       @blur="checkValidation('COMP_ENAME')"
                       oninput="javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '' );"
                       autocomplete="off"
                >
              </td>
            </tr>
            <tr v-show="isKor">
              <th>{{ $t('msg.CMBA100.0088') }}</th><!-- 사업자등록번호 -->
              <td>
                <span class="tbl_form">
                  <span class="dv col_3">
                    <input autocomplete="off" @blur="checkValidation('BIZ_NO')" :class="bizNoCls" type="text" maxlength="3" id="bizNo1" ref="bizNo1" v-mask="'###'" v-model="params.bizNo1">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_3">
                    <input autocomplete="off" @blur="checkValidation('BIZ_NO')" :class="bizNoCls" type="text" maxlength="2" id="bizNo2" ref="bizNo2" v-mask="'###'" v-model="params.bizNo2">
                  </span>
                  <span class="sp"></span>
                  <span class="dv">
                    <input autocomplete="off" @blur="checkValidation('BIZ_NO')" :class="bizNoCls" type="text" maxlength="5" id="bizNo3" ref="bizNo3" v-mask="'#####'" v-model="params.bizNo3">
                  </span>
                  <input type="hidden" id="bizNo" />
                </span>
              </td>
              <th>{{ $t('msg.MYIN010G010.002') }}</th><!-- 사업자등록증 첨부 -->
              <td>
                <div class="filebox">
                  <my-info-dext-uploader v-model="fileList" :cat-list="catList" @completed="uploadCompleted" />
                </div>
              </td>
            </tr>
            <tr>
              <!-- 우편번호 - 회사주소 -->
              <!-- 우편번호  -->
              <th>{{ $t('msg.CMBA100.0090') }}</th>
              <td colspan="3">
                <div v-show="isKor" class="mb5">
                  <input type="text" maxlength="5"
                         v-model="params.postNo"
                         :class="postNoCls"
                         readonly
                         @click="clickSearchAddr"
                         autocomplete="false"
                  >
                  <span class="sp2"></span>
                  <input id="postNo" type="hidden" />
                  <span>
                    <e-search-address ref="searchAddr" class="button sm" @setAddr="customCallback">{{ $t('msg.MYIN010G010.003') }}</e-search-address>
                  </span>
                </div>
                <div>
                  <input id="compAddr" type="text" v-model="params.compAddr" autocomplete="false">
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.00108') }}</th> <!-- 전화번호 -->
              <td>
                <span class="tbl_form">
                  <span class="dv col_2">
                    <input autocomplete="off" @blur="checkValidation('TEL')" :class="telCls" type="text" maxlength="4" id="tel1" v-mask="'####'" v-model="params.tel1">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_2">
                    <input autocomplete="off" @blur="checkValidation('TEL')" :class="telCls" type="text" maxlength="3" id="tel2" v-mask="'###'" name="tel2" v-model="params.tel2" :disabled="params.userCtrCd == 'HK' || params.userCtrCd == 'SG'">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_3">
                    <input autocomplete="off" @blur="checkValidation('TEL')" :class="telCls" type="text" maxlength="4" id="tel3" v-mask="'####'" v-model="params.tel3">
                  </span>
                  <span class="sp"></span>
                  <span class="dv">
                    <input autocomplete="off" @blur="checkValidation('TEL')" :class="telCls" type="text" maxlength="4" id="tel4" v-mask="'####'" v-model="params.tel4">
                  </span>
                  <input type="hidden" id="telNo" />
                </span>
                <span v-if="$i18n.locale === 'en-US'" style="color:#2556c0; font-size:13px;">ex) HK : 852-Non-1234-1234 , SG : 65-Non-1234-1234 , MY : 60-12-1234-1234</span>
              </td>
              <th>{{ $t('msg.CMBA100.00163') }}</th> <!-- 이메일 -->
              <td>
                <span class="tbl_form">
                  <span class="col_50p float_left">
                    <input autocomplete="off" @blur="checkValidation('EMAIL')" :class="emlCls" type="text" id="email1" v-model="params.email1" placeholder="" oninput="javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '' );">
                  </span>
                  <span class="col_7p float_left text_center">@</span>
                  <span class="col_43p float_right">
                    <input autocomplete="off" @blur="checkValidation('EMAIL')" :class="emlCls" type="text" id="email2" v-model="params.email2" placeholder="" oninput="javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '' );">
                  </span>
                  <input type="hidden" id="eml" />
                </span>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.00109') }}<span class="txt_normal">({{ $t('msg.MYIN010G010.004') }})</span></th> <!-- 팩스 -->
              <td>
                <span class="tbl_form">
                  <span class="dv col_2">
                    <input autocomplete="off" type="text" maxlength="4" id="fax1" v-mask="'####'" v-model="params.fax1">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_2">
                    <input autocomplete="off" type="text" maxlength="3" id="fax2" v-mask="'####'" name="fax2" :disabled="params.userCtrCd == 'HK' || params.userCtrCd == 'SG'" v-model="params.fax2">
                  </span>
                  <span class="sp"></span>
                  <span class="dv col_3">
                    <input autocomplete="off" type="text" maxlength="4" id="fax3" v-mask="'####'" v-model="params.fax3">
                  </span>
                  <span class="sp"></span>
                  <span class="dv">
                    <input autocomplete="off" type="text" maxlength="4" id="fax4" v-mask="'####'" v-model="params.fax4">
                  </span>
                </span>
              </td>
              <th>{{ $t('msg.CMBA100.0091') }}</th>  <!-- 회원구분 -->
              <td class="full">
                <select id="cstCatCd" v-model="params.cstCatCd" @change="checkValidation('CST_CAT_CD')">
                  <option
                    v-for="cstCat in cstCatList"
                    :key="cstCat.value"
                    :value="cstCat.value"
                  >
                    {{ cstCat.text }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CMBA100.0097') }}</th>  <!-- 부킹담당사무소 -->
              <td class="full">
                <select id="selectedBkgPlc" v-model="params.bkgPlcCd" @change="checkValidation('BKG_PLC')">
                  <option
                    v-for="bkgPlc in selectedBkgPlcList"
                    :key="bkgPlc.plcCd"
                    :value="bkgPlc.plcCd"
                  >
                    {{ bkgPlc.plcEnm }}
                  </option>
                </select>
              </td>
              <th>{{ $t('msg.CMBA100.00104') }}</th> <!-- 처리업무 -->
              <td class="full">
                <input type="radio" id="trad1" v-model="params.eiCatCd" name="trad" value="O" checked="checked" />
                <label for="trad1" class="mr10"><span></span>{{ $t('msg.CMBA100.00105') }}</label>

                <input type="radio" id="trad2" v-model="params.eiCatCd" name="trad" value="I" />
                <label for="trad2" class="mr10"><span></span>{{ $t('msg.CMBA100.00106') }}</label>

                <input type="radio" id="trad3" v-model="params.eiCatCd" name="trad" value="T" />
                <label for="trad3" class="mr10"><span></span>{{ $t('msg.CMBA100.00107') }}</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
      <div class="flex_box mt10">
        <button class="button blue lg ml_auto mr5" @click.prevent="checkForm()">{{ $t('msg.MYIN010G010.005') }}</button>
        <button class="button gray lg mr5" @click.prevent="clearValidationAll()">
          {{ $t('msg.MYIN040G010.034') }} <!-- 초기화 -->
        </button>
        <button class="button gray lg"
                href=""
                @click.prevent="$router.push('/main')"
        >
          {{ $t('msg.MYIN010G010.006') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import member from '@/api/rest/user/member'
import ESearchAddress from '@/components/common/ESearchAddress'
import SampleDextUploader from '@/pages/sample/SampleDextUploader'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

export default ({
  name: 'JoinMemberForm',
  components: {
    WinLayerPop,
    ESearchAddress,
    SampleDextUploader,
    EBreadcrumbs,
    MyInfoDextUploader: () => import('@/pages/user/MyInfoDextUploader')
  },
  props: {
    agreea1: {
      type: Boolean,
      default: false
    },
    agreea2: {
      type: Boolean,
      default: false
    },
    agreea3: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ctrList: [],
      cstCatList: [
        { text: this.$t('msg.CMBA100.0092'), value: '01' },
        { text: this.$t('msg.CMBA100.0093'), value: '02' },
        { text: this.$t('msg.CMBA100.0094'), value: '03' },
        { text: this.$t('msg.CMBA100.0095'), value: '04' },
        { text: this.$t('msg.CMBA100.0096'), value: '05' }, // 관세사
        { text: 'Vendor', value: '06' },
        { text: 'CODECO (S.CN)', value: '07' },
        { text: 'OFF-HIRE EOR', value: '08' },
        { text: 'CFS', value: '09' }
      ],
      bkgPlcList: [],
      selectedCtrList: '',
      selectedBkgPlc: '',
      selectedBkgPlcList: [],
      selectedCstCatList: '',
      isBizFile: '',
      keyWord: '',

      params:
        {
          userEmail: '',
          email1: '',
          email2: '',
          compEName: '',
          compName: '',
          bizNo1: '',
          bizNo2: '',
          bizNo3: '',
          compAddr: '',
          cstCatCd: '',
          bkgPlcCd: '',
          tel1: '',
          tel2: '',
          tel3: '',
          tel4: '',
          fax1: '',
          fax2: '',
          fax3: '',
          fax4: '',
          eiCatCd: 'T',
          postNo: '',

          recvCnstYn: this.agreea3 === true ? 'Y' : 'N',
          choPsnlInfCltnCnstYn: this.agreea2 === true ? 'Y' : 'N',
          mdtyPsnlInfCltnCnstYn: 'Y',
          userId: '',
          userPwd: '',
          userPwd2: '',

          userCtrCd: '',
          userName: ' ', // to-be 에서 프로필 생성으로 변경 후 필수 입력값이 아님
          userType: '9',
          fileId: ''
        },

        // 파일관련
        customComponent: null,
        popupParams: {},
        uploadParams: [],
        fileOriginalName: '',
        fileList: [],
        catList: [],

        // 다국어 항목 변수
        isKor: true,
        space: '',
        isCheckedId: false,

        postNoCls: 'wid100',
        bizNoCls: '',
        telCls: '',
        emlCls: '',
        faxCls: '',
        orgUserId: null,
        compAddrFlag: false
    }
  },
  watch: {
    'params.compAddr': {
      deep: true,
      handler () {
        if (this.compAddrFlag) {
          this.checkValidation('COMP_ADDR')
        }
        this.compAddrFlag = true
      }
    }
  },
  created () {
    // 다국어 항목 제어
    if (this.$i18n.locale !== 'ko-KR') {
      this.isKor = false
      this.space = ' '
    }
  },
  mounted () {
    member.getCtrList().then(response => {
      const resp = response.data

      this.ctrList = resp.commList
      this.bkgPlcList = resp.commList1
    })
    this.clearValidationAll()
  },
  methods: {
    uploadCompleted (fileInfo) {
      // console.log(fileInfo)
      // console.log(this.fileList)

      if (fileInfo !== null) {
        this.fileList = fileInfo
        this.fileOriginalName = fileInfo[0].originalName
        this.params.fileId = fileInfo[0].fileId
      }
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.MYIN010G010.007'), // 확인
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    openConfirmAlert (msg, func) {
      let obj = {
        // onClose: ,
        onConfirm: func,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.MYIN010G010.006'),
        customConfirmBtnText: this.$t('msg.MYIN010G010.007'),
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    confirmFn () {
      this.$router.push('/main')
    },
    selectedCtr (event) {
      this.checkValidation('ACS_CTR')
      this.selectedBkgPlcList = this.bkgPlcList.filter(temp => temp.ctrCd === event.target.value)

      // 홍콩, 싱가포르 일 때 선택시 전화번호, 팩스 VALIDATION
      if (event.target.value === 'HK' || event.target.value === 'SG') {
        this.params.tel2 = ''
      }

      // 한국어 외 항목 노출 제어
      // if (event.target.value === 'KR') {
      //   this.isKor = true
      // } else {
      //   this.isKor = false
      // }

      //this.checkValidationCtrList()
      this.params.bkgPlcCd = ''
    },
    checkId () {
      const form = document.querySelector('#frm')
      //let isIdOk = this.checkValidationId(form)
      let isIdOk = this.checkValidation('ID', false)
      let chkAll = false

      // if (this.params.userId.length > 10) {
      //   console.log('userId length>>> ')
      //   return false
      // }

      if (typeof isIdOk === 'boolean' && isIdOk) {
        chkAll = true
      } else if (typeof isIdOk === 'object') {
        chkAll = true
      } else if (typeof isIdOk === 'boolean' && !isIdOk) {
        chkAll = false
      }

      if (chkAll) {
        member.checkId(this.params.userId)
          .then(response => {
              // console.log(response)
              if (response.status === 200) {
                if (response.data.count === 0) {
                  this.openAlert(this.$t('msg.MYIN010G010.008'))
                  this.isCheckedId = true
                  this.orgUserId = this.params.userId
                  this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#userId'))
                } else {
                  this.openAlert(this.$t('msg.MYIN010G010.009'))
                  this.isCheckedId = false
                  this.params.userId = ''
                  this.orgUserId = null
                }
              } else {
                this.orgUserId = null
                this.isCheckedId = false
                console.log(response.status)
              }
          })
          .catch(error => {
              this.isCheckedId = false
              console.log(error)
          })
      }
    },
    checkValidation (type, event) {
      let isValid = true
      let rtnChkId = {
         isValid: isValid,
         chkDup: true
      }
      let errMsg = ''
      if (this.orgUserId === null || this.orgUserId !== this.params.userId) {
        this.isCheckedId = false
      }
      if (type === 'ALL' || type === 'ID') {
        let reg_exp = new RegExp('^[a-zA-Z][a-zA-Z0-9]{0,12}$', 'g')
        let match = reg_exp.exec(this.params.userId)

        if (type === 'ID' && event) {
         this.params.userId = event.target.value.toUpperCase()
        }

        if (this.isEmpty(this.params.userId)) {
          //ID를 입력해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.041')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#userId'), errMsg)
          isValid = false
        } else if (this.params.userId.charAt(0) < 'A' || this.params.userId.charAt(0) > 'Z') {
          // ID의 첫글자는 영문자만 허용됩니다.
          errMsg = this.$i18n.t('msg.MYIN010G010.054')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#userId'), errMsg)
          isValid = false
        } else if (match == null) {
          // ID는 숫자와 영문자만 허용됩니다.
          errMsg = this.$i18n.t('msg.MYIN010G010.055')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#userId'), errMsg)
          isValid = false
        } else if (this.params.userId.length > 12) {
          //ID의 최대길이는 12자입니다.
          errMsg = this.$i18n.t('msg.MYIN010G010.039')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#userId'), errMsg)
          isValid = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#userId'))
        }
      }

      if (type === 'ALL') {
        if (!this.isCheckedId) {
          //ID 중복확인을 해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.040')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#userId'), errMsg)
          isValid = false
          // return rtnChkId
        }
      }

      if (type === 'ALL' || type === 'ACS_CTR') {
        if (this.isEmpty(this.params.userCtrCd)) {
          //홈페이지 접속 지역을 선택해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.058')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#selectedCtrList'), errMsg)
          isValid = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#selectedCtrList'))
        }
      }

      if (type === 'ALL' || type === 'PWD') {
        if (this.isEmpty(this.params.userPwd)) {
          //비밀번호를 입력해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.042')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#userPwd'), errMsg)
          isValid = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#userPwd'))
          if (!this.isEmpty(this.params.userPwd2)) {
            this.checkValidation('PWD2')
          }
        }
      }

      if (type === 'ALL' || type === 'PWD2') {
        if (this.isEmpty(this.params.userPwd2) || (!this.isEmpty(this.params.userPwd) && this.params.userPwd !== this.params.userPwd2)) {
          //비밀번호를 확인해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.043')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#userPwd2'), errMsg)
          isValid = false
        } else {
           this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#userPwd2'))
        }
      }

      if ((type === 'ALL' || type === 'COMP_NAME') && this.isKor) {
        if (this.isEmpty(this.params.compName)) {
          //회사명(한글)을 입력해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.044')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#compName'), errMsg)
          isValid = false
        } else {
           this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#compName'))
        }
      }

      if (type === 'ALL' || type === 'COMP_ENAME') {
        if (this.isEmpty(this.params.compEName)) {
          //회사명(영문)을 입력해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.045')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#compEName'), errMsg)
          isValid = false
        } else {
           this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#compEName'))
        }
      }

      if ((type === 'ALL' || type === 'BIZ_NO') && this.isKor) {
        if (this.isEmpty(this.params.bizNo1) && this.isEmpty(this.params.bizNo2) && this.isEmpty(this.params.bizNo3)) {
          //사업자 등록번호를 입력해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.046')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#bizNo'), errMsg)
          this.bizNoCls = 'input_error'
          isValid = false
        } else if (this.params.bizNo1.length !== 3 || this.params.bizNo2.length !== 2 || this.params.bizNo3.length !== 5) {
           //사업자 등록번호는 000-00-00000 형식입니다.
          this.bizNoCls = 'input_error'
          errMsg = this.$i18n.t('msg.MYIN010G010.047')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#bizNo'), errMsg)
          isValid = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#bizNo'))
          this.bizNoCls = ''
        }
      }

      if ((type === 'ALL' || type === 'COMP_ADDR') && this.isKor) {
        if (this.isEmpty(this.params.postNo) || this.params.postNo.length !== 5) {
          //우편번호가 정상적이지 않습니다.
          errMsg = this.$i18n.t('msg.MYIN010G010.048')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#postNo'), errMsg)
          this.postNoCls = 'input_error wid100'
          isValid = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#postNo'))
          this.postNoCls = 'wid100'
        }
        if (this.isEmpty(this.params.compAddr)) {
          //주소를 입력해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.049')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#compAddr'), errMsg)
          isValid = false
        } else {
           this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#compAddr'))
        }
      }

      if ((type === 'ALL' || type === 'TEL') && this.isKor) {
        if (this.isEmpty(this.params.tel1) || this.isEmpty(this.params.tel2) || this.isEmpty(this.params.tel3) || this.isEmpty(this.params.tel4)) {
          //전화번호를 입력해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.050')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#telNo'), errMsg)
          this.telCls = 'input_error'
          isValid = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#telNo'))
          this.telCls = ''
        }
      }

      if (type === 'ALL' || type === 'EMAIL') {
        if (this.isEmpty(this.params.email1) && this.isEmpty(this.params.email2)) {
          //이메일을 입력해주세요.
          errMsg = this.$i18n.t('msg.MYIN010G010.051')
          this.$ekmtcCommon.showErrorTooltip(document.querySelector('#eml'), errMsg)
          this.emlCls = 'input_error'
          isValid = false
        } else {
          let email = this.params.email1 + '@' + this.params.email2
          // let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
          let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          if (email.match(regExp) == null) {
            errMsg = this.$i18n.t('msg.MYIN010G010.052')
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#eml'), errMsg)
            this.emlCls = 'input_error'
            isValid = false
          } else {
            this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#eml'))
            this.emlCls = ''
          }
        }
      }

      if (type === 'ALL' || type === 'CST_CAT_CD') {
          if (this.isEmpty(this.params.cstCatCd)) {
            //회원구분을 선택해주세요.
             errMsg = this.$i18n.t('msg.MYIN010G010.053')
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#cstCatCd'), errMsg)
            //this.emlCls = 'input_error'
            isValid = false
          } else {
            this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#cstCatCd'))
          }
      }

      if (type === 'ALL' || type === 'BKG_PLC') {
          if (this.isEmpty(this.params.bkgPlcCd)) {
            //부킹담당사무소를 선택해주세요.
            errMsg = this.$i18n.t('msg.MYIN010G010.056')
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#selectedBkgPlc'), errMsg)
            //this.emlCls = 'input_error'
            isValid = false
          } else {
            this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#selectedBkgPlc'))
          }
      }

      return isValid
    },
    async checkForm () {
      const isOk = this.checkValidation('ALL')

      if (isOk) {
          var bizNo = this.params.bizNo1 + this.params.bizNo2 + this.params.bizNo3
          let keyWord = ''

          this.isBizFile = bizNo
          this.params.userEmail = this.params.email1 + '@' + this.params.email2

          if (this.isKor) {
            await member.findCrmYn(this.isBizFile)
                .then(response => {
                  let resp = response.data
                  keyWord = resp.keyWord
                }).catch(error => {
                  console.log(error)
                }).finally(res => {
                  if (keyWord === 'Y' || this.params.fileId !== '') {
                    member.joinMemberForm(this.params)
                        .then(response => {
                        if (response.status === 200) {
                          if (response.data.count === 1) {
                            this.openAlert(this.$t('tem.CMAT035')) // 저장되었습니다.
                            setTimeout(() => {
                              this.confirmFn()
                            }, 2000)
                          } else {
                            this.openAlert(this.$t('msg.MYIN010G010.010'))
                          }
                        } else {
                          console.log(response.status)
                        }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                  } else {
                    this.openAlert(this.$t('msg.MYIN010G010.011')) // 사업자 등록증을 첨부해주세요.
                  }
                })
          } else {
            member.joinMemberForm(this.params)
                .then(response => {
                if (response.status === 200) {
                  if (response.data.count === 1) {
                    this.openAlert(this.$t('tem.CMAT035')) // 저장되었습니다.
                    setTimeout(() => {
                      this.confirmFn()
                    }, 2000)
                  } else {
                    this.openAlert(this.$t('msg.MYIN010G010.010'))
                  }
                } else {
                  console.log(response.status)
                }
                })
                .catch(error => {
                  console.log(error)
                })
          }
      } else {
        // console.log('this.params', this.params)
        // this.clearValidationAll()
      }
    },
    customCallback (addrInfo) {
      this.params.postNo = addrInfo.zipNo
      this.params.compAddr = addrInfo.roadFullAddr
    },
    openPopup (compNm) {
        this.customComponent = compNm

        if (compNm === 'TransCompanyPop') {
          this.popupParams = {
            SrPickUpPlaceData: {
              selectRow: '0',
              cntrSize: '',
              cntrType: ''
            }
          }
        } else if (compNm === 'SampleDextUploader') {
          this.uploadParams = []
          this.popupParams = this.uploadParams
          // console.log('this.uploadParams: ', this.uploadParams)
        } else {
          this.popupParams = {}
        }
        this.$ekmtcCommon.layerOpen()
      },
    closePopup () {
        console.log('uploadParams:', uploadParams)
        this.$ekmtcCommon.layerClose()
    },
    clearValidationAll () {
      this.params = {
          userEmail: '',
          email1: '',
          email2: '',
          compEName: '',
          compName: '',
          bizNo1: '',
          bizNo2: '',
          bizNo3: '',
          compAddr: '',
          cstCatCd: '',
          bkgPlcCd: '',
          tel1: '',
          tel2: '',
          tel3: '',
          tel4: '',
          fax1: '',
          fax2: '',
          fax3: '',
          fax4: '',
          eiCatCd: 'T',
          postNo: '',

          recvCnstYn: this.agreea3 === true ? 'Y' : 'N',
          choPsnlInfCltnCnstYn: this.agreea2 === true ? 'Y' : 'N',
          mdtyPsnlInfCltnCnstYn: 'Y',
          userId: '',
          userPwd: '',
          userPwd2: '',

          userCtrCd: '',
          userName: ' ',
          userType: '9',
          fileId: ''
      }
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#userId'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#userPwd'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#userPwd2'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#compName'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#compEName'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#bizNo'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#postNo'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#compAddr'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#telNo'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#eml'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#cstCatCd'))

      this.postNoCls = 'wid100'
      this.bizNoCls = ''
      this.telCls = ''
      this.emlCls = ''
      this.faxCls = ''
      this.isCheckedId = false
      this.compAddrFlag = false
    },
    clickSearchAddr () {
      this.$refs.searchAddr.openPopup()
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }

  }
})
</script>
