var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content widfix" },
    [
      _c("e-breadcrumbs"),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popupParams },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { attrs: { id: "frm" } }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("menu.MENU08.010"))),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("p", { staticClass: "color_black mt5" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.MYIN010G010.000"))),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.$t("msg.MYIN010G010.001")) + " "),
          ]),
          _c("table", { staticClass: "tbl_row mt20" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [
                  _vm._v(" " + _vm._s(_vm.$t("msg.CMBA100.0059")) + " "),
                ]),
                _c("td", [
                  _c("div", { staticClass: "idcheck" }, [
                    _c("span", { staticClass: "inputbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.userId,
                            expression: "params.userId",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id: "userId",
                          placeholder: _vm.$t("msg.CMBA100.0060_5"),
                          maxlength: "10",
                          autocomplete: "false",
                        },
                        domProps: { value: _vm.params.userId },
                        on: {
                          keyup: function ($event) {
                            return _vm.checkValidation("ID", $event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "userId", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "chkbutton" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button sm",
                          attrs: { href: "#" },
                          on: { click: _vm.checkId },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0060")) + " ")]
                      ),
                    ]),
                  ]),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0061")))]),
                _vm._v(" "),
                _c("td", { staticClass: "full" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.userCtrCd,
                          expression: "params.userCtrCd",
                        },
                      ],
                      attrs: { id: "selectedCtrList" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "userCtrCd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.selectedCtr($event)
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.ctrList, function (ctr) {
                      return _c(
                        "option",
                        { key: ctr.ctrCd, domProps: { value: ctr.ctrCd } },
                        [_vm._v(" " + _vm._s(ctr.ctrEnm) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0078")))]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.userPwd,
                        expression: "params.userPwd",
                      },
                    ],
                    attrs: {
                      id: "userPwd",
                      type: "password",
                      autocomplete: "new-password",
                    },
                    domProps: { value: _vm.params.userPwd },
                    on: {
                      blur: function ($event) {
                        return _vm.checkValidation("PWD")
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "userPwd", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0079")))]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.userPwd2,
                        expression: "params.userPwd2",
                      },
                    ],
                    attrs: {
                      id: "userPwd2",
                      type: "password",
                      autocomplete: "new-password",
                    },
                    domProps: { value: _vm.params.userPwd2 },
                    on: {
                      blur: function ($event) {
                        return _vm.checkValidation("PWD2")
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "userPwd2", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(
                    _vm._s(_vm.$t("msg.CMBA100.0087")) +
                      "(" +
                      _vm._s(_vm.$t("msg.CMBA100.0080")) +
                      ")"
                  ),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.compName,
                        expression: "params.compName",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "compName",
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.params.compName },
                    on: {
                      blur: function ($event) {
                        return _vm.checkValidation("COMP_NAME")
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "compName", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("th", [
                  _vm._v(
                    _vm._s(_vm.$t("msg.CMBA100.0087")) +
                      "(" +
                      _vm._s(_vm.$t("msg.CMBA100.0083")) +
                      ")"
                  ),
                ]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.compEName,
                        expression: "params.compEName",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "compEName",
                      oninput:
                        "javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '' );",
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.params.compEName },
                    on: {
                      blur: function ($event) {
                        return _vm.checkValidation("COMP_ENAME")
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "compEName", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isKor,
                      expression: "isKor",
                    },
                  ],
                },
                [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0088")))]),
                  _c("td", [
                    _c("span", { staticClass: "tbl_form" }, [
                      _c("span", { staticClass: "dv col_3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "###",
                              expression: "'###'",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.bizNo1,
                              expression: "params.bizNo1",
                            },
                          ],
                          ref: "bizNo1",
                          class: _vm.bizNoCls,
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            maxlength: "3",
                            id: "bizNo1",
                          },
                          domProps: { value: _vm.params.bizNo1 },
                          on: {
                            blur: function ($event) {
                              return _vm.checkValidation("BIZ_NO")
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params,
                                "bizNo1",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "sp" }),
                      _c("span", { staticClass: "dv col_3" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "###",
                              expression: "'###'",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.bizNo2,
                              expression: "params.bizNo2",
                            },
                          ],
                          ref: "bizNo2",
                          class: _vm.bizNoCls,
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            maxlength: "2",
                            id: "bizNo2",
                          },
                          domProps: { value: _vm.params.bizNo2 },
                          on: {
                            blur: function ($event) {
                              return _vm.checkValidation("BIZ_NO")
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params,
                                "bizNo2",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "sp" }),
                      _c("span", { staticClass: "dv" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "#####",
                              expression: "'#####'",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.bizNo3,
                              expression: "params.bizNo3",
                            },
                          ],
                          ref: "bizNo3",
                          class: _vm.bizNoCls,
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            maxlength: "5",
                            id: "bizNo3",
                          },
                          domProps: { value: _vm.params.bizNo3 },
                          on: {
                            blur: function ($event) {
                              return _vm.checkValidation("BIZ_NO")
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params,
                                "bizNo3",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("input", { attrs: { type: "hidden", id: "bizNo" } }),
                    ]),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.002")))]),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "filebox" },
                      [
                        _c("my-info-dext-uploader", {
                          attrs: { "cat-list": _vm.catList },
                          on: { completed: _vm.uploadCompleted },
                          model: {
                            value: _vm.fileList,
                            callback: function ($$v) {
                              _vm.fileList = $$v
                            },
                            expression: "fileList",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0090")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isKor,
                          expression: "isKor",
                        },
                      ],
                      staticClass: "mb5",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.postNo,
                            expression: "params.postNo",
                          },
                        ],
                        class: _vm.postNoCls,
                        attrs: {
                          type: "text",
                          maxlength: "5",
                          readonly: "",
                          autocomplete: "false",
                        },
                        domProps: { value: _vm.params.postNo },
                        on: {
                          click: _vm.clickSearchAddr,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "postNo", $event.target.value)
                          },
                        },
                      }),
                      _c("span", { staticClass: "sp2" }),
                      _c("input", { attrs: { id: "postNo", type: "hidden" } }),
                      _c(
                        "span",
                        [
                          _c(
                            "e-search-address",
                            {
                              ref: "searchAddr",
                              staticClass: "button sm",
                              on: { setAddr: _vm.customCallback },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.003")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.compAddr,
                          expression: "params.compAddr",
                        },
                      ],
                      attrs: {
                        id: "compAddr",
                        type: "text",
                        autocomplete: "false",
                      },
                      domProps: { value: _vm.params.compAddr },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.params, "compAddr", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00108")))]),
                _vm._v(" "),
                _c("td", [
                  _c("span", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.tel1,
                            expression: "params.tel1",
                          },
                        ],
                        class: _vm.telCls,
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          maxlength: "4",
                          id: "tel1",
                        },
                        domProps: { value: _vm.params.tel1 },
                        on: {
                          blur: function ($event) {
                            return _vm.checkValidation("TEL")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "tel1", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "###",
                            expression: "'###'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.tel2,
                            expression: "params.tel2",
                          },
                        ],
                        class: _vm.telCls,
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          maxlength: "3",
                          id: "tel2",
                          name: "tel2",
                          disabled:
                            _vm.params.userCtrCd == "HK" ||
                            _vm.params.userCtrCd == "SG",
                        },
                        domProps: { value: _vm.params.tel2 },
                        on: {
                          blur: function ($event) {
                            return _vm.checkValidation("TEL")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "tel2", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv col_3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.tel3,
                            expression: "params.tel3",
                          },
                        ],
                        class: _vm.telCls,
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          maxlength: "4",
                          id: "tel3",
                        },
                        domProps: { value: _vm.params.tel3 },
                        on: {
                          blur: function ($event) {
                            return _vm.checkValidation("TEL")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "tel3", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.tel4,
                            expression: "params.tel4",
                          },
                        ],
                        class: _vm.telCls,
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          maxlength: "4",
                          id: "tel4",
                        },
                        domProps: { value: _vm.params.tel4 },
                        on: {
                          blur: function ($event) {
                            return _vm.checkValidation("TEL")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "tel4", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("input", { attrs: { type: "hidden", id: "telNo" } }),
                  ]),
                  _vm.$i18n.locale === "en-US"
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#2556c0",
                            "font-size": "13px",
                          },
                        },
                        [
                          _vm._v(
                            "ex) HK : 852-Non-1234-1234 , SG : 65-Non-1234-1234 , MY : 60-12-1234-1234"
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00163")))]),
                _vm._v(" "),
                _c("td", [
                  _c("span", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "col_50p float_left" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.email1,
                            expression: "params.email1",
                          },
                        ],
                        class: _vm.emlCls,
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "email1",
                          placeholder: "",
                          oninput:
                            "javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '' );",
                        },
                        domProps: { value: _vm.params.email1 },
                        on: {
                          blur: function ($event) {
                            return _vm.checkValidation("EMAIL")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "email1", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c(
                      "span",
                      { staticClass: "col_7p float_left text_center" },
                      [_vm._v("@")]
                    ),
                    _c("span", { staticClass: "col_43p float_right" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.email2,
                            expression: "params.email2",
                          },
                        ],
                        class: _vm.emlCls,
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          id: "email2",
                          placeholder: "",
                          oninput:
                            "javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '' );",
                        },
                        domProps: { value: _vm.params.email2 },
                        on: {
                          blur: function ($event) {
                            return _vm.checkValidation("EMAIL")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "email2", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("input", { attrs: { type: "hidden", id: "eml" } }),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA100.00109"))),
                  _c("span", { staticClass: "txt_normal" }, [
                    _vm._v("(" + _vm._s(_vm.$t("msg.MYIN010G010.004")) + ")"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fax1,
                            expression: "params.fax1",
                          },
                        ],
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          maxlength: "4",
                          id: "fax1",
                        },
                        domProps: { value: _vm.params.fax1 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "fax1", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv col_2" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fax2,
                            expression: "params.fax2",
                          },
                        ],
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          maxlength: "3",
                          id: "fax2",
                          name: "fax2",
                          disabled:
                            _vm.params.userCtrCd == "HK" ||
                            _vm.params.userCtrCd == "SG",
                        },
                        domProps: { value: _vm.params.fax2 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "fax2", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv col_3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fax3,
                            expression: "params.fax3",
                          },
                        ],
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          maxlength: "4",
                          id: "fax3",
                        },
                        domProps: { value: _vm.params.fax3 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "fax3", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "sp" }),
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####",
                            expression: "'####'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.fax4,
                            expression: "params.fax4",
                          },
                        ],
                        attrs: {
                          autocomplete: "off",
                          type: "text",
                          maxlength: "4",
                          id: "fax4",
                        },
                        domProps: { value: _vm.params.fax4 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "fax4", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0091")))]),
                _vm._v(" "),
                _c("td", { staticClass: "full" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.cstCatCd,
                          expression: "params.cstCatCd",
                        },
                      ],
                      attrs: { id: "cstCatCd" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "cstCatCd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.checkValidation("CST_CAT_CD")
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.cstCatList, function (cstCat) {
                      return _c(
                        "option",
                        {
                          key: cstCat.value,
                          domProps: { value: cstCat.value },
                        },
                        [_vm._v(" " + _vm._s(cstCat.text) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.0097")))]),
                _vm._v(" "),
                _c("td", { staticClass: "full" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.bkgPlcCd,
                          expression: "params.bkgPlcCd",
                        },
                      ],
                      attrs: { id: "selectedBkgPlc" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "bkgPlcCd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.checkValidation("BKG_PLC")
                          },
                        ],
                      },
                    },
                    _vm._l(_vm.selectedBkgPlcList, function (bkgPlc) {
                      return _c(
                        "option",
                        {
                          key: bkgPlc.plcCd,
                          domProps: { value: bkgPlc.plcCd },
                        },
                        [_vm._v(" " + _vm._s(bkgPlc.plcEnm) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00104")))]),
                _vm._v(" "),
                _c("td", { staticClass: "full" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.eiCatCd,
                        expression: "params.eiCatCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "trad1",
                      name: "trad",
                      value: "O",
                      checked: "checked",
                    },
                    domProps: { checked: _vm._q(_vm.params.eiCatCd, "O") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.params, "eiCatCd", "O")
                      },
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "mr10", attrs: { for: "trad1" } },
                    [_c("span"), _vm._v(_vm._s(_vm.$t("msg.CMBA100.00105")))]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.eiCatCd,
                        expression: "params.eiCatCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "trad2",
                      name: "trad",
                      value: "I",
                    },
                    domProps: { checked: _vm._q(_vm.params.eiCatCd, "I") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.params, "eiCatCd", "I")
                      },
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "mr10", attrs: { for: "trad2" } },
                    [_c("span"), _vm._v(_vm._s(_vm.$t("msg.CMBA100.00106")))]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.eiCatCd,
                        expression: "params.eiCatCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "trad3",
                      name: "trad",
                      value: "T",
                    },
                    domProps: { checked: _vm._q(_vm.params.eiCatCd, "T") },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.params, "eiCatCd", "T")
                      },
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "mr10", attrs: { for: "trad3" } },
                    [_c("span"), _vm._v(_vm._s(_vm.$t("msg.CMBA100.00107")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flex_box mt10" }, [
          _c(
            "button",
            {
              staticClass: "button blue lg ml_auto mr5",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.checkForm()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.MYIN010G010.005")))]
          ),
          _c(
            "button",
            {
              staticClass: "button gray lg mr5",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.clearValidationAll()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("msg.MYIN040G010.034")) + " ")]
          ),
          _c(
            "button",
            {
              staticClass: "button gray lg",
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push("/main")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("msg.MYIN010G010.006")) + " ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "140" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }