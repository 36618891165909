<template>
  <div class="popup_wrap" style="width:400px;">
    <button
      class="layer_close"
      @click="layerClose()"
    >close</button>
    <div class="popup_cont" >
      <h1 class="page_title">{{ this.$ekmtcCommon.isEmpty(taxTamplateInfo.tplRno) ? $t('msg.ONEX070G050.001') : $t('msg.ONEX070G050.002') }}</h1><!-- 세금계산서 Template 등록 or 세금계산서 Template 수정 -->
      <div class="content_box">
        <p class="txt_desc none">{{ this.$ekmtcCommon.isEmpty(taxTamplateInfo.tplRno) ? $t('msg.ONEX070G050.003') : $t('msg.ONEX070G050.004') }}</p><!-- Template 제목을 입력하세요. or Template을 수정하시겠습니까? -->
        <div class="mt5">
          <input
            v-model="taxTamplateInfo.templateNm"
            type="text"
            class="col_10"
          >
        </div>
      </div>
      <div class="mt10 text_center">
        <a
          class="button blue lg mr5"
          href="#"
          @click.prevent="setTaxTemplateInfo()"
        >{{ $t('msg.ONEX070G050.005') }}</a><!-- 확인 -->
        <a
          class="button gray lg"
          href="#"
          @click.prevent="layerClose()"
        >{{ $t('msg.ONEX070G050.006') }}</a><!-- 취소 -->
      </div>
    </div>
  </div>
</template>

<script>
import trans from '@/api/rest/trans/trans'

export default {
  name: 'TaxTemplatePop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: ''
        }
      }
    }
  },
  data () {
    return {
      taxTamplateInfo: {}
    }
  },
  created () {
    this.taxTamplateInfo = this.$props.parentInfo
  },
  methods: {
    layerClose: function () {
      this.$emit('close')
      return false
    },
    setTaxTemplateInfo () {
      const confirmOpt = {
        alertType: 'normal',
        customCloseBtnText: this.$t('msg.ONEX070G050.007'), // 닫기
        customConfirmBtnText: this.$t('msg.ONEX070G050.008'), // 확인
        useConfirmBtn: true,
        message: this.$t('msg.ONEX070G050.009'), // 저장하시겠습니까?
        type: 'success',
        onConfirm: this.onConfirmInsert
      }

      this.$ekmtcCommon.alert(confirmOpt)
    },
    onConfirmInsert () {
      const alertOpt = {
        alertType: 'normal',
        customCloseBtnText: this.$t('msg.ONEX070G050.007'), // 닫기
        type: 'info'
      }
      const templateNm = this.taxTamplateInfo.templateNm

      if (this.$ekmtcCommon.isNotEmpty(templateNm)) {
        const tplRno = this.taxTamplateInfo.tplRno

        if (this.$ekmtcCommon.isEmpty(tplRno)) {
          // 등록
          trans.insertTemplateTax(this.taxTamplateInfo)
            .then((response) => {
              if (response.headers.respcode === 'C0000') {
                this.$ekmtcCommon.alert({
                  ...alertOpt,
                  message: this.$t('msg.ONEX070G050.010'), // 저장 성공
                  onClose: this.layerClose
                })
              } else {
                this.$ekmtcCommon.alert({
                  ...alertOpt,
                  message: this.$t('msg.ONEX070G050.011') // 저장 실패
                })
              }
            })
        } else {
          // 수정
          const tplRno = this.taxTamplateInfo.tplRno
          const tplCatCd = this.taxTamplateInfo.tplCatCd

          if (this.$ekmtcCommon.isNotEmpty(tplRno) && this.$ekmtcCommon.isNotEmpty(tplCatCd)) {
            trans.updateTemplateTax(this.taxTamplateInfo)
              .then((response) => {
                if (response.headers.respcode === 'C0000') {
                  this.$ekmtcCommon.alert({
                    ...alertOpt,
                    message: this.$t('msg.ONEX070G050.012'), // 수정 성공
                    onClose: this.layerClose
                  })
                } else {
                  this.$ekmtcCommon.alert({
                    ...alertOpt,
                    message: this.$t('msg.ONEX070G050.013') // 수정 실패
                  })
                }
              })
          } else {
            this.$ekmtcCommon.alert({
              ...alertOpt,
              message: this.$t('msg.ONEX070G050.014'), // 필수 값이 존재하지 않습니다. 담당자에게 문의해 주세요.
              onClose: this.layerClose
            })
          }
        }
      } else {
        this.$ekmtcCommon.alert({
          ...alertOpt,
          message: this.$t('msg.ONEX070G050.015') // 템플릿 명을 입력해 주세요.
        })
      }
    }
  }
}
</script>
