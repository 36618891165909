<template>
  <button class="button gray sm" @click.prevent="openPopup">{{ $t('comp.200') }}</button>
</template>

<script>
export default {
  name: 'ESearchAddress',

  props: {
    value: {}
  },
  mounted () {
    window.setAddress = (addrInfo) => {
      this.$emit('setAddr', addrInfo)
    }
  },

  methods: {
    openPopup () {
      var jusoUrl = 'https://www.juso.go.kr/addrlink/addrLinkUrl.do'
      var method = 'POST'

      if (location.host.includes('localhost')) {
        jusoUrl = '#/open-popup/address-result'
        method = 'GET'
      }

      var callbackUrl = location.protocol + '//'
      callbackUrl += location.host
      callbackUrl += '/open-popup/address-result'

      var jusoKey = 'U01TX0FVVEgyMDIyMDIwODA5MTA0OTExMjIxNTk='
      let addressForm = $('<form>').attr({ target: 'addressSearch', method: method, action: jusoUrl }).appendTo(document.body)
      $('<input type="hidden" >').attr({ name: 'confmKey' }).val(jusoKey).appendTo(addressForm)
      $('<input type="hidden" >').attr({ name: 'returnUrl' }).val(callbackUrl).appendTo(addressForm)
      $('<input type="hidden" >').attr({ name: 'resultType' }).val('4').appendTo(addressForm)

      let p = window.open(
        '',
        'addressSearch',
        'width=870,height=820, scrollbars=yes, resizable=yes'
      )

      addressForm.submit()
    }
  }
}

</script>

<style scoped>

</style>
