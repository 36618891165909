import Send from '../../common.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //exchangeRateList - 고객센터 > 정보조회 > 환율정보 - GET /common/info/exchange-rate
  exchangeRateList (data) {
    return Send({
      url: '/common/info/exchange-rate',
      method: 'get',
      params: data
    })
  },
  //searchExVessel - 고객센터 > 정보조회 > Vessel Inquiry 조회 - GET /common/info/vessel-inquiry
  searchExVessel (data) {
    return Send({
      url: '/common/info/vessel-inquiry',
      method: 'get',
      params: data
    })
  }
}
