<template>
  <div
    class="popup_wrap"
    style="width:1000px; height:600px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.VOSD100_M4.005') }} {{ $t('msg.SPEC030.030') }}</h1><!-- 선명/항차 조회 -->
      <form id="frm">
        <div class="content_box">
          <!-- content_box  -->
          <div class="border_bottom_gray_box pt0">
            <table class="tbl_search">
              <colgroup>
                <col width="80px"><col><col width="80px"><col><col width="100px">
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ $t('msg.VOSD100.002') }}</th>
                  <td>
                    <e-auto-complete-place id="polPol" @change="changePol" :ctr-cd="pol.ctrCd" :plc-cd="pol.plcCd" :is-char="true"
                                           :is-char-alert="true"
                    />
                  </td>
                  <th><span class="ml20">{{ $t('msg.VOSD100.003') }}</span></th>
                  <td>
                    <e-auto-complete-place id="podPod" @change="changePod" :ctr-cd="pod.ctrCd" :plc-cd="pod.plcCd" :is-char="true"
                                           :is-char-alert="true"
                    />
                  </td>
                  <td class="text_right">
                    <a class="button blue sh" @click="getLegSchedule(searchYear, searchMonth, 'N')">{{ $t('msg.CMBA100.00120') }}</a><!-- 검색 -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="border_bottom_gray_box border_bottom_none">
            <!-- border_bottom_gray_box -->
            <p class="tit_label ml10">{{ $t('msg.VOSD100_M5.014') }}</p><!-- 나의 스케줄 -->
            <div style="display:block;">
              <ul class="my_sche">
                <li v-for="(list,index) in myScheduleList" :key="index">
                  <input
                    type="radio"
                    :id="list.schId"
                    :value="list.schId"
                    :checked="list.schId === schId"
                    name="mysch"
                    @click="selectMySchedule(list)"
                  />
                  <label :for="list.schId"><span></span>{{ list.polNm2 }} - {{ list.podNm2 }}</label>
                </li>
              </ul>
            </div>
          </div><!-- border_bottom_gray_box // -->
        </div><!-- content_box //  -->
        <div class="content_box mt10 pt0">
          <!-- content_box  -->
          <div class="wrap_schedule">
            <!-- wrap_schedule -->
            <div class="schedule_top">
              <!-- schedule_top -->
              <div class="date_area">
                <!-- date_area -->
                <!-- <div class="remark">
                <span class="finish"></span>
                <span class="possible"></span>
                <span class="after"></span>
              </div> -->
                <div class="date" style="width: 100%;">
                  <span><a href="#" @click.prevent="fnChangeCalendar(prevMonth)" class="prev">prev</a></span>
                  <span class="year">{{ currentYear }}</span>
                  <span class="month">{{ currentMonth }}</span>
                  <span><a href="#" @click.prevent="fnChangeCalendar(nextMonth)" class="next">next</a></span>
                </div>
              <!--<div class="memu">
                <a href="#" class="month on">month</a>
                <a href="#" class="list off">list</a>
              </div> -->
              </div><!-- date_area // -->
            </div><!-- schedule_top // -->
            <div class="sc_calender_type">
              <!-- 캘린더 타입 -->
              <table class="tbl_schedule">
                <colgroup>
                  <col span="7" width="14.2%">
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('msg.VOSD100_M5.016') }}</th>
                    <th>{{ $t('msg.VOSD100_M5.017') }}</th>
                    <th>{{ $t('msg.VOSD100_M5.018') }}</th>
                    <th>{{ $t('msg.VOSD100_M5.019') }}</th>
                    <th>{{ $t('msg.VOSD100_M5.020') }}</th>
                    <th>{{ $t('msg.VOSD100_M5.021') }}</th>
                    <th>{{ $t('msg.VOSD100_M5.022') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- make a calendar -->
                  <tr v-for="(date, idx) in dates" :key="idx">
                    <td v-for="(day, secondIdx) in date" :key="secondIdx">
                      <span v-for="(holiday,dayIdx) in holidayList" :key="dayIdx">
                        <div v-if="day == holiday.bascDt2 && holiday.hldNm === 'NOT'">
                          {{ day }}
                        </div>
                        <div v-else-if="day == holiday.bascDt2 && holiday.hldNm !== 'NOT'">
                          <font color="#d81d6c">{{ day }}</font>
                        </div>
                      </span>
                      <div v-if="bound === 'O'">
                        <div v-if="day < 10">
                          <div v-for="(schedule,schIdx) in listSchedule" :key="schIdx">
                            <div v-if="todayStr2 + '0' + day === schedule.etd">
                              <div>
                                <p class="after">
                                  <a href="#" @click.prevent="fnDetailLayer2(schedule)">
                                    {{ schedule.vslNm }}<br>{{ schedule.voyNo }} ({{ schedule.ts === 'N' ? 'Direct' : 'T/S' }})<br>ETD {{ schedule.etdTm.substring(0, 2) }}:{{ schedule.etdTm.substring(2, 4) }}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div v-for="(schedule,schIdx) in listSchedule" :key="schIdx">
                            <div v-if="todayStr2 + day === schedule.etd">
                              <div>
                                <p class="after">
                                  <a href="#" @click.prevent="fnDetailLayer2(schedule)">
                                    {{ schedule.vslNm }}<br>{{ schedule.voyNo }} ({{ schedule.ts === 'N' ? 'Direct' : 'T/S' }})<br>ETD {{ schedule.etdTm.substring(0, 2) }}:{{ schedule.etdTm.substring(2, 4) }}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="bound === 'I'">
                        <div v-if="day < 10">
                          <div v-for="(schedule,schIdx) in listSchedule" :key="schIdx">
                            <div v-if="todayStr2 + '0' + day === schedule.eta">
                              <div v-if="schedule.bkgClose ==='Y'">
                                <p class="possible"><a href="#" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                              </div>
                              <div v-else-if="schedule.bkgClose =='A'">
                                <p class="possible"><a href="#" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                              </div>
                              <div v-else-if="schedule.bkgClose ==='M'">
                                <p class="possible"><a href="#" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                              </div>
                              <div v-else>
                                <p class="possible"><a href="#" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div v-for="(schedule,schIdx) in listSchedule" :key="schIdx">
                            <div v-if="todayStr2 + day === schedule.eta">
                              <div v-if="schedule.bkgClose ==='Y'">
                                <p class="possible"><a href="#" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                              </div>
                              <div v-else-if="schedule.bkgClose =='A'">
                                <p class="possible"><a href="#" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                              </div>
                              <div v-else-if="schedule.bkgClose ==='M'">
                                <p class="possible"><a href="#" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                              </div>
                              <div v-else>
                                <p class="possible"><a href="#" @click.prevent="fnDetailLayer2(schedule)">{{ schedule.vslNm }}</a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                <!-- make a calendar -->
                </tbody>
              </table>
            </div><!-- 캘린더 타입 // -->
          </div><!-- wrap_schedule // -->
        </div>
      </form>
      <div class="mt10 text_center">
        <a class="button gray lg" href="#" @click="$emit('close')">{{ $t('msg.VOSD100.042') }}<!-- 닫기 --></a>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import mySchedules from '@/api/rest/schedule/mySchedule'
import schedules from '@/api/rest/schedule/schedule'

export default {
  name: 'VoyVslSearchPop',
  computed: {
    ...rootComputed
  },
  components: {
    EAutoCompletePlace
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bound: 'O'
        }
      }
    }
  },
  data: function () {
    return {
      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      scheduleForm: {
        startPlcCd: '',
        startPlcName: '',
        destPlcCd: '',
        startCtrCd: '',
        destCtrCd: '',
        destPlcName: '',
        startPolName: '',
        destPodName: ''
      },
      myScheduleList: [],
      searchYear: '',
      searchMonth: '',
      currentYear: 0,
      currentMonth: 0,
      holidayList: [],
      prevMonth: '',
      nextMonth: '',
      dates: [],
      schId: 0,
      bound: 'O', //this.parentInfo.bound,
      listSchedule: [],
      todayStr2: ''
    }
  },
  created () {
    this.init()
    console.log('$$$$$$$$$$$$ auth $$$$$$$$$$$$')
    console.log(this.auth)
    console.log('$$$$$$$$ memberDetail $$$$$$$$')
    console.log(this.memberDetail)
    console.log('$$$$$$$$ parentInfo $$$$$$$$')
    console.log(this.parentInfo)
    if (this.auth !== undefined) {
      this.lang = this.auth.serviceLang
    }
  },
  methods: {
    async init () {
      // 나의 스케줄
      if (this.auth.userId !== '') {
        const list = await mySchedules.getMySchedule()
        this.myScheduleList = list.data.mySchedule
      }
      // 초기화
      let formData = []
      // 구간별 스케줄 폼
      formData = await schedules.getLegScheduleForm()
      this.searchYear = formData.data.searchYear
      this.searchMonth = formData.data.searchMonth
      this.currentYear = formData.data.searchYear
      this.currentMonth = formData.data.searchMonth
      this.holidayList = formData.data.holidayList
      this.prevMonth = formData.data.prevMonth
      this.nextMonth = formData.data.nextMonth
      this.calendarData(formData.data.searchYear, formData.data.searchMonth)
    },
    changePol (vo) {
      this.pol = vo
      this.scheduleForm.startPolName = vo.plcEnm
      this.scheduleForm.startCtrCd = vo.ctrCd
      this.scheduleForm.startPlcCd = vo.plcCd
      this.scheduleForm.startPlcName = vo.plcEnm
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.pod = vo
      this.scheduleForm.destPodName = vo.plcEnm
      this.scheduleForm.destCtrCd = vo.ctrCd
      this.scheduleForm.destPlcCd = vo.plcCd
      this.scheduleForm.destPlcName = vo.plcEnm
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    selectMySchedule (list) {
      console.log(list)
      this.scheduleForm.startPolName = list.polNm
      this.scheduleForm.destPodName = list.podNm
      this.scheduleForm.startCtrCd = list.polCn
      this.scheduleForm.startPlcCd = list.polCd
      this.scheduleForm.startPlcName = list.polNm2
      this.scheduleForm.destCtrCd = list.podCn
      this.scheduleForm.destPlcCd = list.podCd
      this.scheduleForm.destPlcName = list.podNm2
      this.pol.ctrCd = list.polCn
      this.pol.plcCd = list.polCd
      this.pod.ctrCd = list.podCn
      this.pod.plcCd = list.podCd
      const frm = document.querySelector('#frm')
      const elemPol = frm.querySelector('#polPol')
      const elemPod = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elemPol)
      this.$ekmtcCommon.hideErrorTooltip(elemPod)
    },
    fnChangeCalendar (yearMonth) {
      console.log(yearMonth)
      this.yyyymm = yearMonth
      this.getLegSchedule(yearMonth.substring(0, 4), yearMonth.substring(4, 6))
    },
    getLegSchedule (year, month, filter) {
      console.log('############# Click Search Button >>> ')
      // this.show2ndVessel = 'N'
      const frm = document.querySelector('#frm')
      let isAllOk = true
      if (this.scheduleForm.startPlcCd === '') {
        // alert(this.$t('art.CMATK068') + this.$t('tem.CMAT003')) // 출발지역을 선택하세요.
        this.checkValidationPol(frm)
        this.curPage = 1
        isAllOk = false
      }
      if (this.scheduleForm.destPlcCd === '') {
        // alert(this.$t('art.CMATK010') + this.$t('tem.CMAT003')) // 도착지역을 선택하세요.
        this.checkValidationPod(frm)
        this.curPage = 1
        isAllOk = false
      }
      if (!isAllOk) {
        return
      }
      //
      if (this.searchYN === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT033'))
        return
      }
      this.searchYN = 'Y'
      const params = {
        startPlcCd: this.scheduleForm.startPlcCd,
        searchMonth: month,
        pointChangeYN: '',
        bound: this.bound,
        filterPolCd: '',
        pointLength: '',
        startPlcName: this.scheduleForm.startPlcName,
        destPlcCd: this.scheduleForm.destPlcCd,
        searchYear: year,
        filterYn: 'N',
        searchYN: 'Y',
        filterPodCd: '',
        hiddestPlcCd: '',
        startCtrCd: this.scheduleForm.startCtrCd,
        destCtrCd: this.scheduleForm.destCtrCd,
        filterTs: 'N',
        filterDirect: 'N',
        filterTranMax: '0',
        filterTranMin: '0',
        hidstartPlcCd: '',
        destPlcName: this.scheduleForm.destPlcName,
        main: 'N',
        legIdx: '0',
        calendarOrList: 'C'
      }
      console.log(params)
      schedules.getLegSchedule(params).then((rtnData) => {
        console.log('$$$$$$$$ getLegSchedule $$$$$$$$')
        console.log(rtnData)
        this.bound = rtnData.data.bound
        this.listSchedule = rtnData.data.listSchedule
        this.todayStr2 = rtnData.data.todayStr2
        this.prevMonth = rtnData.data.prevMonth
        this.nextMonth = rtnData.data.nextMonth
        this.holidayList = rtnData.data.holidayList
        this.searchYear = rtnData.data.todayStr2.substring(0, 4)
        this.searchMonth = rtnData.data.todayStr2.substring(4, 6)
        this.currentYear = rtnData.data.todayStr2.substring(0, 4)
        this.currentMonth = rtnData.data.todayStr2.substring(4, 6)
        if (rtnData !== null) {
          this.calendarData(year, month)
          // 상세정보 표시
          this.searchYN = 'N'
        }
      })
    },
    checkValidationPol (frm) {
      const elem = frm.querySelector('#polPol')
      let msg = this.$t('art.CMATK068') + ' ' + this.$t('tem.CMAT003') // '출발지역을 선택하세요.'
      this.$ekmtcCommon.showErrorTooltip(elem, msg)
    },
    checkValidationPod (frm) {
      const elem = frm.querySelector('#podPod')
      let msg = this.$t('art.CMATK010') + ' ' + this.$t('tem.CMAT003') // '도착지역을 선택하세요.'
      this.$ekmtcCommon.showErrorTooltip(elem, msg)
    },
    fnDetailLayer2 (vo) {
      console.log('>>>>>> click Ship <<<<<<')
      console.log(vo)

      this.$emit('selectFunc', vo)
      this.$emit('close')
    },
    // make a calendar
    calendarData (year, month) {
      const [
        monthFirstDay,
        monthLastDate,
        lastMonthLastDate
      ] = this.getFirstDayLastDate(year, month)

      this.dates = this.getMonthOfDays(
        monthFirstDay,
        monthLastDate,
        lastMonthLastDate
      )
    },
    getFirstDayLastDate (year, month) {
      const firstDay = new Date(year, month - 1, 1).getDay() // 이번 달 시작 요일
      const lastDate = new Date(year, month, 0).getDate() // 이번 달 마지막 날짜
      let lastYear = year
      let lastMonth = month - 1
      if (month === 1) {
        lastMonth = 12
        lastYear -= 1
      }
      const prevLastDate = new Date(lastYear, lastMonth, 0).getDate() // 지난 달 마지막 날짜
      return [firstDay, lastDate, prevLastDate]
    },
    getMonthOfDays (
      monthFirstDay,
      monthLastDate,
      prevMonthLastDate
    ) {
      let day = 1
      let prevDay = (prevMonthLastDate - monthFirstDay) + 1
      const dates = []
      let weekOfDays = []
      while (day <= monthLastDate) {
        if (day === 1) {
          // 1일이 어느 요일인지에 따라 테이블에 그리기 위한 지난 셀의 날짜들을 구할 필요가 있다.
          for (let j = 0; j < monthFirstDay; j += 1) {
            // weekOfDays.push(prevDay) // 전달 날짜 No 필요
            weekOfDays.push('')
            prevDay += 1
          }
        }
        weekOfDays.push(day)
        if (weekOfDays.length === 7) {
          // 일주일 채우면
          dates.push(weekOfDays)
          weekOfDays = [] // 초기화
        }
        day += 1
      }
      const len = weekOfDays.length
      if (len > 0 && len < 7) {
        for (let k = 1; k <= 7 - len; k += 1) {
          // weekOfDays.push(k) // 다음달 날짜 No 필요
          weekOfDays.push('')
        }
      }
      if (weekOfDays.length > 0) dates.push(weekOfDays) // 남은 날짜 추가
      return dates
    }
    // make a calendar
  }
}
</script>
