<template>
  <div>
    <!-- container_ekmtc -->
    <win-layer-pop>
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :popup-params="popupParams"
      />
    </win-layer-pop>

    <div class="content resize">
      <!-- content : 화면 리사이징 되는 화면 resize 추가 -->
      <div class="location">
        <!-- location -->
        <ul>
          <li><a href="#" class="home">home</a></li>
          <li><a href="#">정산/발행</a></li>
          <li>Freetime 요청</li>
        </ul>
      </div><!-- location -->

      <div class="flex_box">
        <h1 class="page_title">Freetime 요청</h1>
        <span class="ml_auto mt20">
          <a class="button md print" href="#" @click.prevent="screenToPdfDownload()"><span class="btn_icon print"></span>인쇄</a>
        </span>
      </div>

      <div class="content_box">
        <!-- content_box -->
        <table class="tbl_search t2">
          <colgroup>
            <col width="*"><col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <td>
                <div class="tbl_form">
                  <span class="dv wid150">
                    <input type="radio" id="rdo1" name="rdo" v-model="params.eiCatCd" value="O" checked>
                    <label for="rdo1" class="mr20">{{ $t('msg.CSBL400.012') }}</label>

                    <input type="radio" id="rdo2" name="rdo" v-model="params.eiCatCd" value="I">
                    <label for="rdo2">{{ $t('msg.CSBL400.011') }}</label>
                  </span>
                  <span class="dv wid150">
                    <select v-model="params.dtKnd">
                      <option value="BL" checked>B/L No.</option>
                      <option value="CN">Container No.</option>
                    </select>
                  </span>
                  <span class="dv wid70">
                    <input type="text" value="KMTC" disabled="disabled">
                  </span>
                  <span class="dv">
                    <input type="text" v-model="params.no" class="wid150" style="margin:0;">
                  </span>
                </div>
              </td>

              <td class="text_right">
                <button href="#none" class="button blue sh" @click="detailInquiry">검색</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->

      <div class="content_box mt10">
        <!-- content_box -->

        <div class="flex_box">
          <h2 class="content_title">입금현황</h2>
          <span class="ml_auto cal_btn">
            <a href="#none" class="sm button ml2">Free Time Tariff</a>
          </span>
        </div>

        <table class="tbl_row">
          <colgroup>
            <col style="width:20%">
            <col style="width:18%">
            <col style="width:13%">
            <col style="width:20%">
            <col style="width:13%">
            <col style="width:16%">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">Demurrage<br>입금정보입력</th>
              <td>
                <p><a href="#" class="link">TEMU8990456 처리중</a></p>
                <p><a href="#" class="link">TEMU8990456 처리중</a></p>
              </td>
              <th scope="row">Detention<br>입금정보입력</th>
              <td>
                <p><a href="#" class="link">TEMU8990456 처리중</a></p>
                <p><a href="#" class="link">TEMU8990456 처리중</a></p>
              </td>
              <th scope="row">Monitoring<br>입금정보입력</th>
              <td></td>
            </tr>
            <tr>
              <th scope="row">Over Freetime Charge<br />입금정보입력</th>
              <td colspan="2"></td>
              <th scope="row">Monitoring<br />입금정보입력</th>
              <td colspan="2"></td>
            </tr>
          </tbody>
        </table>
        <p class="txt_desc emp">RF 컨테이너 전기료 프리타임 : 2일 (초과일수에 대해서 터미널과 직정산)</p>

        <div class="freetime_sec">
          <!-- dem  -->

          <div class="flex_box mt20">
            <h2 class="content_title">DEM(Demurrage)</h2>
            <span class="cal_btn">
              <input type="radio" id="dem1" name="dem">
              <label for="dem1" class="mr10" @click="setFilterDem(true)"><span></span>반출 지체중인 컨테이너</label>

              <input type="radio" id="dem2" name="dem">
              <label for="dem2" @click="setFilterDem(false)"><span></span>전체 컨테이너 보기</label>

            </span>
          </div>
          <div id="realgrid1" style="width: 100%; height: 200px"></div>
          <tfoot>
            <tr>
              <th colspan="7" class="text_right">SUB TOTAL</th>
              <td class="text_right">{{ subTotAmt1 }}</td>
              <td class="text_right">{{ subTotAmtVat1 }}</td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">D/C AMOUNT</th>
              <td class="text_right">{{ dcRt1 }} %</td>
              <td class="text_right">{{ dcAmtVat1 }} </td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">GRAND TOTAL</th>
              <td colspan="2" class="text_right">{{ grandTotAmtVAT1 }}</td>
            </tr>
          </tfoot>

          <div class="flex_box btn_set mt10">
            <span>
              <a class="button sm" href="#">반출일 KL-NET 재전송</a>
              <span class="position_relative">
                <button class="tbl_icon help">도움말</button>
                <div class="tooltip_wrap short position_absolute" style="width:220px; display:none; left:0; top:24px;"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button class="close">close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.

                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </span>

            <a class="button blue sm ml_auto mr3" href="#" @click="openPopup('getInvoicePop')">Previous Issued Invoice</a>
            <a class="button blue sm mr3" href="#">입금/계산서 정보입력</a>
            <a class="button blue sm mr3" href="#">Proforma Invoice</a>
            <a class="button blue sm mr3" href="#">Freetime 연장요청</a>
            <a class="button blue sm" href="#">Freetime 요청내역</a>
          </div>
        </div><!-- dem //  -->

        <div class="freetime_sec">
          <!-- det  -->
          <div class="flex_box mt20">
            <h2 class="content_title">DET(Detention)</h2>
            <span class="cal_btn">
              <span>
                <input type="checkbox" id="det"><label for="det">비용 발생 컨테이너만 보기
                </label>
              </span>
            </span>
          </div>

          <div id="realgrid2" style="width: 100%; height: 200px"></div>
          <tr>
            <th colspan="7" class="text_right">SUB TOTAL</th>
            <td class="text_right">{{ subTotAmt2 }}</td>
            <td class="text_right">{{ subTotAmtVat2 }}</td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">D/C AMOUNT</th>
            <td class="text_right">{{ dcRt2 }} %</td>
            <td class="text_right">{{ dcAmtVat2 }} </td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">GRAND TOTAL</th>
            <td colspan="2" class="text_right">{{ grandTotAmtVAT2 }}</td>
          </tr>

          <div class="flex_box btn_set mt10">
            <span>
              <a class="button sm" href="#">반납일 KL-NET 재전송</a>
              <span class="position_relative">
                <button class="tbl_icon help">도움말</button>
                <div class="tooltip_wrap short position_absolute" style="width:220px; display:none; left:0; top:24px;"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button class="close">close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반납일이 상이 할 경우 [반납일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.

                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </span>

            <a class="button blue sm ml_auto mr3" href="#">Previous Issued Invoice</a>
            <a class="button blue sm mr3" href="#">입금/계산서 정보입력</a>
            <a class="button blue sm mr3" href="#">Proforma Invoice</a>
            <a class="button blue sm mr3" href="#">Freetime 연장요청</a>
            <a class="button blue sm" href="#">Freetime 요청내역</a>
          </div>
        </div>

        <div class="freetime_sec">
          <!-- mor  -->
          <div class="flex_box mt20">
            <h2 class="content_title">MOR(Monitoring)</h2>
            <span class="cal_btn">
              <span>
                <input type="checkbox" id="mor"><label for="mor">비용 발생 컨테이너만 보기
                </label>
              </span>
            </span>
          </div>

          <div id="realgrid3" style="width: 100%; height: 200px"></div>
          <tr>
            <th colspan="7" class="text_right">SUB TOTAL</th>
            <td class="text_right">{{ subTotAmt3 }}</td>
            <td class="text_right">{{ subTotAmtVat3 }}</td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">D/C AMOUNT</th>
            <td class="text_right">{{ dcRt3 }} %</td>
            <td class="text_right">{{ dcAmtVat3 }} </td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">GRAND TOTAL</th>
            <td colspan="2" class="text_right">{{ grandTotAmtVAT3 }}</td>
          </tr>
          <div class="flex_box btn_set mt10">
            <span>
              <a class="button sm" href="#">반출일 KL-NET 재전송</a>
              <span class="position_relative">
                <button class="tbl_icon help">도움말</button>
                <div class="tooltip_wrap short position_absolute" style="width:220px; display:none; left:0; top:24px;"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button class="close">close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.
                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </span>
            <a class="button blue sm ml_auto mr3" href="#">Previous Issued Invoice</a>
            <a class="button blue sm mr3" href="#">입금/계산서 정보입력</a>
            <a class="button blue sm mr3" href="#">Proforma Invoice</a>
            <a class="button blue sm mr3" href="#">Freetime 연장요청</a>
            <a class="button blue sm" href="#">Freetime 요청내역</a>
          </div>
        </div> <!-- mor //  -->

        <div class="freetime_sec">
          <!-- str  -->
          <div class="flex_box mt20">
            <h2 class="content_title">STR(Storage)</h2>
            <span class="cal_btn">
              <span>
                <input type="checkbox" id="str"><label for="str">비용 발생 컨테이너만 보기
                </label>
              </span>
            </span>
          </div>

          <div id="realgrid4" style="width: 100%; height: 200px"></div>
          <tr>
            <th colspan="7" class="text_right">SUB TOTAL</th>
            <td class="text_right">{{ subTotAmt4 }}</td>
            <td class="text_right">{{ subTotAmtVat4 }}</td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">D/C AMOUNT</th>
            <td class="text_right">{{ dcRt4 }} %</td>
            <td class="text_right">{{ dcAmtVat4 }} </td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">GRAND TOTAL</th>
            <td colspan="2" class="text_right">{{ grandTotAmtVAT4 }}</td>
          </tr>

          <div class="flex_box btn_set mt10">
            <span>
              <a class="button sm" href="#">반출일 KL-NET 재전송</a>
              <span class="position_relative">
                <button class="tbl_icon help">도움말</button>
                <div class="tooltip_wrap short position_absolute" style="width:220px; display:none; left:0; top:24px;"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button class="close">close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.
                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </span>

            <a class="button blue sm ml_auto mr3" href="#">Previous Issued Invoice</a>
            <a class="button blue sm mr3" href="#">입금/계산서 정보입력</a>
            <a class="button blue sm mr3" href="#">Proforma Invoice</a>
            <a class="button blue sm mr3" href="#">Freetime 연장요청</a>
            <a class="button blue sm" href="#">Freetime 요청내역</a>
          </div>
        </div><!--str -->

        <div class="freetime_sec">
          <!--DET(Combination)  -->
          <div class="flex_box mt20">
            <h2 class="content_title">DET(Combination)</h2>
            <span class="cal_btn">
              <span>
                <input type="checkbox" id="ofc"><label for="ofc">비용 발생 컨테이너만 보기
                </label>
              </span>
            </span>
          </div>

          <div id="realgrid5" style="width: 100%; height: 200px"></div>
          <tr>
            <th colspan="7" class="text_right">SUB TOTAL</th>
            <td class="text_right">{{ subTotAmt5 }}</td>
            <td class="text_right">{{ subTotAmtVat5 }}</td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">D/C AMOUNT</th>
            <td class="text_right">{{ dcRt5 }} %</td>
            <td class="text_right">{{ dcAmtVat5 }} </td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">GRAND TOTAL</th>
            <td colspan="2" class="text_right">{{ grandTotAmtVAT5 }}</td>
          </tr>

          <div class="flex_box btn_set mt10">
            <span>
              <a class="button sm" href="#">반출/반납일 KL-NET 재전송</a>
              <span class="position_relative">
                <button class="tbl_icon help">도움말</button>
                <div class="tooltip_wrap short position_absolute" style="width:220px; display:none; left:0; top:24px;"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button class="close">close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출/반납일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.

                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </span>

            <a class="button blue sm ml_auto mr3" href="#">Previous Issued Invoice</a>
            <a class="button blue sm mr3" href="#">입금/계산서 정보입력</a>
            <a class="button blue sm mr3" href="#">Proforma Invoice</a>
            <a class="button blue sm mr3" href="#">Freetime 연장요청</a>
            <a class="button blue sm" href="#">Freetime 요청내역</a>
          </div>
        </div> <!-- DET(Combination)// -->

        <div class="freetime_sec">
          <!--ofc  -->
          <div class="flex_box mt20">
            <h2 class="content_title">OFC(Over Freetime Charge)</h2>
            <span class="cal_btn">
              <span>
                <input type="checkbox" id="ofc"><label for="ofc">비용 발생 컨테이너만 보기
                </label>
              </span>
            </span>
          </div>

          <div id="realgrid6" style="width: 100%; height: 200px"></div>
          <tr>
            <th colspan="7" class="text_right">SUB TOTAL</th>
            <td class="text_right">{{ subTotAmt6 }}</td>
            <td class="text_right">{{ subTotAmtVat6 }}</td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">D/C AMOUNT</th>
            <td class="text_right">{{ dcRt6 }} %</td>
            <td class="text_right">{{ dcAmtVat6 }} </td>
          </tr>
          <tr>
            <th colspan="7" class="text_right">GRAND TOTAL</th>
            <td colspan="2" class="text_right">{{ grandTotAmtVAT6 }}</td>
          </tr>

          <div class="flex_box btn_set mt10">
            <span>
              <a class="button sm" href="#">반출/반납일 KL-NET 재전송</a>
              <span class="position_relative">
                <button class="tbl_icon help">도움말</button>
                <div class="tooltip_wrap short position_absolute" style="width:220px; display:none; left:0; top:24px;"><!-- tooltip_wrap   -->
                  <div class="cont"><!-- cont -->
                    <button class="close">close</button>
                    <ul class="bul_list_sm t2">
                      <li>
                        현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출/반납일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.

                      </li>
                    </ul>
                  </div><!-- cont -->
                </div><!-- tooltip_wrap // -->
              </span>
            </span>

            <a class="button blue sm ml_auto mr3" href="#">Previous Issued Invoice</a>
            <a class="button blue sm mr3" href="#">입금/계산서 정보입력</a>
            <a class="button blue sm mr3" href="#">Proforma Invoice</a>
            <a class="button blue sm mr3" href="#">Freetime 연장요청</a>
            <a class="button blue sm" href="#">Freetime 요청내역</a>
          </div>
        </div> <!--ofc// -->
      </div><!-- content_box // -->
    </div><!-- content // -->
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import { fields, columns1, columns2, columns3, columns4, columns5, columns6, layout } from './search-demurrage-detail-form.js'
import searchDemurrageDetailForm from '@/api/rest/settle/searchdemurrageDetailForm'

import WinLayerPop from '@/pages/trans/popup/WinLayerPop'

// Grid
// DEM(Demurrage)
let gridView1 = GridView
let provider1 = LocalDataProvider

// DET(Detention)
let gridView2 = GridView
let provider2 = LocalDataProvider

// MOR(Monitoring)
let gridView3 = GridView
let provider3 = LocalDataProvider

// STR(Storage)
let gridView4 = GridView
let provider4 = LocalDataProvider

// DET(Combination))
let gridView5 = GridView
let provider5 = LocalDataProvider

// OFC(Over Freetime Charge)
let gridView6 = GridView
let provider6 = LocalDataProvider

export default {
    name: 'SearchdemurrageDetailForm',
    components: {
      WinLayerPop
    },
    data () {
        return {

            demdetail1: [],
            demdetail2: [],
            demdetail4: [],
            demdetail8: [],
            demdetail16: [],
            demdetail32: [],
            // test: 0,
            demdetail4list: [],
            detdetail4list: [],
            monitoringDetailList: [],
            storageDetailList: [],
            detcombinedetail4list: [],
            ofcdetail4list: [],

            // DEM(Demurrage)
            subTotAmt1: 0,
            subTotAmtVat1: 0,
            dcRt1: 0,
            dcAmtVat1: 0,
            grandTotAmtVAT1: 0,
            // DET(Detention)
            subTotAmt2: 0,
            subTotAmtVat2: 0,
            dcRt2: 0,
            dcAmtVat2: 0,
            grandTotAmtVAT2: 0,
            // MOR(Monitoring)
            subTotAmt3: 0,
            subTotAmtVat3: 0,
            dcRt3: 0,
            dcAmtVat3: 0,
            grandTotAmtVAT3: 0,
            // STR(Storage)
            subTotAmt4: 0,
            subTotAmtVat4: 0,
            dcRt4: 0,
            dcAmtVat4: 0,
            grandTotAmtVAT4: 0,
            // DET(Combination))
            subTotAmt5: 0,
            subTotAmtVat5: 0,
            dcRt5: 0,
            dcAmtVat5: 0,
            grandTotAmtVAT5: 0,
            // OFC(Over Freetime Charge)
            subTotAmt6: 0,
            subTotAmtVat6: 0,
            dcRt6: 0,
            dcAmtVat6: 0,
            grandTotAmtVAT6: 0,

            params:
            {
                blNo: '',
                cntrNo: '',
                dtKnd: '',
                eiCatCd: '',
                demDetCatCd: '',
                extsDt: '',
                no: ''
            },

            customComponent: null,
            popupParams: {},

            invoicePopProp: {
              blNo: '',
              demdetcatcd: '',
              eicatcd: ''
            }
        }
    },
    mounted () {
    // For grid calculation
    let _this = this

/*
    // test Start
    this.params.blNo = 'PUSE013769'
    // this.params.blNo = 'PUSD930913'
    this.params.cntrNo = ''
    this.params.dtKnd = 'BL'
    this.params.eiCatCd = 'O'
    // test End
 */
    // Page 초기화
    // DEM(Demurrage) START
    provider1 = new LocalDataProvider(true)
    gridView1 = new GridView('realgrid1')
    gridView1.setDataSource(provider1)

    gridView1.setColumns(columns1)
    gridView1.setColumnLayout(layout)

    gridView1.setRowIndicator({
      visible: false
    })

    provider1.setFields(fields)

    // grid item check시 처리
    gridView1.onItemChecked = function (grid, itemIndex, checked) {
        var items = grid.getCheckedItems(true)

        console.log('here: ', grid, itemIndex, checked)
        console.log('items: ', items)

        _this.subTotAmt1 = 0
        _this.subTotAmtVat1 = 0

        for (const index of items) {
          console.log(index)
          console.log(provider1.getValue(index, 'totAmt'))
          console.log(provider1.getValue(index, 'totAmtVat'))
          _this.subTotAmt1 += +provider1.getValue(index, 'totAmt')
          _this.subTotAmtVat1 += +provider1.getValue(index, 'totAmtVat')
        }
        console.log('subTotAmt1: ', _this.subTotAmt1)
        console.log('subTotAmtVat1: ', _this.subTotAmtVat1)

        _this.dcRt1 = _this.demdetail4list[0].dcRt
        _this.dcAmtVat1 = Math.round(_this.subTotAmt1 * 1.1 * _this.dcRt1 / 100)
        _this.grandTotAmtVAT1 = _this.subTotAmtVat1 - _this.dcAmtVat1
        console.log('_this.dcRt1: ', _this.dcRt1)
        console.log('_this.dcAmtVat1: ', _this.dcAmtVat1)
        console.log('_this.grandTotAmtVAT1: ', _this.grandTotAmtVAT1)
    }

    // grid item All check시 처리
    gridView1.onItemAllChecked = function (grid, checked) {
       var items = grid.getCheckedItems(true)

        if (checked) {
          // subTotAmt1, subTotAmtVat1 초기화 후 재 계산
          _this.subTotAmt1 = 0
          _this.subTotAmtVat1 = 0
          for (const index of items) {
            console.log(index)
            console.log(provider1.getValue(index, 'totAmt'))
            console.log(provider1.getValue(index, 'totAmtVat'))
            _this.subTotAmt1 += +provider1.getValue(index, 'totAmt')
            _this.subTotAmtVat1 += +provider1.getValue(index, 'totAmtVat')
          }
          console.log('subTotAmt1: ', _this.subTotAmt1)
          console.log('subTotAmtVat1: ', _this.subTotAmtVat1)

          _this.dcRt1 = _this.demdetail4list[0].dcRt
          _this.dcAmtVat1 = Math.round(_this.subTotAmt1 * 1.1 * _this.dcRt1 / 100)
          _this.grandTotAmtVAT1 = _this.subTotAmtVat1 - _this.dcAmtVat1
          console.log('_this.dcRt1: ', _this.dcRt1)
          console.log('_this.dcAmtVat1: ', _this.dcAmtVat1)
          console.log('_this.grandTotAmtVAT1: ', _this.grandTotAmtVAT1)
        } else {
          // subTotAmt1, subTotAmtVat1 초기화
          _this.subTotAmt1 = 0
          _this.subTotAmtVat1 = 0
          _this.dcRt1 = 0
          _this.dcAmtVat1 = 0
          _this.grandTotAmtVAT1 = 0
        }
    }
    //  params
    let params = this.params
    console.log('params :: ', params)

    // grid item custom filed ( text + image button) check시 처리
    gridView1.registerCustomRenderer('renderer_imgbtn1', {
        initContent: function (parent) {
            var span = this._span = document.createElement('span')
            span.className = 'custom_render_span'
            parent.appendChild(span)
            parent.appendChild(this._button1 = document.createElement('span'))
        },

        canClick: function () {
            return true
        },

        clearContent: function (parent) {
            console.log('DISPOSED......')
            parent.innerHTML = ''
        },

        render: function (grid, model, width, height, info) {
            info = info || {}
            var span = this._span

            console.log('model.value', model.value)

            // text설정.
            span.textContent = model.value
            // span.textContent = model.value.toLocaleDateString().replace(/\./g, '').split(' ').map((v, i) => i > 0 && v.length < 2 ? '0' + v : v).join('/')

            this._value = model.value
            this._button1.className = 'custom_search custom-hover' + (info.focused ? ' custom-focused' : '')
        },

        click: function (event) {
            var grid = this.grid.handler
            var current = grid.getCurrent()
            var cntrNo = provider1.getValue(current.dataRow, 'cntrNo')
            var ovrDds = provider1.getValue(current.dataRow, 'ovrDds')
            var totAmt = provider1.getValue(current.dataRow, 'totAmt')
            var totAmtVat = provider1.getValue(current.dataRow, 'totAmtVat')
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'cntrNo: ' + ' ' + cntrNo)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'overDds: ' + ' ' + ovrDds)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmt: ' + ' ' + totAmt)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmtVat: ' + ' ' + totAmtVat)
            event.preventDefault()

            if (event.target === this._button1) {
            // alert('조회버튼: ' + this._value)
            // Set Params
                params.cntrNo = cntrNo
                params.demDetCatCd = '01'
                params.extsDt = this._value
                searchDemurrageDetailForm.overdayInquiry(params).then(response => {
                    const resp = response.data
                    console.log('resp.rstCnt: ', resp.rstCnt)
                    console.log('resp.rstMap.overDds: ', resp.rstMap.overDds)
                    console.log('resp.rstMap.totAmt: ', resp.rstMap.totAmt)
                    grid.commit()
                    provider1.setValue(current.dataRow, 'ovrDds', resp.rstMap.overDds)
                    provider1.setValue(current.dataRow, 'totAmt', resp.rstMap.totAmt)
                    provider1.setValue(current.dataRow, 'totAmtVat', resp.rstMap.totAmt * 1.1)
                })
            }
        }
    })

    // grid item 초기 구동시 checkbox 전체 선택
    // Data가 Load가 끝나지 않고 setAllCheck 선택시
    // grid 선택이 되지 않음
    // onDataLoadCompleted fn 내에 설정이 필요함
    gridView1.onDataLoadComplated = function (grid) {
      console.log('Grid DataLoadComplated')
      grid.setAllCheck(true)
    }

    // DEM(Demurrage) END

    // DET(Detention) START
    provider2 = new LocalDataProvider(true)
    gridView2 = new GridView('realgrid2')
    gridView2.setDataSource(provider2)

    gridView2.setColumns(columns2)
    gridView2.setColumnLayout(layout)

    gridView2.setRowIndicator({
      visible: false
    })

    provider2.setFields(fields)

    // grid item check시 처리
    gridView2.onItemChecked = function (grid, itemIndex, checked) {
        var items = grid.getCheckedItems(true)

        console.log('here: ', grid, itemIndex, checked)
        console.log('items: ', items)

        _this.subTotAmt2 = 0
        _this.subTotAmtVat2 = 0

        for (const index of items) {
          console.log(index)
          console.log(provider2.getValue(index, 'totAmt'))
          console.log(provider2.getValue(index, 'totAmtVat'))
          _this.subTotAmt2 += +provider2.getValue(index, 'totAmt')
          _this.subTotAmtVat2 += +provider2.getValue(index, 'totAmtVat')
        }
        console.log('subTotAmt2: ', _this.subTotAmt2)
        console.log('subTotAmtVat2: ', _this.subTotAmtVat2)

        _this.dcRt2 = _this.detdetail4list[0].dcRt
        _this.dcAmtVat2 = Math.round(_this.subTotAmt2 * 1.1 * _this.dcRt2 / 100)
        _this.grandTotAmtVAT2 = _this.subTotAmtVat2 - _this.dcAmtVat2
        console.log('_this.dcRt2: ', _this.dcRt2)
        console.log('_this.dcAmtVat2: ', _this.dcAmtVat2)
        console.log('_this.grandTotAmtVAT2: ', _this.grandTotAmtVAT2)
    }

    // grid item All check시 처리
    gridView2.onItemAllChecked = function (grid, checked) {
       var items = grid.getCheckedItems(true)

        if (checked) {
          // subTotAmt2, subTotAmtVat2 초기화 후 재 계산
          _this.subTotAmt2 = 0
          _this.subTotAmtVat2 = 0
          for (const index of items) {
            console.log(index)
            console.log(provider2.getValue(index, 'totAmt'))
            console.log(provider2.getValue(index, 'totAmtVat'))
            _this.subTotAmt2 += +provider2.getValue(index, 'totAmt')
            _this.subTotAmtVat2 += +provider2.getValue(index, 'totAmtVat')
          }
          console.log('subTotAmt2: ', _this.subTotAmt2)
          console.log('subTotAmtVat2: ', _this.subTotAmtVat2)

          _this.dcRt2 = _this.detdetail4list[0].dcRt
          _this.dcAmtVat2 = Math.round(_this.subTotAmt2 * 1.1 * _this.dcRt2 / 100)
          _this.grandTotAmtVAT2 = _this.subTotAmtVat2 - _this.dcAmtVat2
          console.log('_this.dcRt2: ', _this.dcRt2)
          console.log('_this.dcAmtVat2: ', _this.dcAmtVat2)
          console.log('_this.grandTotAmtVAT2: ', _this.grandTotAmtVAT2)
        } else {
          // subTotAmt1, subTotAmtVat1 초기화
          _this.subTotAmt2 = 0
          _this.subTotAmtVat2 = 0
          _this.dcRt2 = 0
          _this.dcAmtVat2 = 0
          _this.grandTotAmtVAT2 = 0
        }
    }
    //  params
    params = this.params
    console.log('params :: ', params)

    // grid item custom filed ( text + image button) check시 처리
    gridView2.registerCustomRenderer('renderer_imgbtn2', {
        initContent: function (parent) {
            var span = this._span = document.createElement('span')
            span.className = 'custom_render_span'
            parent.appendChild(span)
            parent.appendChild(this._button1 = document.createElement('span'))
        },

        canClick: function () {
            return true
        },

        clearContent: function (parent) {
            console.log('DISPOSED......')
            parent.innerHTML = ''
        },

        render: function (grid, model, width, height, info) {
            info = info || {}
            var span = this._span

            console.log('model.value', model.value)

            // text설정.
            span.textContent = model.value
            // span.textContent = model.value.toLocaleDateString().replace(/\./g, '').split(' ').map((v, i) => i > 0 && v.length < 2 ? '0' + v : v).join('/')

            this._value = model.value
            this._button1.className = 'custom_search custom-hover' + (info.focused ? ' custom-focused' : '')
        },

        click: function (event) {
            var grid = this.grid.handler
            var current = grid.getCurrent()
            var cntrNo = provider2.getValue(current.dataRow, 'cntrNo')
            var ovrDds = provider2.getValue(current.dataRow, 'ovrDds')
            var totAmt = provider2.getValue(current.dataRow, 'totAmt')
            var totAmtVat = provider2.getValue(current.dataRow, 'totAmtVat')
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'cntrNo: ' + ' ' + cntrNo)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'overDds: ' + ' ' + ovrDds)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmt: ' + ' ' + totAmt)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmtVat: ' + ' ' + totAmtVat)
            event.preventDefault()

            if (event.target === this._button1) {
            // alert('조회버튼: ' + this._value)
            // Set Params
                params.cntrNo = cntrNo
                params.demDetCatCd = '01'
                params.extsDt = this._value
                searchDemurrageDetailForm.overdayInquiry(params).then(response => {
                    const resp = response.data
                    console.log('resp.rstCnt: ', resp.rstCnt)
                    console.log('resp.rstMap.overDds: ', resp.rstMap.overDds)
                    console.log('resp.rstMap.totAmt: ', resp.rstMap.totAmt)
                    grid.commit()
                    provider2.setValue(current.dataRow, 'ovrDds', resp.rstMap.overDds)
                    provider2.setValue(current.dataRow, 'totAmt', resp.rstMap.totAmt)
                    provider2.setValue(current.dataRow, 'totAmtVat', resp.rstMap.totAmt * 1.1)
                })
            }
        }
    })

    // grid item 초기 구동시 checkbox 전체 선택
    // Data가 Load가 끝나지 않고 setAllCheck 선택시
    // grid 선택이 되지 않음
    // onDataLoadCompleted fn 내에 설정이 필요함
    gridView2.onDataLoadComplated = function (grid) {
      console.log('Grid DataLoadComplated')
      grid.setAllCheck(true)
    }

    // DET(Detention) END

    // MOR(Monitoring) START
    provider3 = new LocalDataProvider(true)
    gridView3 = new GridView('realgrid3')
    gridView3.setDataSource(provider3)

    gridView3.setColumns(columns3)
    gridView3.setColumnLayout(layout)

    gridView3.setRowIndicator({
      visible: false
    })

    provider3.setFields(fields)

    // grid item check시 처리
    gridView3.onItemChecked = function (grid, itemIndex, checked) {
        var items = grid.getCheckedItems(true)

        console.log('here: ', grid, itemIndex, checked)
        console.log('items: ', items)

        _this.subTotAmt3 = 0
        _this.subTotAmtVat3 = 0

        for (const index of items) {
          console.log(index)
          console.log(provider3.getValue(index, 'totAmt'))
          console.log(provider3.getValue(index, 'totAmtVat'))
          _this.subTotAmt3 += +provider3.getValue(index, 'totAmt')
          _this.subTotAmtVat3 += +provider3.getValue(index, 'totAmtVat')
        }
        console.log('subTotAmt3: ', _this.subTotAmt3)
        console.log('subTotAmtVat3: ', _this.subTotAmtVat3)

        _this.dcRt3 = _this.monitoringDetailList[0].dcRt
        _this.dcAmtVat3 = Math.round(_this.subTotAmt3 * 1.1 * _this.dcRt3 / 100)
        _this.grandTotAmtVAT3 = _this.subTotAmtVat3 - _this.dcAmtVat3
        console.log('_this.dcRt3: ', _this.dcRt3)
        console.log('_this.dcAmtVat3: ', _this.dcAmtVat3)
        console.log('_this.grandTotAmtVAT3: ', _this.grandTotAmtVAT3)
    }

    // grid item All check시 처리
    gridView3.onItemAllChecked = function (grid, checked) {
       var items = grid.getCheckedItems(true)

        if (checked) {
          // subTotAmt2, subTotAmtVat3 초기화 후 재 계산
          _this.subTotAmt3 = 0
          _this.subTotAmtVat3 = 0
          for (const index of items) {
            console.log(index)
            console.log(provider3.getValue(index, 'totAmt'))
            console.log(provider3.getValue(index, 'totAmtVat'))
            _this.subTotAmt3 += +provider3.getValue(index, 'totAmt')
            _this.subTotAmtVat3 += +provider3.getValue(index, 'totAmtVat')
          }
          console.log('subTotAmt3: ', _this.subTotAmt3)
          console.log('subTotAmtVat3: ', _this.subTotAmtVat3)

          _this.dcRt3 = _this.monitoringDetailList[0].dcRt
          _this.dcAmtVat3 = Math.round(_this.subTotAmt3 * 1.1 * _this.dcRt3 / 100)
          _this.grandTotAmtVAT3 = _this.subTotAmtVat3 - _this.dcAmtVat3
          console.log('_this.dcRt3: ', _this.dcRt3)
          console.log('_this.dcAmtVat3: ', _this.dcAmtVat3)
          console.log('_this.grandTotAmtVAT3: ', _this.grandTotAmtVAT3)
        } else {
          // subTotAmt1, subTotAmtVat1 초기화
          _this.subTotAmt3 = 0
          _this.subTotAmtVat3 = 0
          _this.dcRt3 = 0
          _this.dcAmtVat3 = 0
          _this.grandTotAmtVAT3 = 0
        }
    }
    //  params
    params = this.params
    console.log('params :: ', params)

    // grid item custom filed ( text + image button) check시 처리
    gridView3.registerCustomRenderer('renderer_imgbtn3', {
        initContent: function (parent) {
            var span = this._span = document.createElement('span')
            span.className = 'custom_render_span'
            parent.appendChild(span)
            parent.appendChild(this._button1 = document.createElement('span'))
        },

        canClick: function () {
            return true
        },

        clearContent: function (parent) {
            console.log('DISPOSED......')
            parent.innerHTML = ''
        },

        render: function (grid, model, width, height, info) {
            info = info || {}
            var span = this._span

            console.log('model.value', model.value)

            // text설정.
            span.textContent = model.value
            // span.textContent = model.value.toLocaleDateString().replace(/\./g, '').split(' ').map((v, i) => i > 0 && v.length < 3 ? '0' + v : v).join('/')

            this._value = model.value
            this._button1.className = 'custom_search custom-hover' + (info.focused ? ' custom-focused' : '')
        },

        click: function (event) {
            var grid = this.grid.handler
            var current = grid.getCurrent()
            var cntrNo = provider3.getValue(current.dataRow, 'cntrNo')
            var ovrDds = provider3.getValue(current.dataRow, 'ovrDds')
            var totAmt = provider3.getValue(current.dataRow, 'totAmt')
            var totAmtVat = provider3.getValue(current.dataRow, 'totAmtVat')
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'cntrNo: ' + ' ' + cntrNo)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'overDds: ' + ' ' + ovrDds)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmt: ' + ' ' + totAmt)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmtVat: ' + ' ' + totAmtVat)
            event.preventDefault()

            if (event.target === this._button1) {
            // alert('조회버튼: ' + this._value)
            // Set Params
                params.cntrNo = cntrNo
                params.demDetCatCd = '01'
                params.extsDt = this._value
                searchDemurrageDetailForm.overdayInquiry(params).then(response => {
                    const resp = response.data
                    console.log('resp.rstCnt: ', resp.rstCnt)
                    console.log('resp.rstMap.overDds: ', resp.rstMap.overDds)
                    console.log('resp.rstMap.totAmt: ', resp.rstMap.totAmt)
                    grid.commit()
                    provider3.setValue(current.dataRow, 'ovrDds', resp.rstMap.overDds)
                    provider3.setValue(current.dataRow, 'totAmt', resp.rstMap.totAmt)
                    provider3.setValue(current.dataRow, 'totAmtVat', resp.rstMap.totAmt * 1.1)
                })
            }
        }
    })

    // grid item 초기 구동시 checkbox 전체 선택
    // Data가 Load가 끝나지 않고 setAllCheck 선택시
    // grid 선택이 되지 않음
    // onDataLoadCompleted fn 내에 설정이 필요함
    gridView3.onDataLoadComplated = function (grid) {
      console.log('Grid DataLoadComplated')
      grid.setAllCheck(true)
    }

    // MOR(Monitoring) END

    // STR(Storage) START
    provider4 = new LocalDataProvider(true)
    gridView4 = new GridView('realgrid4')
    gridView4.setDataSource(provider4)

    gridView4.setColumns(columns4)
    gridView4.setColumnLayout(layout)

    gridView4.setRowIndicator({
      visible: false
    })

    provider4.setFields(fields)

    // grid item check시 처리
    gridView4.onItemChecked = function (grid, itemIndex, checked) {
        var items = grid.getCheckedItems(true)

        console.log('here: ', grid, itemIndex, checked)
        console.log('items: ', items)

        _this.subTotAmt4 = 0
        _this.subTotAmtVat4 = 0

        for (const index of items) {
          console.log(index)
          console.log(provider4.getValue(index, 'totAmt'))
          console.log(provider4.getValue(index, 'totAmtVat'))
          _this.subTotAmt4 += +provider4.getValue(index, 'totAmt')
          _this.subTotAmtVat4 += +provider4.getValue(index, 'totAmtVat')
        }
        console.log('subTotAmt4: ', _this.subTotAmt4)
        console.log('subTotAmtVat4: ', _this.subTotAmtVat4)

        _this.dcRt4 = _this.storageDetailList[0].dcRt
        _this.dcAmtVat4 = Math.round(_this.subTotAmt4 * 1.1 * _this.dcRt4 / 100)
        _this.grandTotAmtVAT4 = _this.subTotAmtVat4 - _this.dcAmtVat4
        console.log('_this.dcRt4: ', _this.dcRt4)
        console.log('_this.dcAmtVat4: ', _this.dcAmtVat4)
        console.log('_this.grandTotAmtVAT4: ', _this.grandTotAmtVAT4)
    }

    // grid item All check시 처리
    gridView4.onItemAllChecked = function (grid, checked) {
       var items = grid.getCheckedItems(true)

        if (checked) {
          // subTotAmt2, subTotAmtVat4 초기화 후 재 계산
          _this.subTotAmt4 = 0
          _this.subTotAmtVat4 = 0
          for (const index of items) {
            console.log(index)
            console.log(provider4.getValue(index, 'totAmt'))
            console.log(provider4.getValue(index, 'totAmtVat'))
            _this.subTotAmt4 += +provider4.getValue(index, 'totAmt')
            _this.subTotAmtVat4 += +provider4.getValue(index, 'totAmtVat')
          }
          console.log('subTotAmt4: ', _this.subTotAmt4)
          console.log('subTotAmtVat4: ', _this.subTotAmtVat4)

          _this.dcRt4 = _this.storageDetailList[0].dcRt
          _this.dcAmtVat4 = Math.round(_this.subTotAmt4 * 1.1 * _this.dcRt4 / 100)
          _this.grandTotAmtVAT4 = _this.subTotAmtVat4 - _this.dcAmtVat4
          console.log('_this.dcRt4: ', _this.dcRt4)
          console.log('_this.dcAmtVat4: ', _this.dcAmtVat4)
          console.log('_this.grandTotAmtVAT4: ', _this.grandTotAmtVAT4)
        } else {
          // subTotAmt1, subTotAmtVat1 초기화
          _this.subTotAmt4 = 0
          _this.subTotAmtVat4 = 0
          _this.dcRt4 = 0
          _this.dcAmtVat4 = 0
          _this.grandTotAmtVAT4 = 0
        }
    }
    //  params
    params = this.params
    console.log('params :: ', params)

    // grid item custom filed ( text + image button) check시 처리
    gridView4.registerCustomRenderer('renderer_imgbtn4', {
        initContent: function (parent) {
            var span = this._span = document.createElement('span')
            span.className = 'custom_render_span'
            parent.appendChild(span)
            parent.appendChild(this._button1 = document.createElement('span'))
        },

        canClick: function () {
            return true
        },

        clearContent: function (parent) {
            console.log('DISPOSED......')
            parent.innerHTML = ''
        },

        render: function (grid, model, width, height, info) {
            info = info || {}
            var span = this._span

            console.log('model.value', model.value)

            // text설정.
            span.textContent = model.value
            // span.textContent = model.value.toLocaleDateString().replace(/\./g, '').split(' ').map((v, i) => i > 0 && v.length < 4 ? '0' + v : v).join('/')

            this._value = model.value
            this._button1.className = 'custom_search custom-hover' + (info.focused ? ' custom-focused' : '')
        },

        click: function (event) {
            var grid = this.grid.handler
            var current = grid.getCurrent()
            var cntrNo = provider4.getValue(current.dataRow, 'cntrNo')
            var ovrDds = provider4.getValue(current.dataRow, 'ovrDds')
            var totAmt = provider4.getValue(current.dataRow, 'totAmt')
            var totAmtVat = provider4.getValue(current.dataRow, 'totAmtVat')
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'cntrNo: ' + ' ' + cntrNo)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'overDds: ' + ' ' + ovrDds)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmt: ' + ' ' + totAmt)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmtVat: ' + ' ' + totAmtVat)
            event.preventDefault()

            if (event.target === this._button1) {
            // alert('조회버튼: ' + this._value)
            // Set Params
                params.cntrNo = cntrNo
                params.demDetCatCd = '01'
                params.extsDt = this._value
                searchDemurrageDetailForm.overdayInquiry(params).then(response => {
                    const resp = response.data
                    console.log('resp.rstCnt: ', resp.rstCnt)
                    console.log('resp.rstMap.overDds: ', resp.rstMap.overDds)
                    console.log('resp.rstMap.totAmt: ', resp.rstMap.totAmt)
                    grid.commit()
                    provider4.setValue(current.dataRow, 'ovrDds', resp.rstMap.overDds)
                    provider4.setValue(current.dataRow, 'totAmt', resp.rstMap.totAmt)
                    provider4.setValue(current.dataRow, 'totAmtVat', resp.rstMap.totAmt * 1.1)
                })
            }
        }
    })

    // grid item 초기 구동시 checkbox 전체 선택
    // Data가 Load가 끝나지 않고 setAllCheck 선택시
    // grid 선택이 되지 않음
    // onDataLoadCompleted fn 내에 설정이 필요함
    gridView4.onDataLoadComplated = function (grid) {
      console.log('Grid DataLoadComplated')
      grid.setAllCheck(true)
    }

    // STR(Storage) END

    // DET(Combination) START
    provider5 = new LocalDataProvider(true)
    gridView5 = new GridView('realgrid5')
    gridView5.setDataSource(provider5)

    gridView5.setColumns(columns5)
    gridView5.setColumnLayout(layout)

    gridView5.setRowIndicator({
      visible: false
    })

    provider5.setFields(fields)

   // grid item check시 처리
    gridView5.onItemChecked = function (grid, itemIndex, checked) {
        var items = grid.getCheckedItems(true)

        console.log('here: ', grid, itemIndex, checked)
        console.log('items: ', items)

        _this.subTotAmt5 = 0
        _this.subTotAmtVat5 = 0

        for (const index of items) {
          console.log(index)
          console.log(provider5.getValue(index, 'totAmt'))
          console.log(provider5.getValue(index, 'totAmtVat'))
          _this.subTotAmt5 += +provider5.getValue(index, 'totAmt')
          _this.subTotAmtVat5 += +provider5.getValue(index, 'totAmtVat')
        }
        console.log('subTotAmt5: ', _this.subTotAmt5)
        console.log('subTotAmtVat5: ', _this.subTotAmtVat5)

        _this.dcRt5 = _this.storageDetailList[0].dcRt
        _this.dcAmtVat5 = Math.round(_this.subTotAmt5 * 1.1 * _this.dcRt5 / 100)
        _this.grandTotAmtVAT5 = _this.subTotAmtVat5 - _this.dcAmtVat5
        console.log('_this.dcRt5: ', _this.dcRt5)
        console.log('_this.dcAmtVat5: ', _this.dcAmtVat5)
        console.log('_this.grandTotAmtVAT5: ', _this.grandTotAmtVAT5)
    }

    // grid item All check시 처리
    gridView5.onItemAllChecked = function (grid, checked) {
       var items = grid.getCheckedItems(true)

        if (checked) {
          // subTotAmt2, subTotAmtVat5 초기화 후 재 계산
          _this.subTotAmt5 = 0
          _this.subTotAmtVat5 = 0
          for (const index of items) {
            console.log(index)
            console.log(provider5.getValue(index, 'totAmt'))
            console.log(provider5.getValue(index, 'totAmtVat'))
            _this.subTotAmt5 += +provider5.getValue(index, 'totAmt')
            _this.subTotAmtVat5 += +provider5.getValue(index, 'totAmtVat')
          }
          console.log('subTotAmt5: ', _this.subTotAmt5)
          console.log('subTotAmtVat5: ', _this.subTotAmtVat5)

          _this.dcRt5 = _this.storageDetailList[0].dcRt
          _this.dcAmtVat5 = Math.round(_this.subTotAmt5 * 1.1 * _this.dcRt5 / 100)
          _this.grandTotAmtVAT5 = _this.subTotAmtVat5 - _this.dcAmtVat5
          console.log('_this.dcRt5: ', _this.dcRt5)
          console.log('_this.dcAmtVat5: ', _this.dcAmtVat5)
          console.log('_this.grandTotAmtVAT5: ', _this.grandTotAmtVAT5)
        } else {
          // subTotAmt1, subTotAmtVat1 초기화
          _this.subTotAmt5 = 0
          _this.subTotAmtVat5 = 0
          _this.dcRt5 = 0
          _this.dcAmtVat5 = 0
          _this.grandTotAmtVAT5 = 0
        }
    }
    //  params
    params = this.params
    console.log('params :: ', params)

    // grid item custom filed ( text + image button) check시 처리
    gridView5.registerCustomRenderer('renderer_imgbtn5', {
        initContent: function (parent) {
            var span = this._span = document.createElement('span')
            span.className = 'custom_render_span'
            parent.appendChild(span)
            parent.appendChild(this._button1 = document.createElement('span'))
        },

        canClick: function () {
            return true
        },

        clearContent: function (parent) {
            console.log('DISPOSED......')
            parent.innerHTML = ''
        },

        render: function (grid, model, width, height, info) {
            info = info || {}
            var span = this._span

            console.log('model.value', model.value)

            // text설정.
            span.textContent = model.value
            // span.textContent = model.value.toLocaleDateString().replace(/\./g, '').split(' ').map((v, i) => i > 0 && v.length < 5 ? '0' + v : v).join('/')

            this._value = model.value
            this._button1.className = 'custom_search custom-hover' + (info.focused ? ' custom-focused' : '')
        },

        click: function (event) {
            var grid = this.grid.handler
            var current = grid.getCurrent()
            var cntrNo = provider5.getValue(current.dataRow, 'cntrNo')
            var ovrDds = provider5.getValue(current.dataRow, 'ovrDds')
            var totAmt = provider5.getValue(current.dataRow, 'totAmt')
            var totAmtVat = provider5.getValue(current.dataRow, 'totAmtVat')
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'cntrNo: ' + ' ' + cntrNo)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'overDds: ' + ' ' + ovrDds)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmt: ' + ' ' + totAmt)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmtVat: ' + ' ' + totAmtVat)
            event.preventDefault()

            if (event.target === this._button1) {
            // alert('조회버튼: ' + this._value)
            // Set Params
                params.cntrNo = cntrNo
                params.demDetCatCd = '01'
                params.extsDt = this._value
                searchDemurrageDetailForm.overdayInquiry(params).then(response => {
                    const resp = response.data
                    console.log('resp.rstCnt: ', resp.rstCnt)
                    console.log('resp.rstMap.overDds: ', resp.rstMap.overDds)
                    console.log('resp.rstMap.totAmt: ', resp.rstMap.totAmt)
                    grid.commit()
                    provider5.setValue(current.dataRow, 'ovrDds', resp.rstMap.overDds)
                    provider5.setValue(current.dataRow, 'totAmt', resp.rstMap.totAmt)
                    provider5.setValue(current.dataRow, 'totAmtVat', resp.rstMap.totAmt * 1.1)
                })
            }
        }
    })

    // grid item 초기 구동시 checkbox 전체 선택
    // Data가 Load가 끝나지 않고 setAllCheck 선택시
    // grid 선택이 되지 않음
    // onDataLoadCompleted fn 내에 설정이 필요함
    gridView5.onDataLoadComplated = function (grid) {
      console.log('Grid DataLoadComplated')
      grid.setAllCheck(true)
    }

    // DET(Combination) END

    // OFC(Over Freetime Charge) START
    provider6 = new LocalDataProvider(true)
    gridView6 = new GridView('realgrid6')
    gridView6.setDataSource(provider6)

    gridView6.setColumns(columns6)
    gridView6.setColumnLayout(layout)

    gridView6.setRowIndicator({
      visible: false
    })

    provider6.setFields(fields)

   // grid item check시 처리
    gridView6.onItemChecked = function (grid, itemIndex, checked) {
        var items = grid.getCheckedItems(true)

        console.log('here: ', grid, itemIndex, checked)
        console.log('items: ', items)

        _this.subTotAmt6 = 0
        _this.subTotAmtVat6 = 0

        for (const index of items) {
          console.log(index)
          console.log(provider6.getValue(index, 'totAmt'))
          console.log(provider6.getValue(index, 'totAmtVat'))
          _this.subTotAmt6 += +provider6.getValue(index, 'totAmt')
          _this.subTotAmtVat6 += +provider6.getValue(index, 'totAmtVat')
        }
        console.log('subTotAmt6: ', _this.subTotAmt6)
        console.log('subTotAmtVat6: ', _this.subTotAmtVat6)

        _this.dcRt6 = _this.storageDetailList[0].dcRt
        _this.dcAmtVat6 = Math.round(_this.subTotAmt6 * 1.1 * _this.dcRt6 / 100)
        _this.grandTotAmtVAT6 = _this.subTotAmtVat6 - _this.dcAmtVat6
        console.log('_this.dcRt6: ', _this.dcRt6)
        console.log('_this.dcAmtVat6: ', _this.dcAmtVat6)
        console.log('_this.grandTotAmtVAT6: ', _this.grandTotAmtVAT6)
    }

    // grid item All check시 처리
    gridView6.onItemAllChecked = function (grid, checked) {
       var items = grid.getCheckedItems(true)

        if (checked) {
          // subTotAmt2, subTotAmtVat6 초기화 후 재 계산
          _this.subTotAmt6 = 0
          _this.subTotAmtVat6 = 0
          for (const index of items) {
            console.log(index)
            console.log(provider6.getValue(index, 'totAmt'))
            console.log(provider6.getValue(index, 'totAmtVat'))
            _this.subTotAmt6 += +provider6.getValue(index, 'totAmt')
            _this.subTotAmtVat6 += +provider6.getValue(index, 'totAmtVat')
          }
          console.log('subTotAmt6: ', _this.subTotAmt6)
          console.log('subTotAmtVat6: ', _this.subTotAmtVat6)

          _this.dcRt6 = _this.storageDetailList[0].dcRt
          _this.dcAmtVat6 = Math.round(_this.subTotAmt6 * 1.1 * _this.dcRt6 / 100)
          _this.grandTotAmtVAT6 = _this.subTotAmtVat6 - _this.dcAmtVat6
          console.log('_this.dcRt6: ', _this.dcRt6)
          console.log('_this.dcAmtVat6: ', _this.dcAmtVat6)
          console.log('_this.grandTotAmtVAT6: ', _this.grandTotAmtVAT6)
        } else {
          // subTotAmt1, subTotAmtVat1 초기화
          _this.subTotAmt6 = 0
          _this.subTotAmtVat6 = 0
          _this.dcRt6 = 0
          _this.dcAmtVat6 = 0
          _this.grandTotAmtVAT6 = 0
        }
    }
    //  params
    params = this.params
    console.log('params :: ', params)

    // grid item custom filed ( text + image button) check시 처리
    gridView6.registerCustomRenderer('renderer_imgbtn6', {
        initContent: function (parent) {
            var span = this._span = document.createElement('span')
            span.className = 'custom_render_span'
            parent.appendChild(span)
            parent.appendChild(this._button1 = document.createElement('span'))
        },

        canClick: function () {
            return true
        },

        clearContent: function (parent) {
            console.log('DISPOSED......')
            parent.innerHTML = ''
        },

        render: function (grid, model, width, height, info) {
            info = info || {}
            var span = this._span

            console.log('model.value', model.value)

            // text설정.
            span.textContent = model.value
            // span.textContent = model.value.toLocaleDateString().replace(/\./g, '').split(' ').map((v, i) => i > 0 && v.length < 6 ? '0' + v : v).join('/')

            this._value = model.value
            this._button1.className = 'custom_search custom-hover' + (info.focused ? ' custom-focused' : '')
        },

        click: function (event) {
            var grid = this.grid.handler
            var current = grid.getCurrent()
            var cntrNo = provider6.getValue(current.dataRow, 'cntrNo')
            var ovrDds = provider6.getValue(current.dataRow, 'ovrDds')
            var totAmt = provider6.getValue(current.dataRow, 'totAmt')
            var totAmtVat = provider6.getValue(current.dataRow, 'totAmtVat')
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'cntrNo: ' + ' ' + cntrNo)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'overDds: ' + ' ' + ovrDds)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmt: ' + ' ' + totAmt)
            console.log('row: ' + current.dataRow + ' ' + 'field: ' + 'totAmtVat: ' + ' ' + totAmtVat)
            event.preventDefault()

            if (event.target === this._button1) {
            // alert('조회버튼: ' + this._value)
            // Set Params
                params.cntrNo = cntrNo
                params.demDetCatCd = '01'
                params.extsDt = this._value
                searchDemurrageDetailForm.overdayInquiry(params).then(response => {
                    const resp = response.data
                    console.log('resp.rstCnt: ', resp.rstCnt)
                    console.log('resp.rstMap.overDds: ', resp.rstMap.overDds)
                    console.log('resp.rstMap.totAmt: ', resp.rstMap.totAmt)
                    grid.commit()
                    provider6.setValue(current.dataRow, 'ovrDds', resp.rstMap.overDds)
                    provider6.setValue(current.dataRow, 'totAmt', resp.rstMap.totAmt)
                    provider6.setValue(current.dataRow, 'totAmtVat', resp.rstMap.totAmt * 1.1)
                })
            }
        }
    })

    // grid item 초기 구동시 checkbox 전체 선택
    // Data가 Load가 끝나지 않고 setAllCheck 선택시
    // grid 선택이 되지 않음
    // onDataLoadCompleted fn 내에 설정이 필요함
    gridView6.onDataLoadComplated = function (grid) {
      console.log('Grid DataLoadComplated')
      grid.setAllCheck(true)
    }

    // OFC(Over Freetime Charge)END
  },
 created: function () {
    console.log('created ')
  },
 updated: function () {
    console.log('updated ')
  },
 beforeUpdate: function () {
    console.log('beforeUpdate ')
    // gridView1.checkAll(true)
  },
  methods: {

    setFilterDem (boolean) {
        console.log('boolean: ', boolean)

        console.log('columns1: ', _this.columns1)
    },

    detailInquiry () {
      if (this.params.no === '') {
        alert('BlNo 또는 CntrNo를 넣어 주세요')
      }
      if (this.params.dtKnd === 'BL') {
        this.params.blNo = this.params.no
        this.params.no = ''
      } else {
        this.params.cntrNo = this.params.no
        this.params.no = ''
      }

      searchDemurrageDetailForm.getFreetime(this.params).then(response => {
          const resp = response.data
          console.log('resp: ', resp)
          // list 설정
          this.demdetail1 = resp.demdetail1
          this.demdetail2 = resp.demdetail2
          this.demdetail4 = resp.demdetail4
          this.demdetail8 = resp.demdetail8
          this.demdetail16 = resp.demdetail16
          this.demdetail32 = resp.demdetail32
          this.demdetail4list = resp.demdetail4list
          this.detdetail4list = resp.detdetail4list
          this.monitoringDetailList = resp.monitoringDetailList
          this.storageDetailList = resp.storageDetailList
          // this.detcombinedetail4list = resp.detcombinedetail4list
          // this.ofcdetail4list = resp.ofcdetail4list

          // List 정보 입력
          if (!(Array.isArray(this.demdetail1) && this.demdetail1.length === 0)) {
            console.log('here!')
          }

          // 부가세 관련 jsp 소스 체크 해볼것!
          // if( '${SESSION_USER_CTR_CD}' == 'MY'  || '${Lang}' == 'JPN'  || '${SESSION_USER_CTR_CD}' == 'TH'   )

          if (!(Array.isArray(this.demdetail4list) && this.demdetail4list.length === 0)) {
            console.log('here!')
              for (const i of this.demdetail4list) {
                  i.totAmtVat = i.totAmt * 1.1
                  this.subTotAmt1 += +i.totAmt
                  this.subTotAmtVat1 += +i.totAmtVat
                  //
                  if (i.cntrSts === 'Y') {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.012')
                  } else {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.014')
                  }
                  // console.log('i: ', i)
              }
              this.dcRt1 = this.demdetail4list[0].dcRt
              this.dcAmtVat1 = Math.round(this.subTotAmt1 * 1.1 * this.dcRt1 / 100)
              this.grandTotAmtVAT1 = this.subTotAmtVat1 - this.dcAmtVat1
              console.log('this.dcRt1: ', this.dcRt1)
              console.log('this.dcAmtVat1: ', this.dcAmtVat1)
              console.log('this.grandTotAmtVAT1: ', this.grandTotAmtVAT1)
          }
          if (!(Array.isArray(this.detdetail4list) && this.detdetail4list.length === 0)) {
              for (const i of this.detdetail4list) {
                  i.totAmtVat = i.totAmt * 1.1
                  this.subTotAmt2 += +i.totAmt
                  this.subTotAmtVat2 += +i.totAmtVat
                  //
                  if (i.freeEndDt === '00000000') {
                      i.freeEndDt = this.$t('msg.CSDBL400_M2.014')
                  }
                  if (i.cntrSts === 'Y') {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.013')
                  } else {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.015')
                  }
                  console.log('i: ', i)
              }
              this.dcRt2 = this.detdetail4list[0].dcRt
              this.dcAmtVat2 = Math.round(this.subTotAmt2 * 1.1 * this.dcRt2 / 100)
              this.grandTotAmtVAT2 = this.subTotAmtVat2 - this.dcAmtVat2
          }
          if (!(Array.isArray(this.monitoringDetailList) && this.monitoringDetailList.length === 0)) {
              for (const i of this.monitoringDetailList) {
                  i.totAmtVat = i.totAmt * 1.1
                  this.subTotAmt3 += +i.totAmt
                  this.subTotAmtVat3 += +i.totAmtVat
                  //
                  if (i.freeEndDt === '00000000') {
                      i.freeEndDt = this.$t('msg.CSDBL400_M2.014')
                  }
                  if (i.cntrSts === 'Y') {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.012')
                  } else {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.014')
                  }
                  console.log('i: ', i)
              }
              this.dcRt3 = this.monitoringDetailList[0].dcRt
              this.dcAmtVat3 = Math.round(this.subTotAmt3 * 1.1 * this.dcRt3 / 100)
              this.grandTotAmtVAT3 = this.subTotAmtVat3 - this.dcAmtVat3
          }
          if (!(Array.isArray(this.storageDetailList) && this.storageDetailList.length === 0)) {
              for (const i of this.storageDetailList) {
                  i.totAmtVat = i.totAmt * 1.1
                  this.subTotAmt4 += +i.totAmt
                  this.subTotAmtVat4 += +i.totAmtVat
                  //
                  if (i.freeEndDt === '00000000') {
                      i.freeEndDt = this.$t('msg.CSDBL400_M2.014')
                  }
                  if (i.cntrSts === 'Y') {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.012')
                  } else {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.014')
                  }
                  console.log('i: ', i)
              }
              this.dcRt4 = this.storageDetailList[0].dcRt
              this.dcAmtVat4 = Math.round(this.subTotAmt4 * 1.1 * this.dcRt4 / 100)
              this.grandTotAmtVAT4 = this.subTotAmtVat4 - this.dcAmtVat4
          }
/*
          if (!(Array.isArray(this.detcombinedetail4list) && this.detcombinedetail4list.length === 0)) {
              for (const i of this.detcombinedetail4list) {
                  i.totAmtVat = i.totAmt * 1.1
                  this.subTotAmt5 += +i.totAmt
                  this.subTotAmtVat5 += +i.totAmtVat
                  //
                  if (i.freeEndDt === '00000000') {
                      i.freeEndDt = this.$t('msg.CSDBL400_M2.014')
                  }
                  if (i.cntrSts === 'Y') {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.013')
                  } else {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.015')
                  }
                  console.log('i: ', i)
              }
              this.dcRt5 = this.detcombinedetail4list[0].dcRt
              this.dcAmtVat5 = Math.round(this.subTotAmt5 * 1.1 * this.dcRt5 / 100)
              this.grandTotAmtVAT5 = this.subTotAmtVat5 - this.dcAmtVat5
          }
          if (!(Array.isArray(this.ofcdetail4list) && this.ofcdetail4list.length === 0)) {
              for (const i of this.ofcdetail4list) {
                  i.totAmtVat = i.totAmt * 1.1
                  this.subTotAmt6 += +i.totAmt
                  this.subTotAmtVat6 += +i.totAmtVat
                  //
                  if (i.freeEndDt === '00000000') {
                      i.freeEndDt = this.$t('msg.CSDBL400_M2.014')
                  }
                  if (i.cntrSts === 'Y') {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.012')
                  } else {
                      i.cntrSts = this.$t('msg.CSDBL400_M2.014')
                  }
                  console.log('i: ', i)
              }
              this.dcRt6 = this.ofcdetail4list[0].dcRt
              this.dcAmtVat6 = Math.round(this.subTotAmt6 * 1.1 * this.dcRt6 / 100)
              this.grandTotAmtVAT6 = this.subTotAmtVat6 - this.dcAmtVat6
          }
           */
          provider1.setRows(this.demdetail4list)
          provider2.setRows(this.detdetail4list)
          provider3.setRows(this.monitoringDetailList)
          provider4.setRows(this.storageDetailList)
/*
          provider5.setRows(this.detcombinedetail4list)
          provider6.setRows(this.ofcdetail4list)
 */
console.log('this.demdetail4list: ', this.demdetail4list)
          // console.log('this.detdetail4list: ', this.detdetail4list)
          // console.log('this.monitoringDetailList: ', this.monitoringDetailList)
          // console.log('this.storageDetailList: ', this.storageDetailList)
          // console.log('this.detcombinedetail4list: ', this.detcombinedetail4list)
          // console.log('this.ofcdetail4list: ', this.ofcdetail4list)
      })
    },
    // param
    // compNm : Import한 컴포넌트 명
    openPopup (compNm) {
      console.log('compNm =' + compNm)
      this.customComponent = compNm

      if (compNm === 'getInvoicePop') {
        this.popupParams = {
          invoicePopProp: {
            blNo: this.params.blNo,
            // demdetcatcd: this.params.demDetCatCd,
            demdetcatcd: '01',
            eicatcd: this.params.eiCatCd
          }

        }
          console.log('this.popupParams: ', this.popupParams)
      } else {
        this.popupParams = {}
      }
      // 팝업 후처리
      // $('.popup_dim').fadeIn()
      // $('body,html').css('overflow', 'hidden')
      this.$ekmtcCommon.layerOpen()
    },

    closePopup () {
      // $('.popup_dim').fadeOut()
      // $('body,html').removeAttr('style')
      this.$ekmtcCommon.layerClose()
    },

    screenToPdfDownload () {
      this.$ekmtcCommon.screenToPdf({ cssSelector: 'body > div', fileName: 'sample-home.pdf', download: 'Y' })
    },

    getInvoice () {
      this.params.demDetCatCd = '01'
      console.log('blNo', this.params.blNo)
      console.log('demdetcatcd', this.params.demDetCatCd)
      console.log('eicatcd', this.params.eiCatCd)

      searchDemurrageDetailForm.getInvoice(this.params).then(response => {
        const resp = response.data
        console.log('resp.rstCnt: ', resp.rstCnt)
        console.log('resp.rstList: ', resp.rstList)
      })
    }
  }
}
</script>

<style scoped>

.custom_search {
  height: 26px;
  margin-left: 2px;
  background: url("https://docs.realgrid.com/public/images/btnImages/search_normal.png") center center no-repeat;
  flex: 0 0 45px;
}
.custom_search:hover {
  background: url("https://docs.realgrid.com/public/images/btnImages/search_hover.png") center center no-repeat;
}
.custom_search:active {
  background: url("https://docs.realgrid.com/public/images/btnImages/search_click.png") center center no-repeat;
}
</style>
