var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", {
        staticStyle: { width: "100%", height: "300px" },
        attrs: { id: "realgrid" },
      }),
      _c("div", { staticClass: "content resize" }, [
        _c("div", { staticClass: "location" }, [
          _c("ul", [
            _c("li", [
              _c("a", { staticClass: "home", attrs: { href: "#" } }, [
                _vm._v("home"),
              ]),
            ]),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [_vm._v("정산/발행")]),
            ]),
            _c("li", [_vm._v("Freetime 요청")]),
          ]),
        ]),
        _c("h1", { staticClass: "page_title" }, [_vm._v("Freetime 요청")]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_search t2" }, [
            _c("colgroup", [
              _c("col", { attrs: { width: "90px" } }),
              _c("col", { attrs: { width: "*" } }),
              _c("col", { attrs: { width: "100px" } }),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("불러오기")]),
                _c("td", [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c("span", { staticClass: "dv wid100" }, [
                      _c("select", [
                        _c("option", [_vm._v("선택")]),
                        _c("option", [_vm._v("수입")]),
                        _c("option", [_vm._v("수출")]),
                      ]),
                    ]),
                    _c("span", { staticClass: "dv wid150" }, [
                      _c("select", [
                        _c("option", [_vm._v("B/L No.")]),
                        _c("option", [_vm._v("Container No.")]),
                      ]),
                    ]),
                    _c("span", { staticClass: "dv wid70" }, [
                      _c("input", {
                        attrs: {
                          type: "text",
                          value: "KMTC",
                          disabled: "disabled",
                        },
                      }),
                    ]),
                    _c("span", { staticClass: "dv" }, [
                      _c("input", {
                        staticClass: "wid150",
                        staticStyle: { margin: "0" },
                        attrs: { type: "text" },
                      }),
                    ]),
                  ]),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _c(
                    "a",
                    { staticClass: "button blue sh", attrs: { href: "#none" } },
                    [_vm._v("검색")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("div", { staticClass: "text_right mb10" }, [
            _c("a", { staticClass: "sm button", attrs: { href: "#none" } }, [
              _vm._v("은행계좌정보"),
            ]),
            _c(
              "a",
              { staticClass: "sm button ml2", attrs: { href: "#none" } },
              [_vm._v("Free Time Tariff")]
            ),
          ]),
          _c("table", { staticClass: "tbl_row" }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "12%" } }),
              _c("col", { staticStyle: { width: "21%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
              _c("col", { staticStyle: { width: "21%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
              _c("col", { staticStyle: { width: "auto" } }),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v("Demurrage"),
                  _c("br"),
                  _vm._v("입금정보입력"),
                ]),
                _c("td", [_vm._v("TEMU8990456 처리중")]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v("Detention"),
                  _c("br"),
                  _vm._v("입금정보입력"),
                ]),
                _c("td", [_vm._v("TEMU8990456 승인완료")]),
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v("Monitoring"),
                  _c("br"),
                  _vm._v("입금정보입력"),
                ]),
                _c("td"),
              ]),
            ]),
          ]),
          _c("p", { staticClass: "txt_desc emp" }, [
            _vm._v(
              "RF 컨테이너 전기료 프리타임 : 2일 (초과일수에 대해서 터미널과 직정산)"
            ),
          ]),
          _c("div", { staticClass: "flex_box mt20" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v("DEM(Demurrage)"),
            ]),
            _c("span", { staticClass: "cal_btn" }, [
              _c("input", {
                attrs: { type: "radio", id: "dem1", name: "dem" },
              }),
              _c("label", { staticClass: "mr10", attrs: { for: "dem1" } }, [
                _c("span"),
                _vm._v("반출 지체중인 컨테이너"),
              ]),
              _c("input", {
                attrs: { type: "radio", id: "dem2", name: "dem" },
              }),
              _c("label", { attrs: { for: "dem2" } }, [
                _c("span"),
                _vm._v("전체 컨테이너 보기"),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "tbl_col" }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "5%" } }),
              _c("col", { staticStyle: { width: "18%" } }),
              _c("col", { staticStyle: { width: "10%" } }),
              _c("col", { staticStyle: { width: "10%" } }),
              _c("col", { staticStyle: { width: "14%" } }),
              _c("col", { staticStyle: { width: "11%" } }),
              _c("col", { staticStyle: { width: "8%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
            ]),
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "pr0", attrs: { scope: "col" } }, [
                  _c("input", { attrs: { type: "checkbox", id: "chkall" } }),
                  _c("label", { attrs: { for: "chkall" } }, [
                    _c("span", { staticClass: "offscreen" }, [_vm._v("선택")]),
                  ]),
                ]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Cntr. No")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("FreeDay")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("반출시한")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("반출(예정)일")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Over Days.")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("상태")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("금액")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("금액(VAT)")]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "pr0" }, [
                  _c("input", { attrs: { type: "checkbox", id: "chk1" } }),
                  _c("label", { attrs: { for: "chk1" } }, [
                    _c("span", { staticClass: "offscreen" }, [_vm._v("선택")]),
                  ]),
                ]),
                _c("td", [_vm._v("BMOU6219934")]),
                _c("td", [_vm._v("30")]),
                _c("td", [_vm._v("2021-11-04")]),
                _c("td", [
                  _c("div", { staticClass: "input_calendar short" }, [
                    _c("input", {
                      attrs: { type: "text", value: "2021-10-18" },
                    }),
                    _c("button", { staticClass: "tbl_icon calendar" }, [
                      _vm._v("달력"),
                    ]),
                  ]),
                ]),
                _c("td", [_vm._v("0")]),
                _c("td", [_vm._v("반출")]),
                _c("td", { staticClass: "text_right" }, [_vm._v("0")]),
                _c("td", { staticClass: "text_right" }, [_vm._v("0")]),
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "9" } }, [
                  _vm._v("No Demurrage Tariff (OUTBOUND)"),
                ]),
              ]),
            ]),
            _c("tfoot", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("SUB TOTAL")]
                ),
                _c("td", { staticClass: "text_right" }, [_vm._v("0")]),
                _c("td", { staticClass: "text_right" }, [_vm._v("0")]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("D/C AMOUNT")]
                ),
                _c("td", { staticClass: "text_right" }, [_vm._v("0%")]),
                _c("td", { staticClass: "text_right" }, [_vm._v("0")]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("GRAND TOTAL")]
                ),
                _c(
                  "td",
                  { staticClass: "text_right", attrs: { colspan: "2" } },
                  [_vm._v("0")]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex_box mt10" }, [
            _c("a", { staticClass: "button sm", attrs: { href: "#" } }, [
              _vm._v("반출일 KL-NET 재전송"),
            ]),
            _c(
              "a",
              {
                staticClass: "button blue sm ml_auto mr5",
                attrs: { href: "#" },
              },
              [_vm._v("Previous Issued Invoice")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("입금/계산서 정보입력")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("Proforma Invoice")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("Freetime 연장요청")]
            ),
            _c("a", { staticClass: "button blue sm", attrs: { href: "#" } }, [
              _vm._v("Freetime 요청내역"),
            ]),
          ]),
          _c("div", { staticClass: "desc_list" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  " 반출 요청하실 컨테이너를 선택하신 후 반출(예정)일을 입력 후 조회하시면 Demurrage 금액을 확인 하실 수 있으며, Proforma invoice 출력도 가능합니다. "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " 현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다. "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex_box mt20" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v("DET(Detention)"),
            ]),
            _c("span", { staticClass: "cal_btn" }, [
              _c("input", {
                attrs: { type: "radio", id: "det1", name: "det" },
              }),
              _c("label", { staticClass: "mr10", attrs: { for: "det1" } }, [
                _c("span"),
                _vm._v("반납 지체중인 컨테이너"),
              ]),
              _c("input", {
                attrs: { type: "radio", id: "det2", name: "det" },
              }),
              _c("label", { attrs: { for: "det2" } }, [
                _c("span"),
                _vm._v("전체 컨테이너 보기"),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "tbl_col" }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "5%" } }),
              _c("col", { staticStyle: { width: "18%" } }),
              _c("col", { staticStyle: { width: "10%" } }),
              _c("col", { staticStyle: { width: "10%" } }),
              _c("col", { staticStyle: { width: "14%" } }),
              _c("col", { staticStyle: { width: "11%" } }),
              _c("col", { staticStyle: { width: "8%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
            ]),
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "pr0", attrs: { scope: "col" } }, [
                  _c("input", { attrs: { type: "checkbox", id: "chkall" } }),
                  _c("label", { attrs: { for: "chkall" } }, [
                    _c("span", { staticClass: "offscreen" }, [_vm._v("선택")]),
                  ]),
                ]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Cntr. No")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("FreeDay")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("반납시한")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("반납(예정)일")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Over Days.")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("상태")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("금액")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("금액(VAT)")]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "pr0" }, [
                  _c("input", { attrs: { type: "checkbox", id: "chk1" } }),
                  _c("label", { attrs: { for: "chk1" } }, [
                    _c("span", { staticClass: "offscreen" }, [_vm._v("선택")]),
                  ]),
                ]),
                _c("td", [_vm._v("BMOU6219934")]),
                _c("td", [_vm._v("30")]),
                _c("td", [_vm._v("2021-11-04")]),
                _c("td", [
                  _c("div", { staticClass: "input_calendar short" }, [
                    _c("input", {
                      attrs: { type: "text", value: "2021-10-18" },
                    }),
                    _c("button", { staticClass: "tbl_icon calendar" }, [
                      _vm._v("달력"),
                    ]),
                  ]),
                ]),
                _c("td", [_vm._v("0")]),
                _c("td", { staticClass: "color_red" }, [_vm._v("반출")]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "9" } }, [
                  _vm._v("No Demurrage Tariff (OUTBOUND)"),
                ]),
              ]),
            ]),
            _c("tfoot", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("SUB TOTAL")]
                ),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("D/C AMOUNT")]
                ),
                _c("td", { staticClass: "text_right" }, [_vm._v("0%")]),
                _c("td", { staticClass: "text_right" }, [_vm._v("0")]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("GRAND TOTAL")]
                ),
                _c(
                  "td",
                  { staticClass: "text_right", attrs: { colspan: "2" } },
                  [_vm._v("22,4000,000")]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex_box mt10" }, [
            _c("a", { staticClass: "button sm", attrs: { href: "#" } }, [
              _vm._v("반납일 KL-NET 재전송"),
            ]),
            _c(
              "a",
              {
                staticClass: "button blue sm ml_auto mr5",
                attrs: { href: "#" },
              },
              [_vm._v("Previous Issued Invoice")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("입금/계산서 정보입력")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("Proforma Invoice")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("Freetime 연장요청")]
            ),
            _c("a", { staticClass: "button blue sm", attrs: { href: "#" } }, [
              _vm._v("Freetime 요청내역"),
            ]),
          ]),
          _c("div", { staticClass: "desc_list" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  " 반납 요청하실 컨테이너를 선택하신 후 반납(예정)일을 입력 후 조회하시면 Detention 금액을 확인 하실 수 있으며, Proforma invoice 출력도 가능합니다. "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " 현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반납일이 상이 할 경우 [반납일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다. "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex_box mt20" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v("MOR(Monitoring)"),
            ]),
            _c("span", { staticClass: "cal_btn" }, [
              _c("input", {
                attrs: { type: "radio", id: "mor1", name: "mor" },
              }),
              _c("label", { staticClass: "mr10", attrs: { for: "mor1" } }, [
                _c("span"),
                _vm._v("반출 지체중인 컨테이너"),
              ]),
              _c("input", {
                attrs: { type: "radio", id: "mor2", name: "mor" },
              }),
              _c("label", { attrs: { for: "mor2" } }, [
                _c("span"),
                _vm._v("전체 컨테이너 보기"),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "tbl_col" }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "5%" } }),
              _c("col", { staticStyle: { width: "18%" } }),
              _c("col", { staticStyle: { width: "10%" } }),
              _c("col", { staticStyle: { width: "10%" } }),
              _c("col", { staticStyle: { width: "14%" } }),
              _c("col", { staticStyle: { width: "11%" } }),
              _c("col", { staticStyle: { width: "8%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
            ]),
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "pr0", attrs: { scope: "col" } }, [
                  _c("input", { attrs: { type: "checkbox", id: "chkall" } }),
                  _c("label", { attrs: { for: "chkall" } }, [
                    _c("span", { staticClass: "offscreen" }, [_vm._v("선택")]),
                  ]),
                ]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Cntr. No")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("FreeDay")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("반납시한")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("반납(예정)일")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Over Days.")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("상태")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("금액")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("금액(VAT)")]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "pr0" }, [
                  _c("input", { attrs: { type: "checkbox", id: "chk1" } }),
                  _c("label", { attrs: { for: "chk1" } }, [
                    _c("span", { staticClass: "offscreen" }, [_vm._v("선택")]),
                  ]),
                ]),
                _c("td", [_vm._v("BMOU6219934")]),
                _c("td", [_vm._v("30")]),
                _c("td", [_vm._v("2021-11-04")]),
                _c("td", [
                  _c("div", { staticClass: "input_calendar short" }, [
                    _c("input", {
                      attrs: { type: "text", value: "2021-10-18" },
                    }),
                    _c("button", { staticClass: "tbl_icon calendar" }, [
                      _vm._v("달력"),
                    ]),
                  ]),
                ]),
                _c("td", [_vm._v("0")]),
                _c("td", { staticClass: "color_red" }, [_vm._v("미반출")]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "9" } }, [
                  _vm._v("No Demurrage Tariff (OUTBOUND)"),
                ]),
              ]),
            ]),
            _c("tfoot", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("SUB TOTAL")]
                ),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("D/C AMOUNT")]
                ),
                _c("td", { staticClass: "text_right" }, [_vm._v("0%")]),
                _c("td", { staticClass: "text_right" }, [_vm._v("0")]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("GRAND TOTAL")]
                ),
                _c(
                  "td",
                  { staticClass: "text_right", attrs: { colspan: "2" } },
                  [_vm._v("22,4000,000")]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex_box mt10" }, [
            _c("a", { staticClass: "button sm", attrs: { href: "#" } }, [
              _vm._v("반출일 KL-NET 재전송"),
            ]),
            _c(
              "a",
              {
                staticClass: "button blue sm ml_auto mr5",
                attrs: { href: "#" },
              },
              [_vm._v("Previous Issued Invoice")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("입금/계산서 정보입력")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("Proforma Invoice")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("Freetime 연장요청")]
            ),
            _c("a", { staticClass: "button blue sm", attrs: { href: "#" } }, [
              _vm._v("Freetime 요청내역"),
            ]),
          ]),
          _c("div", { staticClass: "desc_list" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  " Monitoring 요청하실 컨테이너를 선택하신 후 반출(예정)일을 입력 후 조회하시면 Monitoring 금액을 확인 하실 수 있으며, Proforma invoice 출력도 가능합니다. "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " 현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다. "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex_box mt20" }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v("STR(Storage)"),
            ]),
            _c("span", { staticClass: "cal_btn" }, [
              _c("input", {
                attrs: { type: "radio", id: "str1", name: "str" },
              }),
              _c("label", { staticClass: "mr10", attrs: { for: "str1" } }, [
                _c("span"),
                _vm._v("반출 지체중인 컨테이너"),
              ]),
              _c("input", {
                attrs: { type: "radio", id: "str2", name: "str" },
              }),
              _c("label", { attrs: { for: "str2" } }, [
                _c("span"),
                _vm._v("전체 컨테이너 보기"),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "tbl_col" }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "5%" } }),
              _c("col", { staticStyle: { width: "18%" } }),
              _c("col", { staticStyle: { width: "10%" } }),
              _c("col", { staticStyle: { width: "10%" } }),
              _c("col", { staticStyle: { width: "14%" } }),
              _c("col", { staticStyle: { width: "11%" } }),
              _c("col", { staticStyle: { width: "8%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
              _c("col", { staticStyle: { width: "12%" } }),
            ]),
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "pr0", attrs: { scope: "col" } }, [
                  _c("input", { attrs: { type: "checkbox", id: "chkall" } }),
                  _c("label", { attrs: { for: "chkall" } }, [
                    _c("span", { staticClass: "offscreen" }, [_vm._v("선택")]),
                  ]),
                ]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Cntr. No")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("FreeDay")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("반납시한")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("반납(예정)일")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Over Days.")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("상태")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("금액")]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("금액(VAT)")]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "pr0" }, [
                  _c("input", { attrs: { type: "checkbox", id: "chk1" } }),
                  _c("label", { attrs: { for: "chk1" } }, [
                    _c("span", { staticClass: "offscreen" }, [_vm._v("선택")]),
                  ]),
                ]),
                _c("td", [_vm._v("BMOU6219934")]),
                _c("td", [_vm._v("30")]),
                _c("td", [_vm._v("2021-11-04")]),
                _c("td", [
                  _c("div", { staticClass: "input_calendar short" }, [
                    _c("input", {
                      attrs: { type: "text", value: "2021-10-18" },
                    }),
                    _c("button", { staticClass: "tbl_icon calendar" }, [
                      _vm._v("달력"),
                    ]),
                  ]),
                ]),
                _c("td", [_vm._v("0")]),
                _c("td", { staticClass: "color_red" }, [_vm._v("미반출")]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "9" } }, [
                  _vm._v("No Demurrage Tariff (OUTBOUND)"),
                ]),
              ]),
            ]),
            _c("tfoot", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("SUB TOTAL")]
                ),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v("22,4000,000"),
                ]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("D/C AMOUNT")]
                ),
                _c("td", { staticClass: "text_right" }, [_vm._v("0%")]),
                _c("td", { staticClass: "text_right" }, [_vm._v("0")]),
              ]),
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text_right", attrs: { colspan: "7" } },
                  [_vm._v("GRAND TOTAL")]
                ),
                _c(
                  "td",
                  { staticClass: "text_right", attrs: { colspan: "2" } },
                  [_vm._v("22,4000,000")]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex_box mt10" }, [
            _c("a", { staticClass: "button sm", attrs: { href: "#" } }, [
              _vm._v("반출일 KL-NET 재전송"),
            ]),
            _c(
              "a",
              {
                staticClass: "button blue sm ml_auto mr5",
                attrs: { href: "#" },
              },
              [_vm._v("Previous Issued Invoice")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("입금/계산서 정보입력")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("Proforma Invoice")]
            ),
            _c(
              "a",
              { staticClass: "button blue sm mr5", attrs: { href: "#" } },
              [_vm._v("Freetime 연장요청")]
            ),
            _c("a", { staticClass: "button blue sm", attrs: { href: "#" } }, [
              _vm._v("Freetime 요청내역"),
            ]),
          ]),
          _c("div", { staticClass: "desc_list" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  " Monitoring 요청하실 컨테이너를 선택하신 후 반출(예정)일을 입력 후 조회하시면 Monitoring 금액을 확인 하실 수 있으며, Proforma invoice 출력도 가능합니다. "
                ),
              ]),
              _c("li", [
                _vm._v(
                  " 현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다. "
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "btn_area" }, [
          _c("div", { staticClass: "btn_right" }, [
            _c(
              "a",
              { staticClass: "button blue lg", attrs: { href: "#none" } },
              [_vm._v("A/N")]
            ),
            _c(
              "a",
              { staticClass: "button blue lg", attrs: { href: "#none" } },
              [_vm._v("B/L COPY")]
            ),
            _c(
              "a",
              { staticClass: "button blue lg", attrs: { href: "#none" } },
              [_vm._v("Free Time 요청")]
            ),
            _c(
              "a",
              { staticClass: "button blue lg", attrs: { href: "#none" } },
              [_vm._v("Free Time Tariff")]
            ),
            _c(
              "a",
              { staticClass: "button blue lg", attrs: { href: "#none" } },
              [_vm._v("Free Time Print")]
            ),
            _c(
              "a",
              { staticClass: "button blue lg", attrs: { href: "#none" } },
              [_vm._v("해당 모선 Schedule조회")]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }