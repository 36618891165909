<template>
  <div
    class="popup_wrap"
    style="width:900px; height:600px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
    테스트
    </button> <!-- close -->
    <div class="popup_cont">
      <h1 class="page_title">C/A 내역 조회</h1>
      <div class="content_box"><!-- content_box -->
      <table class="tbl_search">
        <colgroup>
          <col style="width:100px">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th>B/L No.</th>
            <td>
              <span>{{ blNo }}</span>
              <select class="wid180 ml20" v-model="selectedCaNo" @change="getCaHistory">
                <option value="">select</option>
                <option v-for="(ca, index) in mainCAHistoryList" :key="index" :value="ca.caNo">{{ca.caNo}}/{{ ca.reqDt }}({{ ca.caCatNm }})</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      </div><!-- content_box // -->

      <div class="content_box"><!-- content_box -->
        <!--<p class="txt_desc asterisk">B/L No. PUSC340296 수정 내역이 없습니다. </p> -->

        <h2 class="content_title">Shipper / Consignee / Notify</h2>
        <table class="tbl_col mt10">
          <colgroup>
            <col width="30%">
            <col width="35%">
            <col width="35%">
            <col>
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>Original</th>
              <th>Revised</th>
            </tr>
          </thead>
          <tbody
            v-for="(header, index) in blHeader"
            :key="index"
          >
            <!------------shipper------------>
            <tr v-if="(header.shprNmOrg || header.shprNmRvs) && (header.shprAdrOrg || header.shprAdrRvs)">
              <th>Shipper | Name</th>
              <td>{{header.shprNmOrg}}</td>
              <td>{{header.shprNmRvs}}</td>
            </tr>
            <tr v-else-if="header.shprNmOrg || header.shprNmRvs">
              <th>Shipper | Name</th>
              <td>{{header.shprNmOrg}}</td>
              <td>{{header.shprNmRvs}}</td>
            </tr>
            <tr v-if="(header.shprNmOrg || header.shprNmRvs) && (header.shprAdrOrg || header.shprAdrRvs)">
              <th>Shipper | Address</th>
              <td>{{header.shprAdrOrg}}</td>
              <td>{{header.shprAdrRvs}}</td>
            </tr>
            <tr v-else-if="header.shprAdrOrg || header.shprAdrRvs">
              <th>Shipper | Address</th>
              <td>{{header.shprAdrOrg}}</td>
              <td>{{header.shprAdrRvs}}</td>
            </tr>
            <!------------consignee------------>
            <tr v-if="(header.cneNmOrg || header.cneNmRvs) && (header.cneAdrOrg || header.cneAdrRvs)">
              <th>Consignee | Name</th>
              <td>{{header.cneNmOrg}}</td>
              <td>{{header.cneNmRvs}}</td>
            </tr>
            <tr v-else-if="header.cneNmOrg || header.cneNmRvs">
              <th>Consignee | Name</th>
              <td>{{header.cneNmOrg}}</td>
              <td>{{header.cneNmRvs}}</td>
            </tr>
            <tr v-if="(header.cneNmOrg || header.cneNmRvs) && (header.cneAdrOrg || header.cneAdrRvs)">
              <th>Consignee | Address</th>
              <td>{{header.cneAdrOrg}}</td>
              <td>{{header.cneAdrRvs}}</td>
            </tr>
            <tr v-else-if="header.cneAdrOrg || header.cneAdrRvs">
              <th>Consignee | Address</th>
              <td>{{header.cneAdrOrg}}</td>
              <td>{{header.cneAdrRvs}}</td>
            </tr>
            <!------------notify------------>
            <tr v-if="(header.ntfNmOrg || header.ntfNmRvs) && (header.ntfAdrOrg || header.ntfAdrRvs)">
              <th>Notify | Name</th>
              <td>{{header.ntfNmOrg}}</td>
              <td>{{header.ntfNmRvs}}</td>
            </tr>
            <tr v-else-if="header.ntfNmOrg || header.ntfNmRvs">
              <th>Notify | Name</th>
              <td>{{header.ntfNmOrg}}</td>
              <td>{{header.ntfNmRvs}}</td>
            </tr>
            <tr v-if="(header.ntfNmOrg || header.ntfNmRvs) && (header.ntfAdrOrg || header.ntfAdrRvs)">
              <th>Notify | Address</th>
              <td>{{header.ntfAdrOrg}}</td>
              <td>{{header.ntfAdrRvs}}</td>
            </tr>
            <tr v-else-if="header.ntfAdrOrg || header.ntfAdrRvs">
              <th>Notify | Address</th>
              <td>{{header.ntfAdrOrg}}</td>
              <td>{{header.ntfAdrRvs}}</td>
            </tr>
            <!------------Measure------------------>
            <tr v-if="header.measureOrg || header.measureRvs">
              <th>Measure</th>
              <td>{{header.measureOrg}}</td>
              <td>{{header.measureRvs}}</td>
            </tr>
            <!------------Gross Weight------------------>
            <tr v-if="header.grsWtOrg || header.grsWtRvs">
              <th>Gross Weight</th>
              <td>{{header.grsWtOrg}}</td>
              <td>{{header.grsWtRvs}}</td>
            </tr>
            <!-- 일본 B/L C/A 항목 -->
            <tr v-if="header.blTypeOrg || header.blTypeRvs">
              <th>B/L Type</th>
              <td>{{header.blTypeOrg == '01' ? 'O.B/L' : 'SeaWay Bill'}}</td>
              <td>{{header.blTypeRvs == '01' ? 'O.B/L' : 'SeaWay Bill'}}</td>
            </tr>
            <!-- Package -->
            <tr v-if="(header.pkgCdOrg || header.pkgCdRvs) && (header.pkgQtyOrg || header.pkgQtyRvs)">
              <th>Package | Qty</th>
              <td>{{header.pkgQtyOrg}}</td>
              <td>{{header.pkgQtyRvs}}</td>
            </tr>
            <tr v-else-if="header.pkgQtyOrg || header.pkgQtyRvs">
              <th>Package | Qty</th>
              <td>{{header.pkgQtyOrg}}</td>
              <td>{{header.pkgQtyRvs}}</td>
            </tr>
            <tr v-if="(header.pkgCdOrg || header.pkgCdRvs) && (header.pkgQtyOrg || header.pkgQtyRvs)">
              <th>Package | 단위</th>
              <td>{{header.pkgCdOrg}}</td>
              <td>{{header.pkgCdRvs}}</td>
            </tr>
            <tr v-else-if="header.pkgCdOrg || header.pkgCdRvs">
              <th>Package | 단위</th>
              <td>{{header.pkgCdOrg}}</td>
              <td>{{header.pkgCdRvs}}</td>
            </tr>
            <!-- Commodity -->
            <tr v-if="header.cmdtDscOrg || header.cmdtDscRvs">
              <th>Commodity</th>
              <td>{{header.cmdtDscOrg}}</td>
              <td>{{header.cmdtDscRvs}}</td>
            </tr>
            <!------------START OBL_PRT_SCNT------------>
            <tr v-if="header.oblPrtScntOrg || header.oblPrtScntRvs">
              <th>No. of B/</th>
              <td>{{header.oblPrtScntOrg}}</td>
              <td>{{header.oblPrtScntRvs}}</td>
            </tr>
            <!------------START ISS_CTR_CD------------>
            <tr v-if="header.issCtrCdOrg || header.issCtrCdRvs">
              <th>Issue Country</th>
              <td>{{header.issCtrCdOrg}}</td>
              <td>{{header.issCtrCdRvs}}</td>
            </tr>
            <!------------START ISS_PLC_CD------------>
            <tr v-if="header.issPlcCdOrg || header.issPlcCdRvs">
              <th>Issue Place</th>
              <td>{{header.issPlcCdOrg}}</td>
              <td>{{header.issPlcCdRvs}}</td>
            </tr>
            <!------------START FRT_PAY_PLC_CD------------>
            <tr v-if="header.frtPayPlcCdOrg || header.frtPayPlcCdRvs">
              <th>Freight Payable Place</th>
              <td>{{header.frtPayPlcCdOrg}}</td>
              <td>{{header.frtPayPlcCdRvs}}</td>
            </tr>
            <!------------START Place of Receipt------------>
            <tr v-if="header.porPortNmOrg || header.porPortNmRvs">
              <th>Place of Receipt</th>
              <td>{{header.porPortNmOrg}}</td>
              <td>{{header.porPortNmRvs}}</td>
            </tr>
            <!------------START ISS_CTR_CD(v-if조건 기존 잘못되있는 것 같음)------------>
            <tr v-if="header.porPortNmOrg || header.porPortNmRvs">
              <th>Port of Loading</th>
              <td>{{header.polPortNmOrg}}</td>
              <td>{{header.polPortNmRvs}}</td>
            </tr>
            <!------------START ISS_CTR_CD------------>
            <tr v-if="header.porPortNmOrg || header.porPortNmRvs">
              <th>Port of Discharging</th>
              <td>{{header.podPortNmOrg}}</td>
              <td>{{header.podPortNmRvs}}</td>
            </tr>
            <!------------START ISS_CTR_CD------------>
            <tr v-if="header.porPortNmOrg || header.porPortNmRvs">
              <th>Place of Delivery</th>
              <td>{{header.dlyPortNmOrg}}</td>
              <td>{{header.dlyPortNmRvs}}</td>
            </tr>
            <!------------START ISS_CTR_CD------------>
            <tr v-if="header.oblPrtScntOrg || header.oblPrtScntRvs">
              <th>HS Code</th>
              <td>{{header.oblPrtScntOrg}}</td>
              <td>{{header.oblPrtScntRvs}}</td>
            </tr>
            <!------------START ISS_CTR_CD------------>
            <tr v-if="header.porPortNmOrg || header.porPortNmRvs">
              <th>Final Destination</th>
              <td>{{header.oblPrtScntOrg}}</td>
              <td>{{header.oblPrtScntRvs}}</td>
            </tr>
            <!------------START ISS_CTR_CD------------>
            <tr v-if="header.obrdDtOrg || header.obrdDtRvs">
              <th>Issue Date</th>
              <td>{{header.issDtOrg}}</td>
              <td>{{header.issDtRvs}}</td>
            </tr>
            <!------------START OBRD_DT_ORG------------>
            <tr v-if="header.obrdDtOrg || header.obrdDtRvs">
              <th>On Board Date</th>
              <td>{{header.obrdDtOrg}}</td>
              <td>{{header.obrdDtRvs}}</td>
            </tr>
            <!------------START ID_TAX_NO_ORG------------>
            <tr v-if="header.idTaxNoOrg || header.idTaxNoRvs">
              <th>Importer's<br> Tax No</th>
              <td>{{header.idTaxNoOrg}}</td>
              <td>{{header.idTaxNoRvs}}</td>
            </tr>
            <!------------START ET_TAX_NO_ORG------------>
            <tr v-if="header.etTaxNoOrg || header.etTaxNoRvs">
              <th>Exporter's<br> Tax No</th>
              <td>{{header.etTaxNoOrg}}</td>
              <td>{{header.etTaxNoRvs}}</td>
            </tr>
             <!------------START WST_CGO_YN_ORG------------>
            <tr v-if="header.wstCgoYnOrg || header.wstCgoYnRvs">
              <th>WASTE/SCRAP Cargo</th>
              <td>{{header.wstCgoYnOrg}}</td>
              <td>{{header.wstCgoYnRvs}}</td>
            </tr>
            <tr v-if="header.imLicNoOrg || header.imLicNoRvs">
              <th>License No</th>
              <td>{{header.imLicNoOrg}}</td>
              <td>{{header.imLicNoRvs}}</td>
            </tr>
            <tr v-if="header.imBnkGurNoOrg || header.imBnkGurNoRvs">
              <th>Bank Guarantee No</th>
              <td>{{header.imBnkGurNoOrg}}</td>
              <td>{{header.imBnkGurNoRvs}}</td>
            </tr>
            <tr v-if="header.iecNoOrg || header.iecNoRvs">
              <th>IEC No</th>
              <td>{{header.iecNoOrg}}</td>
              <td>{{header.iecNoRvs}}</td>
            </tr>
            <tr v-if="header.gstNoOrg || header.gstNoRvs">
              <th>GST No</th>
              <td>{{header.gstNoOrg}}</td>
              <td>{{header.gstNoRvs}}</td>
            </tr>
            <tr v-if="header.iecPicEmlAddrOrg || header.iecPicEmlAddrRvs">
              <th>IEC E-Mail</th>
              <td>{{header.iecPicEmlAddrOrg}}</td>
              <td>{{header.iecPicEmlAddrRvs}}</td>
            </tr>
            <tr v-if="header.actShprCstCtrCdOrg || header.actShprCstCtrCdRvs">
              <th>Shipper (Country)</th>
              <td>{{header.actShprCstCtrCdOrg}}</td>
              <td>{{header.actShprCstCtrCdRvs}}</td>
            </tr>
            <tr v-if="header.actShprCstCityNmOrg || header.actShprCstCityNmRvs">
              <th>Shipper (City)</th>
              <td>{{header.actShprCstCityNmOrg}}</td>
              <td>{{header.actShprCstCityNmRvs}}</td>
            </tr>
            <tr v-if="header.actShprCstPicNmOrg || header.actShprCstPicNmRvs">
              <th>Shipper (PIC)</th>
              <td>{{header.actShprCstPicNmOrg}}</td>
              <td>{{header.actShprCstPicNmRvs}}</td>
            </tr>
            <tr v-if="header.actShprCstTelNoOrg || header.actShprCstTelNoRvs">
              <th>Shipper (PIC Tel)</th>
              <td>{{header.actShprCstTelNoOrg}}</td>
              <td>{{header.actShprCstTelNoRvs}}</td>
            </tr>
            <tr v-if="header.actShprCstFaxNoOrg || header.actShprCstFaxNoRvs">
              <th>Shipper (PIC Fax)</th>
              <td>{{header.actShprCstFaxNoOrg}}</td>
              <td>{{header.actShprCstFaxNoRvs}}</td>
            </tr>
            <tr v-if="header.actShprCstEmlAddrOrg || header.actShprCstEmlAddrRvs">
              <th>Shipper (PIC e-mail)</th>
              <td>{{header.actShprCstEmlAddrOrg}}</td>
              <td>{{header.actShprCstEmlAddrRvs}}</td>
            </tr>
            <tr v-if="header.actShprIdCatCdOrg || header.actShprIdCatCdRvs">
              <th>Shipper (Company ID)</th>
              <td>{{header.actShprIdCatCdOrg}}</td>
              <td>{{header.actShprIdCatCdRvs}}</td>
            </tr>
            <tr v-if="header.actShprIdNoOrg || header.actShprIdNoRvs">
              <th>Shipper (Company ID No)</th>
              <td>{{header.actShprIdNoOrg}}</td>
              <td>{{header.actShprIdNoRvs}}</td>
            </tr>
            <tr v-if="header.cneCstCtrCdOrg || header.cneCstCtrCdRvs">
              <th>Consignee (Country)</th>
              <td>{{header.cneCstCtrCdOrg}}</td>
              <td>{{header.cneCstCtrCdRvs}}</td>
            </tr>
            <tr v-if="header.cneCstCityNmOrg || header.cneCstCityNmRvs">
              <th>Consignee (City)</th>
              <td>{{header.cneCstCityNmOrg}}</td>
              <td>{{header.cneCstCityNmRvs}}</td>
            </tr>
            <tr v-if="header.cneCstPicNmOrg || header.cneCstPicNmRvs">
              <th>Consignee (PIC)</th>
              <td>{{header.cneCstPicNmOrg}}</td>
              <td>{{header.cneCstPicNmRvs}}</td>
            </tr>
            <tr v-if="header.cneCstTelNoOrg || header.cneCstTelNoRvs">
              <th>Consignee (PIC Tel)</th>
              <td>{{header.cneCstTelNoOrg}}</td>
              <td>{{header.cneCstTelNoRvs}}</td>
            </tr>
            <tr v-if="header.cneCstFaxNoOrg || header.cneCstFaxNoRvs">
              <th>Consignee (PIC Fax)</th>
              <td>{{header.cneCstFaxNoOrg}}</td>
              <td>{{header.cneCstFaxNoRvs}}</td>
            </tr>
            <tr v-if="header.cneCstEmlAddrOrg || header.cneCstEmlAddrRvs">
              <th>Consignee (PIC e-mail)</th>
              <td>{{header.cneCstEmlAddrOrg}}</td>
              <td>{{header.cneCstEmlAddrRvs}}</td>
            </tr>
            <tr v-if="header.cneIdCatCdOrg || header.cneIdCatCdRvs">
              <th>Consignee (Company ID)</th>
              <td>{{header.cneIdCatCdOrg}}</td>
              <td>{{header.cneIdCatCdRvs}}</td>
            </tr>
            <tr v-if="header.cneIdNoOrg || header.cneIdNoRvs">
              <th>Consignee (Company ID No)</th>
              <td>{{header.cneIdNoOrg}}</td>
              <td>{{header.cneIdNoRvs}}</td>
            </tr>
            <tr v-if="header.ntifCstCityNmOrg || header.ntifCstCityNmRvs">
              <th>Notify (City)</th>
              <td>{{header.ntifCstCityNmOrg}}</td>
              <td>{{header.ntifCstCityNmRvs}}</td>
            </tr>
            <tr v-if="header.cneCstCityNmOrg || header.cneCstCityNmRvs">
              <th>Consignee (City)</th>
              <td>{{header.cneCstCityNmOrg}}</td>
              <td>{{header.cneCstCityNmRvs}}</td>
            </tr>
            <tr v-if="header.ntifCstPicNmOrg || header.ntifCstPicNmRvs">
              <th>Notify (PIC)</th>
              <td>{{header.ntifCstPicNmOrg}}</td>
              <td>{{header.ntifCstPicNmRvs}}</td>
            </tr>
            <tr v-if="header.ntifCstTelNoOrg || header.ntifCstTelNoRvs">
              <th>Notify (PIC Tel)</th>
              <td>{{header.ntifCstTelNoOrg}}</td>
              <td>{{header.ntifCstTelNoRvs}}</td>
            </tr>
            <tr v-if="header.ntifCstFaxNoOrg || header.ntifCstFaxNoRvs">
              <th>Notify (PIC Fax)</th>
              <td>{{header.ntifCstFaxNoOrg}}</td>
              <td>{{header.ntifCstFaxNoRvs}}</td>
            </tr>
            <tr v-if="header.ntifCstEmlAddrOrg || header.ntifCstEmlAddrRvs">
              <th>Notify (PIC e-mail)</th>
              <td>{{header.ntifCstEmlAddrOrg}}</td>
              <td>{{header.ntifCstEmlAddrRvs}}</td>
            </tr>
            <tr v-if="header.ntifIdCatCdOrg || header.ntifIdCatCdRvs">
              <th>Notify (Company ID)</th>
              <td>{{header.ntifIdCatCdOrg}}</td>
              <td>{{header.ntifIdCatCdRvs}}</td>
            </tr>
            <tr v-if="header.ntifIdNoOrg || header.ntifIdNoRvs">
              <th>Notify (Company ID No)</th>
              <td>{{header.ntifIdNoOrg}}</td>
              <td>{{header.ntifIdNoRvs}}</td>
            </tr>
            <tr v-if="header.invValCurCdOrg || header.invValCurCdRvs">
              <th>Invoice Value Cur</th>
              <td>{{header.invValCurCdOrg}}</td>
              <td>{{header.invValCurCdRvs}}</td>
            </tr>
            <tr v-if="header.invValExpOrg || header.invValExpRvs">
              <th>Invoice Value Exp</th>
              <td>{{header.invValExpOrg}}</td>
              <td>{{header.invValExpRvs}}</td>
            </tr>
            <tr v-if="header.panNoOrg || header.panNoRvs">
              <th>PAN No</th>
              <td>{{header.panNoOrg}}</td>
              <td>{{header.panNoRvs}}</td>
            </tr>
            <tr v-if="header.blPrtSntcContOrg || header.blPrtSntcContRvs">
              <th>Say</th>
              <td>{{header.blPrtSntcContOrg}}</td>
              <td>{{header.blPrtSntcContRvs}}</td>
            </tr>
          </tbody>
          <tbody v-if="!blHeader">
            <tr>
              <td colspan="3">수정 내역이 없습니다.</td>
            </tr>
          </tbody>
        </table>

        <h2 class="content_title">Marks & Description</h2>
        <table class="tbl_col mt10">
          <colgroup>
            <col width="30%">
            <col width="35%">
            <col width="35%">
            <col>
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>Original</th>
              <th>Revised</th>
            </tr>
          </thead>
          <tbody
              v-for="(body,index) in blBody"
              :key="index"
          >
            <tr v-if="body.mrkOrg || body.mrkRvs">
              <td>{{body.ordSeqOrg == '1' ? 'Mark' : 'Mark Rider'}}</td>
              <td v-html="body.mrkOrg"></td>
              <td v-html="body.mrkRvs"></td>
            </tr>
            <tr v-if="body.dscrOrg || body.dscrRvs">
              <td>{{body.ordSeqOrg == '1' ? 'Description' : 'Description Rider'}}</td>
              <td v-html="body.dscrOrg"></td>
              <td v-html="body.dscrRvs"></td>
            </tr>
          </tbody>
          <tbody v-if="!blBody">
            <tr>
              <td colspan="3">수정 내역이 없습니다.</td>
            </tr>
          </tbody>
        </table>

        <h2 class="content_title">Container</h2>
        <table class="tbl_col mt10">
          <colgroup>
            <col width="8%">
            <col width="9%">
            <col width="7%">
            <col width="7%">
            <col width="7%">
            <col width="9%">
            <col width="9%">
            <col width="9%">
            <col width="9%">
            <col width="9%">
            <col width="9%">
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>Container<br/> No.</th>
              <th>Size</th>
              <th>Type</th>
              <th>특수<br/> 화물</th>
              <th>화주<br/> 컨테이너</th>
              <th>Seal No1.</th>
              <th>Seal No2.</th>
              <th>Seal No3.</th>
              <th>Seal No4.</th>
              <th>Seal No5.</th>
            </tr>
          </thead>
          <tbody v-if="blContainer">
            <tr
              v-for="(container,index) in blContainer"
              :key="index"
            >
              <td>{{ index%2 === 0 ? 'Original' : 'Revised' }}</td>
              <td>{{ container.cntrNo }}</td>
              <td>{{ container.cntrSzCd }}</td>
              <td>{{ container.cntrTypCd }}</td>
              <td>{{ container.cgoTypCd }}</td>
              <td>{{ container.shprVanYn }}</td>
              <td>{{ container.sealNo1 }}</td>
              <td>{{ container.sealNo2 }}</td>
              <td>{{ container.sealNo3 }}</td>
              <td>{{ container.sealNo4 }}</td>
              <td>{{ container.sealNo5 }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="11">수정 내역이 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>
<script>

import ca from '@/api/rest/trans/ca'

export default {
  name: 'CaHistoryPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          caNo: ''
        }
      }
    }
  },
  data () {
    return {
      blNo: '',
      selectedCaNo: '',
      mainCAHistoryList: '',
      blHeader: '',
      blBody: '',
      blContainer: ''
    }
  },
  //CA 내역조회 초기화 셋팅
  created: function () {
    this.blNo = this.parentInfo.blNo
    this.getCaHistory()
  },
  methods: {
    getCaHistory () {
      this.blHeader = ''
      this.blBody = ''
      this.blContainer = ''

      ca.getCaHistory({ blNo: this.blNo, caNo: this.selectedCaNo }).then((data) => {
        //CA History 전체 JSON 데이터
        const caData = data.data

        if (caData.mainCAHistoryList[0]) {
          this.mainCAHistoryList = caData.mainCAHistoryList
        }
        if (caData.findblHeader[0]) {
          this.blHeader = caData.findblHeader
        }
        if (caData.findblBody[0]) {
          this.blBody = caData.findblBody
        }
        if (caData.findblContainer[0]) {
          this.blContainer = caData.findblContainer
        }

        console.log(caData)
      })
    }
  }
}
</script>
