<template>
  <div>
    <div id="realgrid" style="width: 100%; height: 300px"></div>

    <div class="content resize">
      <!-- content : 화면 리사이징 되는 화면 resize 추가 -->
      <div class="location">
        <!-- location -->
        <ul>
          <li><a href="#" class="home">home</a></li>
          <li><a href="#">정산/발행</a></li>
          <li>Freetime 요청</li>
        </ul>
      </div><!-- location -->

      <h1 class="page_title">Freetime 요청</h1>

      <div class="content_box">
        <!-- content_box -->
        <table class="tbl_search t2">
          <colgroup>
            <col width="90px"><col width="*"><col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>불러오기</th>
              <td>
                <div class="tbl_form">
                  <span class="dv wid100">
                    <select>
                      <option>선택</option>
                      <option>수입</option>
                      <option>수출</option>
                    </select>
                  </span>
                  <span class="dv wid150">
                    <select>
                      <option>B/L No.</option>
                      <option>Container No.</option>
                    </select>
                  </span>
                  <span class="dv wid70">
                    <input type="text" value="KMTC" disabled="disabled">
                  </span>
                  <span class="dv">
                    <input type="text" class="wid150" style="margin:0;">
                  </span>
                </div>
              </td>

              <td class="text_right">
                <a href="#none" class="button blue sh">검색</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->

      <div class="content_box mt10">
        <!-- content_box -->
        <div class="text_right mb10">
          <a href="#none" class="sm button">은행계좌정보</a><a href="#none" class="sm button ml2">Free Time Tariff</a>
        </div>

        <table class="tbl_row">
          <colgroup>
            <col style="width:12%">
            <col style="width:21%">
            <col style="width:12%">
            <col style="width:21%">
            <col style="width:12%">
            <col style="width:auto">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">Demurrage<br>입금정보입력</th>
              <td>TEMU8990456 처리중</td>
              <th scope="row">Detention<br>입금정보입력</th>
              <td>TEMU8990456 승인완료</td>
              <th scope="row">Monitoring<br>입금정보입력</th>
              <td></td>
            </tr>
          </tbody>
        </table>
        <p class="txt_desc emp">RF 컨테이너 전기료 프리타임 : 2일 (초과일수에 대해서 터미널과 직정산)</p>

        <div class="flex_box mt20">
          <h2 class="content_title">DEM(Demurrage)</h2>
          <span class="cal_btn">
            <input type="radio" id="dem1" name="dem">
            <label for="dem1" class="mr10"><span></span>반출 지체중인 컨테이너</label>

            <input type="radio" id="dem2" name="dem">
            <label for="dem2"><span></span>전체 컨테이너 보기</label>

          </span>
        </div>

        <table class="tbl_col">
          <colgroup>
            <col style="width:5%">
            <col style="width:18%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:14%">
            <col style="width:11%">
            <col style="width:8%">
            <col style="width:12%">
            <col style="width:12%">
          </colgroup>
          <!-- Header START -->
          <thead>
            <tr>
              <th scope="col" class="pr0">
                <input type="checkbox" id="chkall">
                <label for="chkall"><span class="offscreen">선택</span></label>
              </th>
              <th scope="col">Cntr. No</th>
              <th scope="col">FreeDay</th>
              <th scope="col">반출시한</th>
              <th scope="col">반출(예정)일</th>
              <th scope="col">Over Days.</th>
              <th scope="col">상태</th>
              <th scope="col">금액</th>
              <th scope="col">금액(VAT)</th>
            </tr>
          </thead>
          <!-- Header END -->

          <!-- Body START -->

          <tbody>
            <tr>
              <td class="pr0">
                <input type="checkbox" id="chk1">
                <label for="chk1"><span class="offscreen">선택</span></label>
              </td>
              <td>BMOU6219934</td>
              <td>30</td>
              <td>2021-11-04</td>
              <td>
                <div class="input_calendar short">
                  <input type="text" value="2021-10-18">
                  <button class="tbl_icon calendar">달력</button>
                </div>
              </td>
              <td>0</td>
              <td>반출</td>
              <td class="text_right">0</td>
              <td class="text_right">0</td>
            </tr>
            <tr>
              <td colspan="9">No Demurrage Tariff (OUTBOUND)</td>
            </tr>
          </tbody>

          <!-- Body END -->

          <!-- Sub START -->

          <tfoot>
            <tr>
              <th colspan="7" class="text_right">SUB TOTAL</th>
              <td class="text_right">0</td>
              <td class="text_right">0</td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">D/C AMOUNT</th>
              <td class="text_right">0%</td>
              <td class="text_right">0</td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">GRAND TOTAL</th>
              <td colspan="2" class="text_right">0</td>
            </tr>
          </tfoot>

          <!-- Sub END -->
        </table>
        <!-- Buttons START -->
        <div class="flex_box mt10">
          <a class="button sm" href="#">반출일 KL-NET 재전송</a>
          <a class="button blue sm ml_auto mr5" href="#">Previous Issued Invoice</a>
          <a class="button blue sm mr5" href="#">입금/계산서 정보입력</a>
          <a class="button blue sm mr5" href="#">Proforma Invoice</a>
          <a class="button blue sm mr5" href="#">Freetime 연장요청</a>
          <a class="button blue sm" href="#">Freetime 요청내역</a>
        </div>
        <!-- Buttons END -->

        <!-- Comment START -->

        <div class="desc_list">
          <ul>
            <li>
              반출 요청하실 컨테이너를 선택하신 후 반출(예정)일을 입력 후 조회하시면 Demurrage 금액을 확인 하실 수 있으며, Proforma invoice 출력도 가능합니다.
            </li>
            <li>
              현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.
            </li>
          </ul>
        </div>

        <!-- Comment END -->

        <div class="flex_box mt20">
          <h2 class="content_title">DET(Detention)</h2>
          <span class="cal_btn">
            <input type="radio" id="det1" name="det">
            <label for="det1" class="mr10"><span></span>반납 지체중인 컨테이너</label>

            <input type="radio" id="det2" name="det">
            <label for="det2"><span></span>전체 컨테이너 보기</label>

          </span>
        </div>

        <table class="tbl_col">
          <colgroup>
            <col style="width:5%">
            <col style="width:18%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:14%">
            <col style="width:11%">
            <col style="width:8%">
            <col style="width:12%">
            <col style="width:12%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" class="pr0">
                <input type="checkbox" id="chkall">
                <label for="chkall"><span class="offscreen">선택</span></label>
              </th>
              <th scope="col">Cntr. No</th>
              <th scope="col">FreeDay</th>
              <th scope="col">반납시한</th>
              <th scope="col">반납(예정)일</th>
              <th scope="col">Over Days.</th>
              <th scope="col">상태</th>
              <th scope="col">금액</th>
              <th scope="col">금액(VAT)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pr0">
                <input type="checkbox" id="chk1">
                <label for="chk1"><span class="offscreen">선택</span></label>
              </td>
              <td>BMOU6219934</td>
              <td>30</td>
              <td>2021-11-04</td>
              <td>
                <div class="input_calendar short">
                  <input type="text" value="2021-10-18">
                  <button class="tbl_icon calendar">달력</button>
                </div>
              </td>
              <td>0</td>
              <td class="color_red">반출</td>
              <td class="text_right">22,4000,000</td>
              <td class="text_right">22,4000,000</td>
            </tr>
            <tr>
              <td colspan="9">No Demurrage Tariff (OUTBOUND)</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="7" class="text_right">SUB TOTAL</th>
              <td class="text_right">22,4000,000</td>
              <td class="text_right">22,4000,000</td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">D/C AMOUNT</th>
              <td class="text_right">0%</td>
              <td class="text_right">0</td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">GRAND TOTAL</th>
              <td colspan="2" class="text_right">22,4000,000</td>
            </tr>
          </tfoot>
        </table>
        <div class="flex_box mt10">
          <a class="button sm" href="#">반납일 KL-NET 재전송</a>
          <a class="button blue sm ml_auto mr5" href="#">Previous Issued Invoice</a>
          <a class="button blue sm mr5" href="#">입금/계산서 정보입력</a>
          <a class="button blue sm mr5" href="#">Proforma Invoice</a>
          <a class="button blue sm mr5" href="#">Freetime 연장요청</a>
          <a class="button blue sm" href="#">Freetime 요청내역</a>
        </div>
        <div class="desc_list">
          <ul>
            <li>
              반납 요청하실 컨테이너를 선택하신 후 반납(예정)일을 입력 후 조회하시면 Detention 금액을 확인 하실 수 있으며, Proforma invoice 출력도 가능합니다.
            </li>
            <li>
              현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반납일이 상이 할 경우 [반납일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.
            </li>
          </ul>
        </div>

        <div class="flex_box mt20">
          <h2 class="content_title">MOR(Monitoring)</h2>
          <span class="cal_btn">
            <input type="radio" id="mor1" name="mor">
            <label for="mor1" class="mr10"><span></span>반출 지체중인 컨테이너</label>

            <input type="radio" id="mor2" name="mor">
            <label for="mor2"><span></span>전체 컨테이너 보기</label>

          </span>
        </div>

        <table class="tbl_col">
          <colgroup>
            <col style="width:5%">
            <col style="width:18%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:14%">
            <col style="width:11%">
            <col style="width:8%">
            <col style="width:12%">
            <col style="width:12%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" class="pr0">
                <input type="checkbox" id="chkall">
                <label for="chkall"><span class="offscreen">선택</span></label>
              </th>
              <th scope="col">Cntr. No</th>
              <th scope="col">FreeDay</th>
              <th scope="col">반납시한</th>
              <th scope="col">반납(예정)일</th>
              <th scope="col">Over Days.</th>
              <th scope="col">상태</th>
              <th scope="col">금액</th>
              <th scope="col">금액(VAT)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pr0">
                <input type="checkbox" id="chk1">
                <label for="chk1"><span class="offscreen">선택</span></label>
              </td>
              <td>BMOU6219934</td>
              <td>30</td>
              <td>2021-11-04</td>
              <td>
                <div class="input_calendar short">
                  <input type="text" value="2021-10-18">
                  <button class="tbl_icon calendar">달력</button>
                </div>
              </td>
              <td>0</td>
              <td class="color_red">미반출</td>
              <td class="text_right">22,4000,000</td>
              <td class="text_right">22,4000,000</td>
            </tr>
            <tr>
              <td colspan="9">No Demurrage Tariff (OUTBOUND)</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="7" class="text_right">SUB TOTAL</th>
              <td class="text_right">22,4000,000</td>
              <td class="text_right">22,4000,000</td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">D/C AMOUNT</th>
              <td class="text_right">0%</td>
              <td class="text_right">0</td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">GRAND TOTAL</th>
              <td colspan="2" class="text_right">22,4000,000</td>
            </tr>
          </tfoot>
        </table>
        <div class="flex_box mt10">
          <a class="button sm" href="#">반출일 KL-NET 재전송</a>
          <a class="button blue sm ml_auto mr5" href="#">Previous Issued Invoice</a>
          <a class="button blue sm mr5" href="#">입금/계산서 정보입력</a>
          <a class="button blue sm mr5" href="#">Proforma Invoice</a>
          <a class="button blue sm mr5" href="#">Freetime 연장요청</a>
          <a class="button blue sm" href="#">Freetime 요청내역</a>
        </div>
        <div class="desc_list">
          <ul>
            <li>
              Monitoring 요청하실 컨테이너를 선택하신 후 반출(예정)일을 입력 후 조회하시면 Monitoring 금액을 확인 하실 수 있으며, Proforma invoice 출력도 가능합니다.
            </li>
            <li>
              현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.
            </li>
          </ul>
        </div>

        <div class="flex_box mt20">
          <h2 class="content_title">STR(Storage)</h2>
          <span class="cal_btn">
            <input type="radio" id="str1" name="str">
            <label for="str1" class="mr10"><span></span>반출 지체중인 컨테이너</label>

            <input type="radio" id="str2" name="str">
            <label for="str2"><span></span>전체 컨테이너 보기</label>

          </span>
        </div>

        <table class="tbl_col">
          <colgroup>
            <col style="width:5%">
            <col style="width:18%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:14%">
            <col style="width:11%">
            <col style="width:8%">
            <col style="width:12%">
            <col style="width:12%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col" class="pr0">
                <input type="checkbox" id="chkall">
                <label for="chkall"><span class="offscreen">선택</span></label>
              </th>
              <th scope="col">Cntr. No</th>
              <th scope="col">FreeDay</th>
              <th scope="col">반납시한</th>
              <th scope="col">반납(예정)일</th>
              <th scope="col">Over Days.</th>
              <th scope="col">상태</th>
              <th scope="col">금액</th>
              <th scope="col">금액(VAT)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pr0">
                <input type="checkbox" id="chk1">
                <label for="chk1"><span class="offscreen">선택</span></label>
              </td>
              <td>BMOU6219934</td>
              <td>30</td>
              <td>2021-11-04</td>
              <td>
                <div class="input_calendar short">
                  <input type="text" value="2021-10-18">
                  <button class="tbl_icon calendar">달력</button>
                </div>
              </td>
              <td>0</td>
              <td class="color_red">미반출</td>
              <td class="text_right">22,4000,000</td>
              <td class="text_right">22,4000,000</td>
            </tr>
            <tr>
              <td colspan="9">No Demurrage Tariff (OUTBOUND)</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="7" class="text_right">SUB TOTAL</th>
              <td class="text_right">22,4000,000</td>
              <td class="text_right">22,4000,000</td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">D/C AMOUNT</th>
              <td class="text_right">0%</td>
              <td class="text_right">0</td>
            </tr>
            <tr>
              <th colspan="7" class="text_right">GRAND TOTAL</th>
              <td colspan="2" class="text_right">22,4000,000</td>
            </tr>
          </tfoot>
        </table>
        <div class="flex_box mt10">
          <a class="button sm" href="#">반출일 KL-NET 재전송</a>
          <a class="button blue sm ml_auto mr5" href="#">Previous Issued Invoice</a>
          <a class="button blue sm mr5" href="#">입금/계산서 정보입력</a>
          <a class="button blue sm mr5" href="#">Proforma Invoice</a>
          <a class="button blue sm mr5" href="#">Freetime 연장요청</a>
          <a class="button blue sm" href="#">Freetime 요청내역</a>
        </div>
        <div class="desc_list">
          <ul>
            <li>
              Monitoring 요청하실 컨테이너를 선택하신 후 반출(예정)일을 입력 후 조회하시면 Monitoring 금액을 확인 하실 수 있으며, Proforma invoice 출력도 가능합니다.
            </li>
            <li>
              현재 조회하신 정보와 KL-NET 및 E-TRANS 화면에 반출일이 상이 할 경우 [반출일 KL-NET 재전송] 버튼을 클릭하여 재전송 후 확인이 가능합니다.
            </li>
          </ul>
        </div>
      </div><!-- content_box // -->

      <div class="btn_area">
        <div class="btn_right">
          <a href="#none" class="button blue lg">A/N</a><a href="#none" class="button blue lg">B/L COPY</a><a href="#none" class="button blue lg">Free Time 요청</a><a href="#none" class="button blue lg">Free Time Tariff</a><a href="#none" class="button blue lg">Free Time Print</a><a href="#none" class="button blue lg">해당 모선 Schedule조회</a>
        </div>
      </div>
    </div><!-- content // -->
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
// import { fields, columns, layout } from './check'

const fields = [
    { fieldName: 'cntrNo', dataType: 'text' },
    { fieldName: 'freeDds', dataType: 'text' },
    { fieldName: 'freeEndDt', dataType: 'text' },
    { fieldName: 'extsDt', dataType: 'text' },
    { fieldName: 'ovrDds', dataType: 'text' },
    { fieldName: 'cntrSts', dataType: 'text' },
    { fieldName: 'totAmt', dataType: 'text' },
    { fieldName: 'totAmtVat', dataType: 'text' }
  ]

const columns = [
    {
      name: 'cntrNo',
      fieldName: 'cntrNo',
      header: { text: 'Cntr. No' },
      type: 'text',
      editable: false
    },
    {
      name: 'freeDds',
      fieldName: 'freeDds',
      header: { text: 'FreeDay' },
      type: 'text',
      editable: false
    },
    {
      name: 'freeEndDt',
      fieldName: 'freeEndDt',
      header: { text: '반출시한' },
      type: 'text',
      editable: false
    },
    {
      name: 'extsDt',
      fieldName: 'extsDt',
      header: { text: '반출(예정)일' },
      type: 'text',
      editable: false
    },
    {
      name: 'ovrDds',
      fieldName: 'ovrDds',
      header: { text: 'Over Days.' },
      type: 'text',
      editable: false
    },
      {
      name: 'cntrSts',
      fieldName: 'cntrSts',
      header: { text: '상태' },
      type: 'text',
      editable: false
    },
    {
      name: 'totAmt',
      fieldName: 'totAmt',
      header: { text: '금액' },
      type: 'text',
      editable: false
    },
    {
      name: 'totAmtVat',
      fieldName: 'totAmtVat',
      header: { text: '금액(VAT)' },
      type: 'text',
      editable: false
    }

  ]

const layout = [
          'cntrNo',
          'freeDds',
          'freeEndDt',
          'extsDt',
          'ovrDds',
          'cntrSts',
          'totAmt',
          'totAmtVat'
  ]

let gridView = GridView
let provider = LocalDataProvider

export default {
    name: 'SearchdemurrageDetailForm',

    mounted () {
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)

    gridView.setColumns(columns)
    gridView.setColumnLayout(layout)

    gridView.setRowIndicator({
      visible: false
    })

    var header = gridView.getColumnProperty('id', 'header')
    console.log('header', header)
    header.text = '아이디'
    gridView.setColumnProperty('id', 'header', header)
  }
}
</script>

<style scoped>

</style>
