
export const fields = [
  { fieldName: 'cntrNo', dataType: 'text' },
  { fieldName: 'freeDds', dataType: 'text' },
  { fieldName: 'freeEndDt', dataType: 'text' },
  { fieldName: 'extsDt', dataType: 'text' },
  { fieldName: 'ovrDds', dataType: 'text' },
  { fieldName: 'cntrSts', dataType: 'text' },
  { fieldName: 'totAmt', dataType: 'text' },
  { fieldName: 'totAmtVat', dataType: 'text' }
]
// 반출시한, 반출(예정)일 : Dem
export const columns1 = [
  {
    name: 'cntrNo',
    fieldName: 'cntrNo',
    header: { text: 'Cntr. No' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeDds',
    fieldName: 'freeDds',
    header: { text: 'FreeDay' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeEndDt',
    fieldName: 'freeEndDt',
    header: { text: '반출시한' },
    type: 'text',
    editable: false
  },
  {
    name: 'extsDt',
    fieldName: 'extsDt',
    header: { text: '반출(예정)일' },
    type: 'text',
    width: '150',
    renderer: 'renderer_imgbtn1',
    styleName: 'left-column custom_renderer',
    editable: true
  },
  {
    name: 'ovrDds',
    fieldName: 'ovrDds',
    header: { text: 'Over Days.' },
    type: 'text',
    editable: false
  },
    {
    name: 'cntrSts',
    fieldName: 'cntrSts',
    header: { text: '상태' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmt',
    fieldName: 'totAmt',
    header: { text: '금액' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmtVat',
    fieldName: 'totAmtVat',
    header: { text: '금액(VAT)' },
    type: 'text',
    editable: false
  }

]

// 반납기한, 반납(예정)일 : Det
export const columns2 = [
  {
    name: 'cntrNo',
    fieldName: 'cntrNo',
    header: { text: 'Cntr. No' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeDds',
    fieldName: 'freeDds',
    header: { text: 'FreeDay' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeEndDt',
    fieldName: 'freeEndDt',
    header: { text: '반납기한' },
    type: 'text',
    editable: false
  },
  {
    name: 'extsDt',
    fieldName: 'extsDt',
    header: { text: '반납(예정)일' },
    type: 'text',
    width: '150',
    renderer: 'renderer_imgbtn2',
    styleName: 'left-column custom_renderer',
    editable: true

  },
  {
    name: 'ovrDds',
    fieldName: 'ovrDds',
    header: { text: 'Over Days.' },
    type: 'text',
    editable: false
  },
    {
    name: 'cntrSts',
    fieldName: 'cntrSts',
    header: { text: '상태' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmt',
    fieldName: 'totAmt',
    header: { text: '금액' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmtVat',
    fieldName: 'totAmtVat',
    header: { text: '금액(VAT)' },
    type: 'text',
    editable: false
  }

]

// 반출기한, 반출(예정)일 : Mon
export const columns3 = [
  {
    name: 'cntrNo',
    fieldName: 'cntrNo',
    header: { text: 'Cntr. No' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeDds',
    fieldName: 'freeDds',
    header: { text: 'FreeDay' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeEndDt',
    fieldName: 'freeEndDt',
    header: { text: '반출기한' },
    type: 'text',
    editable: false
  },
  {
    name: 'extsDt',
    fieldName: 'extsDt',
    header: { text: '반출(예정)일' },
    type: 'text',
    width: '150',
    renderer: 'renderer_imgbtn3',
    styleName: 'left-column custom_renderer',
    editable: true
  },
  {
    name: 'ovrDds',
    fieldName: 'ovrDds',
    header: { text: 'Over Days.' },
    type: 'text',
    editable: false
  },
    {
    name: 'cntrSts',
    fieldName: 'cntrSts',
    header: { text: '상태' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmt',
    fieldName: 'totAmt',
    header: { text: '금액' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmtVat',
    fieldName: 'totAmtVat',
    header: { text: '금액(VAT)' },
    type: 'text',
    editable: false
  }

]
// 반출기한, 반출(예정)일 : Sto
export const columns4 = [
  {
    name: 'cntrNo',
    fieldName: 'cntrNo',
    header: { text: 'Cntr. No' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeDds',
    fieldName: 'freeDds',
    header: { text: 'FreeDay' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeEndDt',
    fieldName: 'freeEndDt',
    header: { text: '반출기한' },
    type: 'text',
    editable: false
  },
  {
    name: 'extsDt',
    fieldName: 'extsDt',
    header: { text: '반출(예정)일' },
    type: 'text',
    width: '150',
    renderer: 'renderer_imgbtn4',
    styleName: 'left-column custom_renderer',
    editable: true

  },
  {
    name: 'ovrDds',
    fieldName: 'ovrDds',
    header: { text: 'Over Days.' },
    type: 'text',
    editable: false
  },
    {
    name: 'cntrSts',
    fieldName: 'cntrSts',
    header: { text: '상태' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmt',
    fieldName: 'totAmt',
    header: { text: '금액' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmtVat',
    fieldName: 'totAmtVat',
    header: { text: '금액(VAT)' },
    type: 'text',
    editable: false
  }

]

// 반납기한, 반납(예정)일 : Det(Combined)
export const columns5 = [
{
  name: 'cntrNo',
  fieldName: 'cntrNo',
  header: { text: 'Cntr. No' },
  type: 'text',
  editable: false
},
{
  name: 'freeDds',
  fieldName: 'freeDds',
  header: { text: 'FreeDay' },
  type: 'text',
  editable: false
},
{
  name: 'freeEndDt',
  fieldName: 'freeEndDt',
  header: { text: '반납기한' },
  type: 'text',
  editable: false
},
{
  name: 'extsDt',
  fieldName: 'extsDt',
  header: { text: '반납(예정)일' },
  type: 'text',
  width: '150',
  renderer: 'renderer_imgbtn5',
  styleName: 'left-column custom_renderer',
  editable: true

},
{
  name: 'ovrDds',
  fieldName: 'ovrDds',
  header: { text: 'Over Days.' },
  type: 'text',
  editable: false
},
  {
  name: 'cntrSts',
  fieldName: 'cntrSts',
  header: { text: '상태' },
  type: 'text',
  editable: false
},
{
  name: 'totAmt',
  fieldName: 'totAmt',
  header: { text: '금액' },
  type: 'text',
  editable: false
},
{
  name: 'totAmtVat',
  fieldName: 'totAmtVat',
  header: { text: '금액(VAT)' },
  type: 'text',
  editable: false
}

]

// 반출/반납 시한 반출/반납(예정)일 : Ofc
export const columns6 = [
  {
    name: 'cntrNo',
    fieldName: 'cntrNo',
    header: { text: 'Cntr. No' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeDds',
    fieldName: 'freeDds',
    header: { text: 'FreeDay' },
    type: 'text',
    editable: false
  },
  {
    name: 'freeEndDt',
    fieldName: 'freeEndDt',
    header: { text: '반출/반납 시한' },
    type: 'text',
    editable: false
  },
  {
    name: 'extsDt',
    fieldName: 'extsDt',
    header: { text: '반출/반납(예정)일' },
    type: 'text',
    width: '150',
    renderer: 'renderer_imgbtn6',
    styleName: 'left-column custom_renderer',
    editable: true

  },
  {
    name: 'ovrDds',
    fieldName: 'ovrDds',
    header: { text: 'Over Days.' },
    type: 'text',
    editable: false
  },
    {
    name: 'cntrSts',
    fieldName: 'cntrSts',
    header: { text: '상태' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmt',
    fieldName: 'totAmt',
    header: { text: '금액' },
    type: 'text',
    editable: false
  },
  {
    name: 'totAmtVat',
    fieldName: 'totAmtVat',
    header: { text: '금액(VAT)' },
    type: 'text',
    editable: false
  }

]

export const layout = [
        'cntrNo',
        'freeDds',
        'freeEndDt',
        'extsDt',
        'ovrDds',
        'cntrSts',
        'totAmt',
        'totAmtVat'
]
