var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" 테스트 ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("C/A 내역 조회")]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_search" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("B/L No.")]),
                _c("td", [
                  _c("span", [_vm._v(_vm._s(_vm.blNo))]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedCaNo,
                          expression: "selectedCaNo",
                        },
                      ],
                      staticClass: "wid180 ml20",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedCaNo = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.getCaHistory,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("select"),
                      ]),
                      _vm._l(_vm.mainCAHistoryList, function (ca, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: ca.caNo } },
                          [
                            _vm._v(
                              _vm._s(ca.caNo) +
                                "/" +
                                _vm._s(ca.reqDt) +
                                "(" +
                                _vm._s(ca.caCatNm) +
                                ")"
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v("Shipper / Consignee / Notify"),
          ]),
          _c(
            "table",
            { staticClass: "tbl_col mt10" },
            [
              _vm._m(1),
              _vm._m(2),
              _vm._l(_vm.blHeader, function (header, index) {
                return _c("tbody", { key: index }, [
                  (header.shprNmOrg || header.shprNmRvs) &&
                  (header.shprAdrOrg || header.shprAdrRvs)
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper | Name")]),
                        _c("td", [_vm._v(_vm._s(header.shprNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.shprNmRvs))]),
                      ])
                    : header.shprNmOrg || header.shprNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper | Name")]),
                        _c("td", [_vm._v(_vm._s(header.shprNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.shprNmRvs))]),
                      ])
                    : _vm._e(),
                  (header.shprNmOrg || header.shprNmRvs) &&
                  (header.shprAdrOrg || header.shprAdrRvs)
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper | Address")]),
                        _c("td", [_vm._v(_vm._s(header.shprAdrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.shprAdrRvs))]),
                      ])
                    : header.shprAdrOrg || header.shprAdrRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper | Address")]),
                        _c("td", [_vm._v(_vm._s(header.shprAdrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.shprAdrRvs))]),
                      ])
                    : _vm._e(),
                  (header.cneNmOrg || header.cneNmRvs) &&
                  (header.cneAdrOrg || header.cneAdrRvs)
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee | Name")]),
                        _c("td", [_vm._v(_vm._s(header.cneNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneNmRvs))]),
                      ])
                    : header.cneNmOrg || header.cneNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee | Name")]),
                        _c("td", [_vm._v(_vm._s(header.cneNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneNmRvs))]),
                      ])
                    : _vm._e(),
                  (header.cneNmOrg || header.cneNmRvs) &&
                  (header.cneAdrOrg || header.cneAdrRvs)
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee | Address")]),
                        _c("td", [_vm._v(_vm._s(header.cneAdrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneAdrRvs))]),
                      ])
                    : header.cneAdrOrg || header.cneAdrRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee | Address")]),
                        _c("td", [_vm._v(_vm._s(header.cneAdrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneAdrRvs))]),
                      ])
                    : _vm._e(),
                  (header.ntfNmOrg || header.ntfNmRvs) &&
                  (header.ntfAdrOrg || header.ntfAdrRvs)
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify | Name")]),
                        _c("td", [_vm._v(_vm._s(header.ntfNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntfNmRvs))]),
                      ])
                    : header.ntfNmOrg || header.ntfNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify | Name")]),
                        _c("td", [_vm._v(_vm._s(header.ntfNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntfNmRvs))]),
                      ])
                    : _vm._e(),
                  (header.ntfNmOrg || header.ntfNmRvs) &&
                  (header.ntfAdrOrg || header.ntfAdrRvs)
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify | Address")]),
                        _c("td", [_vm._v(_vm._s(header.ntfAdrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntfAdrRvs))]),
                      ])
                    : header.ntfAdrOrg || header.ntfAdrRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify | Address")]),
                        _c("td", [_vm._v(_vm._s(header.ntfAdrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntfAdrRvs))]),
                      ])
                    : _vm._e(),
                  header.measureOrg || header.measureRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Measure")]),
                        _c("td", [_vm._v(_vm._s(header.measureOrg))]),
                        _c("td", [_vm._v(_vm._s(header.measureRvs))]),
                      ])
                    : _vm._e(),
                  header.grsWtOrg || header.grsWtRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Gross Weight")]),
                        _c("td", [_vm._v(_vm._s(header.grsWtOrg))]),
                        _c("td", [_vm._v(_vm._s(header.grsWtRvs))]),
                      ])
                    : _vm._e(),
                  header.blTypeOrg || header.blTypeRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("B/L Type")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              header.blTypeOrg == "01" ? "O.B/L" : "SeaWay Bill"
                            )
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              header.blTypeRvs == "01" ? "O.B/L" : "SeaWay Bill"
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  (header.pkgCdOrg || header.pkgCdRvs) &&
                  (header.pkgQtyOrg || header.pkgQtyRvs)
                    ? _c("tr", [
                        _c("th", [_vm._v("Package | Qty")]),
                        _c("td", [_vm._v(_vm._s(header.pkgQtyOrg))]),
                        _c("td", [_vm._v(_vm._s(header.pkgQtyRvs))]),
                      ])
                    : header.pkgQtyOrg || header.pkgQtyRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Package | Qty")]),
                        _c("td", [_vm._v(_vm._s(header.pkgQtyOrg))]),
                        _c("td", [_vm._v(_vm._s(header.pkgQtyRvs))]),
                      ])
                    : _vm._e(),
                  (header.pkgCdOrg || header.pkgCdRvs) &&
                  (header.pkgQtyOrg || header.pkgQtyRvs)
                    ? _c("tr", [
                        _c("th", [_vm._v("Package | 단위")]),
                        _c("td", [_vm._v(_vm._s(header.pkgCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.pkgCdRvs))]),
                      ])
                    : header.pkgCdOrg || header.pkgCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Package | 단위")]),
                        _c("td", [_vm._v(_vm._s(header.pkgCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.pkgCdRvs))]),
                      ])
                    : _vm._e(),
                  header.cmdtDscOrg || header.cmdtDscRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Commodity")]),
                        _c("td", [_vm._v(_vm._s(header.cmdtDscOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cmdtDscRvs))]),
                      ])
                    : _vm._e(),
                  header.oblPrtScntOrg || header.oblPrtScntRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("No. of B/")]),
                        _c("td", [_vm._v(_vm._s(header.oblPrtScntOrg))]),
                        _c("td", [_vm._v(_vm._s(header.oblPrtScntRvs))]),
                      ])
                    : _vm._e(),
                  header.issCtrCdOrg || header.issCtrCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Issue Country")]),
                        _c("td", [_vm._v(_vm._s(header.issCtrCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.issCtrCdRvs))]),
                      ])
                    : _vm._e(),
                  header.issPlcCdOrg || header.issPlcCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Issue Place")]),
                        _c("td", [_vm._v(_vm._s(header.issPlcCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.issPlcCdRvs))]),
                      ])
                    : _vm._e(),
                  header.frtPayPlcCdOrg || header.frtPayPlcCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Freight Payable Place")]),
                        _c("td", [_vm._v(_vm._s(header.frtPayPlcCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.frtPayPlcCdRvs))]),
                      ])
                    : _vm._e(),
                  header.porPortNmOrg || header.porPortNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Place of Receipt")]),
                        _c("td", [_vm._v(_vm._s(header.porPortNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.porPortNmRvs))]),
                      ])
                    : _vm._e(),
                  header.porPortNmOrg || header.porPortNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Port of Loading")]),
                        _c("td", [_vm._v(_vm._s(header.polPortNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.polPortNmRvs))]),
                      ])
                    : _vm._e(),
                  header.porPortNmOrg || header.porPortNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Port of Discharging")]),
                        _c("td", [_vm._v(_vm._s(header.podPortNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.podPortNmRvs))]),
                      ])
                    : _vm._e(),
                  header.porPortNmOrg || header.porPortNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Place of Delivery")]),
                        _c("td", [_vm._v(_vm._s(header.dlyPortNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.dlyPortNmRvs))]),
                      ])
                    : _vm._e(),
                  header.oblPrtScntOrg || header.oblPrtScntRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("HS Code")]),
                        _c("td", [_vm._v(_vm._s(header.oblPrtScntOrg))]),
                        _c("td", [_vm._v(_vm._s(header.oblPrtScntRvs))]),
                      ])
                    : _vm._e(),
                  header.porPortNmOrg || header.porPortNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Final Destination")]),
                        _c("td", [_vm._v(_vm._s(header.oblPrtScntOrg))]),
                        _c("td", [_vm._v(_vm._s(header.oblPrtScntRvs))]),
                      ])
                    : _vm._e(),
                  header.obrdDtOrg || header.obrdDtRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Issue Date")]),
                        _c("td", [_vm._v(_vm._s(header.issDtOrg))]),
                        _c("td", [_vm._v(_vm._s(header.issDtRvs))]),
                      ])
                    : _vm._e(),
                  header.obrdDtOrg || header.obrdDtRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("On Board Date")]),
                        _c("td", [_vm._v(_vm._s(header.obrdDtOrg))]),
                        _c("td", [_vm._v(_vm._s(header.obrdDtRvs))]),
                      ])
                    : _vm._e(),
                  header.idTaxNoOrg || header.idTaxNoRvs
                    ? _c("tr", [
                        _vm._m(3, true),
                        _c("td", [_vm._v(_vm._s(header.idTaxNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.idTaxNoRvs))]),
                      ])
                    : _vm._e(),
                  header.etTaxNoOrg || header.etTaxNoRvs
                    ? _c("tr", [
                        _vm._m(4, true),
                        _c("td", [_vm._v(_vm._s(header.etTaxNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.etTaxNoRvs))]),
                      ])
                    : _vm._e(),
                  header.wstCgoYnOrg || header.wstCgoYnRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("WASTE/SCRAP Cargo")]),
                        _c("td", [_vm._v(_vm._s(header.wstCgoYnOrg))]),
                        _c("td", [_vm._v(_vm._s(header.wstCgoYnRvs))]),
                      ])
                    : _vm._e(),
                  header.imLicNoOrg || header.imLicNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("License No")]),
                        _c("td", [_vm._v(_vm._s(header.imLicNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.imLicNoRvs))]),
                      ])
                    : _vm._e(),
                  header.imBnkGurNoOrg || header.imBnkGurNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Bank Guarantee No")]),
                        _c("td", [_vm._v(_vm._s(header.imBnkGurNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.imBnkGurNoRvs))]),
                      ])
                    : _vm._e(),
                  header.iecNoOrg || header.iecNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("IEC No")]),
                        _c("td", [_vm._v(_vm._s(header.iecNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.iecNoRvs))]),
                      ])
                    : _vm._e(),
                  header.gstNoOrg || header.gstNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("GST No")]),
                        _c("td", [_vm._v(_vm._s(header.gstNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.gstNoRvs))]),
                      ])
                    : _vm._e(),
                  header.iecPicEmlAddrOrg || header.iecPicEmlAddrRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("IEC E-Mail")]),
                        _c("td", [_vm._v(_vm._s(header.iecPicEmlAddrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.iecPicEmlAddrRvs))]),
                      ])
                    : _vm._e(),
                  header.actShprCstCtrCdOrg || header.actShprCstCtrCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper (Country)")]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstCtrCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstCtrCdRvs))]),
                      ])
                    : _vm._e(),
                  header.actShprCstCityNmOrg || header.actShprCstCityNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper (City)")]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstCityNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstCityNmRvs))]),
                      ])
                    : _vm._e(),
                  header.actShprCstPicNmOrg || header.actShprCstPicNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper (PIC)")]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstPicNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstPicNmRvs))]),
                      ])
                    : _vm._e(),
                  header.actShprCstTelNoOrg || header.actShprCstTelNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper (PIC Tel)")]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstTelNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstTelNoRvs))]),
                      ])
                    : _vm._e(),
                  header.actShprCstFaxNoOrg || header.actShprCstFaxNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper (PIC Fax)")]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstFaxNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstFaxNoRvs))]),
                      ])
                    : _vm._e(),
                  header.actShprCstEmlAddrOrg || header.actShprCstEmlAddrRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper (PIC e-mail)")]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstEmlAddrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.actShprCstEmlAddrRvs))]),
                      ])
                    : _vm._e(),
                  header.actShprIdCatCdOrg || header.actShprIdCatCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper (Company ID)")]),
                        _c("td", [_vm._v(_vm._s(header.actShprIdCatCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.actShprIdCatCdRvs))]),
                      ])
                    : _vm._e(),
                  header.actShprIdNoOrg || header.actShprIdNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Shipper (Company ID No)")]),
                        _c("td", [_vm._v(_vm._s(header.actShprIdNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.actShprIdNoRvs))]),
                      ])
                    : _vm._e(),
                  header.cneCstCtrCdOrg || header.cneCstCtrCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee (Country)")]),
                        _c("td", [_vm._v(_vm._s(header.cneCstCtrCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneCstCtrCdRvs))]),
                      ])
                    : _vm._e(),
                  header.cneCstCityNmOrg || header.cneCstCityNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee (City)")]),
                        _c("td", [_vm._v(_vm._s(header.cneCstCityNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneCstCityNmRvs))]),
                      ])
                    : _vm._e(),
                  header.cneCstPicNmOrg || header.cneCstPicNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee (PIC)")]),
                        _c("td", [_vm._v(_vm._s(header.cneCstPicNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneCstPicNmRvs))]),
                      ])
                    : _vm._e(),
                  header.cneCstTelNoOrg || header.cneCstTelNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee (PIC Tel)")]),
                        _c("td", [_vm._v(_vm._s(header.cneCstTelNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneCstTelNoRvs))]),
                      ])
                    : _vm._e(),
                  header.cneCstFaxNoOrg || header.cneCstFaxNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee (PIC Fax)")]),
                        _c("td", [_vm._v(_vm._s(header.cneCstFaxNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneCstFaxNoRvs))]),
                      ])
                    : _vm._e(),
                  header.cneCstEmlAddrOrg || header.cneCstEmlAddrRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee (PIC e-mail)")]),
                        _c("td", [_vm._v(_vm._s(header.cneCstEmlAddrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneCstEmlAddrRvs))]),
                      ])
                    : _vm._e(),
                  header.cneIdCatCdOrg || header.cneIdCatCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee (Company ID)")]),
                        _c("td", [_vm._v(_vm._s(header.cneIdCatCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneIdCatCdRvs))]),
                      ])
                    : _vm._e(),
                  header.cneIdNoOrg || header.cneIdNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee (Company ID No)")]),
                        _c("td", [_vm._v(_vm._s(header.cneIdNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneIdNoRvs))]),
                      ])
                    : _vm._e(),
                  header.ntifCstCityNmOrg || header.ntifCstCityNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify (City)")]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstCityNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstCityNmRvs))]),
                      ])
                    : _vm._e(),
                  header.cneCstCityNmOrg || header.cneCstCityNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Consignee (City)")]),
                        _c("td", [_vm._v(_vm._s(header.cneCstCityNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.cneCstCityNmRvs))]),
                      ])
                    : _vm._e(),
                  header.ntifCstPicNmOrg || header.ntifCstPicNmRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify (PIC)")]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstPicNmOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstPicNmRvs))]),
                      ])
                    : _vm._e(),
                  header.ntifCstTelNoOrg || header.ntifCstTelNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify (PIC Tel)")]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstTelNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstTelNoRvs))]),
                      ])
                    : _vm._e(),
                  header.ntifCstFaxNoOrg || header.ntifCstFaxNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify (PIC Fax)")]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstFaxNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstFaxNoRvs))]),
                      ])
                    : _vm._e(),
                  header.ntifCstEmlAddrOrg || header.ntifCstEmlAddrRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify (PIC e-mail)")]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstEmlAddrOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntifCstEmlAddrRvs))]),
                      ])
                    : _vm._e(),
                  header.ntifIdCatCdOrg || header.ntifIdCatCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify (Company ID)")]),
                        _c("td", [_vm._v(_vm._s(header.ntifIdCatCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntifIdCatCdRvs))]),
                      ])
                    : _vm._e(),
                  header.ntifIdNoOrg || header.ntifIdNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Notify (Company ID No)")]),
                        _c("td", [_vm._v(_vm._s(header.ntifIdNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.ntifIdNoRvs))]),
                      ])
                    : _vm._e(),
                  header.invValCurCdOrg || header.invValCurCdRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Invoice Value Cur")]),
                        _c("td", [_vm._v(_vm._s(header.invValCurCdOrg))]),
                        _c("td", [_vm._v(_vm._s(header.invValCurCdRvs))]),
                      ])
                    : _vm._e(),
                  header.invValExpOrg || header.invValExpRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Invoice Value Exp")]),
                        _c("td", [_vm._v(_vm._s(header.invValExpOrg))]),
                        _c("td", [_vm._v(_vm._s(header.invValExpRvs))]),
                      ])
                    : _vm._e(),
                  header.panNoOrg || header.panNoRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("PAN No")]),
                        _c("td", [_vm._v(_vm._s(header.panNoOrg))]),
                        _c("td", [_vm._v(_vm._s(header.panNoRvs))]),
                      ])
                    : _vm._e(),
                  header.blPrtSntcContOrg || header.blPrtSntcContRvs
                    ? _c("tr", [
                        _c("th", [_vm._v("Say")]),
                        _c("td", [_vm._v(_vm._s(header.blPrtSntcContOrg))]),
                        _c("td", [_vm._v(_vm._s(header.blPrtSntcContRvs))]),
                      ])
                    : _vm._e(),
                ])
              }),
              !_vm.blHeader ? _c("tbody", [_vm._m(5)]) : _vm._e(),
            ],
            2
          ),
          _c("h2", { staticClass: "content_title" }, [
            _vm._v("Marks & Description"),
          ]),
          _c(
            "table",
            { staticClass: "tbl_col mt10" },
            [
              _vm._m(6),
              _vm._m(7),
              _vm._l(_vm.blBody, function (body, index) {
                return _c("tbody", { key: index }, [
                  body.mrkOrg || body.mrkRvs
                    ? _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              body.ordSeqOrg == "1" ? "Mark" : "Mark Rider"
                            )
                          ),
                        ]),
                        _c("td", {
                          domProps: { innerHTML: _vm._s(body.mrkOrg) },
                        }),
                        _c("td", {
                          domProps: { innerHTML: _vm._s(body.mrkRvs) },
                        }),
                      ])
                    : _vm._e(),
                  body.dscrOrg || body.dscrRvs
                    ? _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              body.ordSeqOrg == "1"
                                ? "Description"
                                : "Description Rider"
                            )
                          ),
                        ]),
                        _c("td", {
                          domProps: { innerHTML: _vm._s(body.dscrOrg) },
                        }),
                        _c("td", {
                          domProps: { innerHTML: _vm._s(body.dscrRvs) },
                        }),
                      ])
                    : _vm._e(),
                ])
              }),
              !_vm.blBody ? _c("tbody", [_vm._m(8)]) : _vm._e(),
            ],
            2
          ),
          _c("h2", { staticClass: "content_title" }, [_vm._v("Container")]),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(9),
            _vm._m(10),
            _vm.blContainer
              ? _c(
                  "tbody",
                  _vm._l(_vm.blContainer, function (container, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [
                        _vm._v(
                          _vm._s(index % 2 === 0 ? "Original" : "Revised")
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(container.cntrNo))]),
                      _c("td", [_vm._v(_vm._s(container.cntrSzCd))]),
                      _c("td", [_vm._v(_vm._s(container.cntrTypCd))]),
                      _c("td", [_vm._v(_vm._s(container.cgoTypCd))]),
                      _c("td", [_vm._v(_vm._s(container.shprVanYn))]),
                      _c("td", [_vm._v(_vm._s(container.sealNo1))]),
                      _c("td", [_vm._v(_vm._s(container.sealNo2))]),
                      _c("td", [_vm._v(_vm._s(container.sealNo3))]),
                      _c("td", [_vm._v(_vm._s(container.sealNo4))]),
                      _c("td", [_vm._v(_vm._s(container.sealNo5))]),
                    ])
                  }),
                  0
                )
              : _c("tbody", [_vm._m(11)]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "100px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "35%" } }),
      _c("col", { attrs: { width: "35%" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Original")]),
        _c("th", [_vm._v("Revised")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("Importer's"), _c("br"), _vm._v(" Tax No")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [_vm._v("Exporter's"), _c("br"), _vm._v(" Tax No")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "3" } }, [_vm._v("수정 내역이 없습니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "35%" } }),
      _c("col", { attrs: { width: "35%" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Original")]),
        _c("th", [_vm._v("Revised")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "3" } }, [_vm._v("수정 내역이 없습니다.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "9%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Container"), _c("br"), _vm._v(" No.")]),
        _c("th", [_vm._v("Size")]),
        _c("th", [_vm._v("Type")]),
        _c("th", [_vm._v("특수"), _c("br"), _vm._v(" 화물")]),
        _c("th", [_vm._v("화주"), _c("br"), _vm._v(" 컨테이너")]),
        _c("th", [_vm._v("Seal No1.")]),
        _c("th", [_vm._v("Seal No2.")]),
        _c("th", [_vm._v("Seal No3.")]),
        _c("th", [_vm._v("Seal No4.")]),
        _c("th", [_vm._v("Seal No5.")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "11" } }, [_vm._v("수정 내역이 없습니다.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }