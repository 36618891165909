<template>
  <div class="popup_wrap" style="width:1000px;"><!--  popup_wrap  -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont" ><!-- popup_cont -->
    <h1 class="page_title">{{ $t('msg.ONIM010P010.001') }}</h1> <!-- B/L OOG Entry -->
    <div class="content_box"><!-- content_box -->
      <table class="tbl_col">
        <colgroup>
          <col width="19%">
          <col width="9%">
          <col width="9%">
          <col width="9%">
          <col width="9%">
          <col width="9%">
          <col width="9%">
          <col width="9%">
          <col width="9%">
          <col width="9%">
        </colgroup>
        <thead>
          <tr>
            <th rowspan="2">{{ $t('msg.ONIM010P010.002') }}</th> <!-- Container No. -->
            <th rowspan="2">{{ $t('msg.ONIM010P010.003') }}</th> <!-- Size -->
            <th rowspan="2">{{ $t('msg.ONIM010P010.004') }}</th> <!-- Type -->
            <th rowspan="2">{{ $t('msg.ONIM010P010.005') }}</th> <!-- Cargo Type -->
            <th rowspan="2">{{ $t('msg.ONIM010P010.006') }}</th> <!-- F/E -->
            <th colspan="5">{{ $t('msg.ONIM010P010.007') }}</th> <!-- Over(Cm) -->
          </tr>
          <tr>
            <th class="border_left">{{ $t('msg.ONIM010P010.008') }}</th> <!-- OH -->
            <th>{{ $t('msg.ONIM010P010.009') }}</th> <!-- OF -->
            <th>{{ $t('msg.ONIM010P010.010') }}</th> <!-- OB -->
            <th>{{ $t('msg.ONIM010P010.011') }}</th> <!-- OWP -->
            <th>{{ $t('msg.ONIM010P010.012') }}</th> <!-- OWS -->
          </tr>
        </thead>
        <tbody v-if="items.length > 0">
          <tr v-for="(item, index) in items" :key="index">
            <td>{{item.cntrNo}}</td>
            <td>{{item.cntrSzCd}}</td>
            <td>{{item.cntrTypCd}}</td>
            <td>{{item.cgoTypCd}}</td>
            <td>{{item.feCatCd}}</td>
            <td>{{item.ovrHght}}</td>
            <td>{{item.ovrFwdLen}}</td>
            <td>{{item.ovrBwrdLen}}</td>
            <td>{{item.ovrPortLen}}</td>
            <td>{{item.ovrStbdLen}}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10">{{ $t('msg.ONIM010P010.015') }}</td> <!-- No Data. -->
          </tr>
        </tbody>
      </table>
      <p class="txt_desc asterisk">{{ $t('msg.ONIM010P010.013') }}</p> <!-- 상기 정보는 참조용 이며, 정확한 정보는 선적지 SHIPPER 와 확인 바랍니다. -->
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <!--<button class="button blue lg">확인</button>-->
        <button class="button gray lg" @click="$emit('close')">{{ $t('msg.ONIM010P010.014') }}</button> <!-- 닫기 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import specialCgo from '@/api/rest/trans/specialCgo'

export default {
  name: 'OOGEntryPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: ''
        }
      }
    }
  },
  data () {
    return {
      items: [],
      formData: {
        blNo: '',
        flag: 'FR'
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.formData.blNo = this.parentInfo.blNo
      const result = await specialCgo.findSpecialCgoInfoList(this.formData)
      this.items = result.data
    }
  }
}
</script>
