import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  findSpecialCgoInfoList (data) {
    return Send({
      url: '/trans/special-cgo',
      method: 'get',
      params: data
    })
  }
}
