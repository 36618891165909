<template>
  <div class="container_ekmtc">
    <!-- container_ekmtc -->
    <div class="content">
      <!-- content -->
      <h1 class="page_title">팝업 리스트</h1>

      <win-layer-pop class="sample_popup_list">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="parentInfo"
        />
      </win-layer-pop>

      <div class="content_box" style="">
        <!-- content_box -->
        <table class="tbl_type">
          <colgroup>
            <col width="200">
            <col width="200">
            <col width="*">
            <col width="100">
            <col width="200">
          </colgroup>
          <thead>
            <tr>
              <th>팝업명</th>
              <th>URL</th>
              <th>필수 파라메터</th>
              <th></th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p class="temp_name">운송사 정보 리스트 팝업</p>
                <p>NEWB010P040(U0050P0)</p>
              </td>
              <td>/trans/bookings/transCompany</td>
              <td></td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('TransCompanyPop')">
                  팝업
                </button>
              </td>
              <td>@parentSelect= 자식function select시 부모 컴포넌트에서 매핑</td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">Pick Up 장소 리스트 팝업</p>
                <p>ONEX100P030(U0050P1)</p>
              </td>
              <td>/trans/pickup/findPickUpPlace</td>
              <td>
                <p class="p_params">
                  selectRow='선택한 Place Row No' <input type="text" class="input_txt" v-model="popupParams.PickUpSearchPop.selectRow" />
                </p>
                <p class="p_params">
                  cntrSize='컨테이너 사이즈'
                  <input type="text" class="input_txt" v-model="popupParams.PickUpSearchPop.cntrSize" />
                </p>
                <p class="p_params">
                  cntrType='컨테이너 타입'
                  <input type="text" class="input_txt" v-model="popupParams.PickUpSearchPop.cntrType" />
                </p>
                <p class="p_params">
                  polPortCd='항구 CODE'
                  <input type="text" class="input_txt" v-model="popupParams.PickUpSearchPop.polPortCd" />
                </p>
                <p class="p_params">
                  pickupDate='컨테이너 픽업날짜'
                  <input type="text" class="input_txt" v-model="popupParams.PickUpSearchPop.pickupDate" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('PickUpSearchPop')">
                  팝업
                </button>
              </td>
              <td>
                <p>@parentSelect= 자식function select시 부모 컴포넌트에서 매핑</p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">Pick up Place 선택 팝업</p>
                <p>NEWB020P020(U0050P1)</p>
              </td>
              <td>/trans/sr/pick-up-Place</td>
              <td>
                <p class="p_params">
                  selectRow='선택한 컨테이너 row No'
                  <input type="text" class="input_txt" v-model="popupParams.SrPickUpPlacePop.selectRow" />
                </p>
                <p class="p_params">hidPolCtrCode='부킹 지역 코드'</p>
                <p class="p_params">
                  cntrSize='컨테이너 사이즈'
                  <input type="text" class="input_txt" v-model="popupParams.SrPickUpPlacePop.cntrSize" />
                </p>
                <p class="p_params">
                  cntrType='컨테이너 타입'
                  <input type="text" class="input_txt" v-model="popupParams.SrPickUpPlacePop.cntrType" />
                </p>
                <p class="p_params">
                  pickUpDate='컨테이너 픽업날짜'
                  <input type="text" class="input_txt" v-model="popupParams.SrPickUpPlacePop.pickUpDate" />
                </p>
                <p class="p_params">
                  hidPolPortCode='부킹지역코드(세션)'
                  <span class="txt_fr">{{ this.memberDetail !== undefined && this.memberDetail.bkgPlcCd !== undefined ? this.memberDetail.bkgPlcCd : '로그인필요!!' }}</span>
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('SrPickUpPlacePop')">
                  팝업
                </button>
              </td>
              <td>
                <p>@parentSelect= 자식function select시 부모 컴포넌트에서 매핑</p>
                <p>부킹 지역 코드 세션에 따라 검색 리스트 가져오므로 테스트시에만 부킹지역코드(hidPolPortCode,세션) 입력값 사용</p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">위험물반입신고 요청서 팝업</p>
                <p>NEWB010P130</p>
              </td>
              <td>/trans/doc/insertDangerDoc</td>
              <td>
                <p class="p_params">
                  bkgNo='위험물이 포함된 부킹번호'
                  <input type="text" class="input_txt" v-model="popupParams.DangerDocReqPop.bkgNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click.prevent="openPopup('DangerDocReqPop')">
                  팝업
                </button>
              </td>
              <td>위험물반입신고 요청서는 bkgNo 기준</td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">DG/OOG Certificate 팝업</p>
                <p>NEWB020P030</p>
              </td>
              <td>/trans/bookings/dg-oog-certi</td>
              <td>
                <p class="p_params">
                  taskCatCd='업무 구분코드( B => Booking 등록 / S => SR 등록 )'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DgOogCertiPop.taskCatCd"
                  />
                </p>
                <p class="p_params">*********************************** type 분기 설명 ***********************************</p>
                <p class="p_params">Booking 등록 // DG/OOG 구분값( 'D' = DG / 'O' = OOG )</p>
                <p class="p_params">SR 등록 // 'SR' (SR 등록 시 DG(위험물) Certificate 파일만 첨부)</p>
                <p class="p_params">**************************************************************************************</p>
                <p class="p_params">
                  type
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DgOogCertiPop.type"
                  />
                </p>
                <p class="p_params">=============================== Booking 등록 ===============================</p>
                <p class="p_params">
                  reqRno='요청관리번호(첨부파일 키값)'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DgOogCertiPop.reqRno"
                  />
                </p>
                <p class="p_params">
                  count
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DgOogCertiPop.count"
                  />
                </p>
                <p class="p_params">
                  dgUpLoadFlag
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DgOogCertiPop.dgUpLoadFlag"
                  />
                </p>
                <p class="p_params">
                  bkgNo
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DgOogCertiPop.bkgNo"
                  />
                </p>
                <p class="p_params">
                  scgAppNo
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DgOogCertiPop.scgAppNo"
                  />
                </p>
                <p class="p_params">================================= SR 등록 =================================</p>
                <p class="p_params">
                  blNo
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DgOogCertiPop.blNo"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('DgOogCertiPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">VGM 정보 등록</p>
                <p>ONEX050G020</p>
              </td>
              <td>/trans/vgms</td>
              <td>
                <p class="p_params">
                  methCd
                  <input type="text" class="input_txt" v-model="popupParams.VgmPop.methCd" />
                </p>
                <p class="p_params">
                  type
                  <input type="text" class="input_txt" v-model="popupParams.VgmPop.type" />
                </p>
                <p class="p_params">
                  blNo
                  <input type="text" class="input_txt" v-model="popupParams.VgmPop.blNo" />
                </p>
                <p class="p_params">
                  uiFlag
                  <input type="text" class="input_txt" v-model="popupParams.VgmPop.uiFlag" />
                </p>
              </td>
              <td><button class="button blue lg mt10 layer_open" @click="openPopup('VgmPop')">팝업</button></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">ON DECK 선적 관련 안내 팝업</p>
                <p>NEWB010P140</p>
              </td>
              <td>/trans/bookings/oog-loi</td>
              <td>
                <p class="p_params">
                  kmdVslCheck='KMTC 모선여부'
                  <input type="text" class="input_txt" v-model="popupParams.OogLoiPop.kmdVslCheck" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('OogLoiPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">스케줄 조회 팝업</p>
                <p>NEWB010P030</p>
              </td>
              <td>/schedule/popup/ScheduleLegPop</td>
              <td>
                <p class="p_params">
                  polCtrCd='선적항 국가 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.ScheduleLegPop.polCtrCd"
                  />
                </p>
                <p class="p_params">
                  polPlcCd='선적항 지역 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.ScheduleLegPop.polPlcCd"
                  />
                </p>
                <p class="p_params">
                  podCtrCd='양하항 국가 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.ScheduleLegPop.podCtrCd"
                  />
                </p>
                <p class="p_params">
                  podPlcCd='양하항 지역 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.ScheduleLegPop.podPlcCd"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('ScheduleLegPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">스케쥴 구독 팝업</p>
                <p>SCHD010P030</p>
              </td>
              <td>/schedule/email-schedule</td>
              <td>
                <p class="p_params">
                  polCtrCd='선적항 국가 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.EmailSchedulePop.polCtrCd"
                  />
                </p>
                <p class="p_params">
                  polPlcCd='선적항 지역 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.EmailSchedulePop.polPlcCd"
                  />
                </p>
                <p class="p_params">
                  polPlcNm='선적항 지역 명'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.EmailSchedulePop.polPlcNm"
                  />
                </p>
                <p class="p_params">
                  podCtrCd='양하항 국가 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.EmailSchedulePop.podCtrCd"
                  />
                </p>
                <p class="p_params">
                  podPlcCd='양하항 지역 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.EmailSchedulePop.podPlcCd"
                  />
                </p>
                <p class="p_params">
                  podPlcNm='양하항 지역 명'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.EmailSchedulePop.podPlcNm"
                  />
                </p>
                <p class="p_params">
                  eiCatCd='수출입 구분 코드('O'=수출/'I'=수입)'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.EmailSchedulePop.eiCatCd"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('EmailSchedulePop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">스케쥴 Unno 검색 팝업</p>
                <p>SCHD010P020(U0120P0)</p>
              </td>
              <td>/trans/unno/{unno}</td>
              <td>
                <p class="p_params">
                  unno='unno(위험물 번호)'
                  <input type="text" class="input_txt" v-model="popupParams.UnnoPop.unno" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('UnnoPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">부킹 취소 사유 입력 팝업</p>
                <p>ONEX030P020(U0170)</p>
              </td>
              <td>/trans/bookings/booking-cancel-reason-popup</td>
              <td>
                <p class="p_params">
                  bkgNo='Booking No.'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingCancelPop.bkgNo"
                    placeholder="required"
                  />
                </p>
                <p class="p_params">
                  cboTerm='POL Term (01:CY, 02:CFS)'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingCancelPop.cboTerm"
                    placeholder="01:CY, 02:CFS"
                  />
                </p>
                <p class="p_params">
                  lclCancelYn='LCL 부킹 여부(미사용 예정)'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingCancelPop.lclCancelYn"
                    placeholder="Y/N"
                  />
                </p>
                <p class="p_params">
                  cancelChargeYn='태국 웹 부킹 취소 시 수수료발생 대상인 경우'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingCancelPop.cancelChargeYn"
                    placeholder="Y/N"
                  />
                </p>
                <p class="p_params">
                  overUi='선적확정 화면에서 부킹캔슬 시 플래그'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingCancelPop.overUi"
                    placeholder="선적확정인 경우 Y"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BookingCancelPop')">
                  팝업
                </button>
              </td>
              <td>
              </td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">컨테이너 정보 팝업</p>
                <p>NEWB010P050</p>
              </td>
              <td></td>
              <td></td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('ContainerPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">선박정보 및 스케줄</p>
                <p>SCHD010P040</p>
              </td>
              <td>/schedule/vsl-schedule-info</td>
              <td>
                <p class="p_params">
                  vslCd='선박 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.VesselScheduleInfoPop.vslCd"
                  />
                </p>
                <p class="p_params">
                  voyNo='항해 번호'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.VesselScheduleInfoPop.voyNo"
                  />
                </p>
                <p class="p_params">
                  polPortCd='선적항 항구 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.VesselScheduleInfoPop.polPortCd"
                  />
                </p>
                <p class="p_params">
                  polPortCd='양하항 항구 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.VesselScheduleInfoPop.podPortCd"
                  />
                </p>
                <p class="p_params">
                  bound='수출입 구분 코드('O'=수출/'I'=수입)'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.VesselScheduleInfoPop.bound"
                  />
                </p>
                <p class="p_params">
                  polTrmlCd='선적항 터미널 코드'
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.VesselScheduleInfoPop.polTrmlCd"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('VesselScheduleInfoPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>불러오기 팝업</td>
              <td>NEWB010P020</td>
              <td></td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BookingCallPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>불만수집 팝업</td>
              <td>NEWB010P150</td>
              <td>
                <p class="p_params">
                  docNo
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingVOCPop.docNo"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BookingVOCPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">RF 위험물 사전승인번호 선택 팝업</p>
                <p>NEWB020P020</p>
              </td>
              <td>
              </td>
              <td>
                <p class="p_params">
                  유저ID
                  <input type="text" class="input_txt" v-model="popupParams.RfDgRequestPop.userId" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('RfDgRequestPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">CA내역 조회 팝업</p>
                <p>ONEX060P020</p>
              </td>
              <td></td>
              <td>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.CaHistoryPop.blNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('CaHistoryPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">DEM/DET 계산기 팝업</td>
              <td>NEWB010P110</td>
              <td>
                <p class="p_params">
                  ctrCd=국가코드(KR,JP,IN..)
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DemDetCalculatorPop.ctrCd"
                  />
                </p>
                <p class="p_params">
                  portCd=포트코드(HZR,AXT..)
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DemDetCalculatorPop.portCd"
                  />
                </p>
                <p class="p_params">
                  eta=출항일(선적예정일)
                  <input
                    type="text"
                    class="input_txt"
                    placeholder="YYYY-MM-DD"
                    v-model="popupParams.DemDetCalculatorPop.etd"
                  />
                </p>
                <p class="p_params">
                  containerList.cgoTypCd=컨테이너 특수화물구분
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DemDetCalculatorPop.containerList[0].cgoTypCd"
                  />
                </p>
                <p class="p_params">
                  containerList.cntrQty=컨테이너 수량
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DemDetCalculatorPop.containerList[0].cntrQty"
                  />
                </p>
                <p class="p_params">
                  containerList.cntrSzCd=컨테이너 사이즈(20,40,45)
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DemDetCalculatorPop.containerList[0].cntrSzCd"
                  />
                </p>
                <p class="p_params">
                  containerList.cntrTypCd=컨테이너 타입(GP,SR..)
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.DemDetCalculatorPop.containerList[0].cntrTypCd"
                  />
                </p>
                <p class="p_params">
                  containerList.pcupReqDt=픽업예정일
                  <input
                    type="text"
                    class="input_txt"
                    placeholder="YYYY-MM-DD"
                    v-model="popupParams.DemDetCalculatorPop.containerList[0].pcupReqDt"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('DemDetCalculatorPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">사업자 등록번호 검색 팝업</td>
              <td>MYIN040P020</td>
              <td>
                <p class="p_params">
                  bsnNm=사업자명
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BusinessNumberPop.bsnNm"
                  />
                </p>
                <p class="p_params">
                  bzrgNo=사업자번호
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BusinessNumberPop.bzrgNo"
                  />
                </p>
                <p class="p_params">
                  cstNo=회사코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BusinessNumberPop.cstNo"
                  />
                </p>
                <p class="p_params">
                  addr=주소
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BusinessNumberPop.addr"
                  />
                </p>
                <p class="p_params">
                  faxNo=팩스
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BusinessNumberPop.faxNo"
                  />
                </p>
                <p class="p_params">
                  telNo=전번
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BusinessNumberPop.telNo"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BusinessNumberPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">운임 등록 요청 팝업</td>
              <td>ONEX010P060</td>
              <td>
              <p class="p_params">
                Bl No.
                <input
                  type="text"
                  class="input_txt"
                  v-model="popupParams.FareRegisReqPop.blNo"
                />
              </p>
              <p class="p_params">
                eiCatCd (수출/수입 여부)
                <input
                  type="text"
                  class="input_txt"
                  v-model="popupParams.FareRegisReqPop.eiCatCd"
                />
              </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('FareRegisReqPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">선적 로그 조회</td>
              <td>
                ONEX010T070,
                ONEX010T080
              </td>
              <td>
                <p class="p_params">
                  Booking No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.ShippingLogPop.bkgNo"
                  />
                </p>
                <p class="p_params">
                  B/L No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.ShippingLogPop.blNo"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('ShippingLogPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">선적 로그 상세(C/A DETAIL)</td>
              <td>ONEX010T090</td>
              <td>
                <p class="p_params">
                  blNo=B/L No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.ShippingLogBLDetailPop.blNo"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('ShippingLogBLDetailPop')">
                  팝업
                </button>
              </td>
              <td>
                <p>[TEST DATA]</p>
                <p>header: INC3335590</p>
                <p>leg: INC3335590</p>
                <p>body: PUSE000959</p>
                <p>freight: INC3335590</p>
                <p>container: PUSE013769</p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="temp_name">미정산 내역 팝업</p>
                <p>ONEX010P050</p>
              </td>
              <td>/trans/bl-payment/{blNo}/account-less</td>
              <td>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.BLPaymentAccountlessPop.blNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BLPaymentAccountlessPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">수출 담당자 팝업</td>
              <td>ONEX010P030</td>
              <td>
                <p class="p_params">
                  vslCd
                  <input type="text" class="input_txt" v-model="popupParams.ExportManagerPop.vslCd" />
                </p>
                <p class="p_params">
                  voyNo
                  <input type="text" class="input_txt" v-model="popupParams.ExportManagerPop.voyNo" />
                </p>
                <p class="p_params">
                  polPortCd
                  <input type="text" class="input_txt" v-model="popupParams.ExportManagerPop.polPortCd" />
                </p>
                <p class="p_params">
                  podPortCd
                  <input type="text" class="input_txt" v-model="popupParams.ExportManagerPop.podPortCd" />
                </p>
                <p class="p_params">
                  polTrmlCd
                  <input type="text" class="input_txt" v-model="popupParams.ExportManagerPop.polTrmlCd" />
                </p>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.ExportManagerPop.blNo" />
                </p>
                <p class="p_params">
                  D/O 발행상태
                  <input type="text" class="input_txt" v-model="popupParams.ExportManagerPop.doStatus" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('ExportManagerPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Free Time Print 팝업</td>
              <td>SETT060P050</td>
              <td>
                <p class="p_params">
                  blNo
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreeTimePrintPop.blNo"
                  />
                </p>
                <p class="p_params">
                  demDetCatCd
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreeTimePrintPop.demDetCatCd"
                  />
                </p>
                <p class="p_params">
                  eiCatCd
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreeTimePrintPop.eiCatCd"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('FreeTimePrintPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Office / Agent 팝업</td>
              <td>SETT060P070</td>
              <td>
                <p class="p_params">
                  portCd
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.OfficeAgentPop.portCd"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('OfficeAgentPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Free Time 연장요청</td>
              <td>SETT060P040</td>
              <td>
                <p class="p_params">
                  blNo
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreeTimeExtensReqPop.blNo"
                  />
                </p>
                <p class="p_params">
                  eiCatCd
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreeTimeExtensReqPop.eiCatCd"
                  />
                </p>
                <p class="p_params">
                  sr
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreeTimeExtensReqPop.sr"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('FreeTimeExtensReqPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">지도 팝업</td>
              <td>SCHD010P060</td>
              <td>
                <p class="p_params">
                  catCd - 업무 구분 코드(V=선박위치, P=항구위치)
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.PortLocationPop.catCd"
                    readonly="readonly"
                  />
                </p>
                <p class="p_params">
                  portCd - 포트 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.PortLocationPop.portCd"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('PortLocationPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Route 팝업</td>
              <td>SCHD010P041</td>
              <td>
                <p class="p_params">
                  vesselNm - 선박명
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.RoutePop.vesselNm"
                  />
                </p>
                <p class="p_params">
                  rteCd - 항로 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.RoutePop.rteCd"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('RoutePop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">세금계산서 Template</td>
              <td>ONEX070G050</td>
              <td>
                <p class="p_params">
                  tplRno - 템플릿 관리 번호(PK) - 수정 시에만 입력
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.TaxTemplatePop.tplRno"
                  />
                </p>
                <p class="p_params">
                  tplCatCd - 템플릿 구분 코드(PK)
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.TaxTemplatePop.tplCatCd"
                    readonly="readonly"
                  />
                </p>
                <p class="p_params">
                  templateNm - 템플릿 명
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.TaxTemplatePop.templateNm"
                  />
                </p>
                <p class="p_params">
                  rcivNm - 상호 명
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.TaxTemplatePop.rcivNm"
                  />
                </p>
                <p class="p_params">
                  rcivAddr - 주소
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.TaxTemplatePop.rcivAddr"
                  />
                </p>
                <p class="p_params">
                  rcivBzrgNo - 사업자 번호
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.TaxTemplatePop.rcivBzrgNo"
                  />
                </p>
                <p class="p_params">
                  rcivPicNm - 담당자
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.TaxTemplatePop.rcivPicNm"
                  />
                </p>
                <p class="p_params">
                  rcivCtfc - 연락처
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.TaxTemplatePop.rcivCtfc"
                  />
                </p>
                <p class="p_params">
                  emlAddr - E-Mail
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.TaxTemplatePop.emlAddr"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('TaxTemplatePop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Booking Combine Detail</td>
              <td></td>
              <td>
                <p class="p_params">
                  Booking No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingCombineDetailPop.bkgNo"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BookingCombineDetailPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Booking Split Detail</td>
              <td></td>
              <td>
                <p class="p_params">
                  Booking No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingSplitDetailPop.bkgNo"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BookingSplitDetailPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">운임확인(1) 팝업</td>
              <td>SCHD010P070</td>
              <td>
                <p class="p_params">
                  porCtrCd - 출발 국가 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.porCtrCd"
                  />
                </p>
                <p class="p_params">
                  porPlcCd - 출발 항구 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.porPlcCd"
                  />
                </p>
                <p class="p_params">
                  dlyCtrCd - 도착 국가 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.dlyCtrCd"
                  />
                </p>
                <p class="p_params">
                  dlyPlcCd - 도착 항구 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.dlyPlcCd"
                  />
                </p>
                <p class="p_params">
                  etd - 출항 일자
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.etd"
                  />
                </p>
                <p class="p_params">
                  frtAppNo - 운임 APPLICATION 번호
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.frtAppNo"
                  />
                </p>
                <p class="p_params">
                  frtEstnYn - 운임 견적 여부
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.frtEstnYn"
                  />
                </p>
                <!-- <p class="p_params">voyNo - 항차번호
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.voyNo"
                  />
                </p>
                <p class="p_params">rteCd - 항로 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.rteCd"
                  />
                </p> -->
                <p class="p_params">
                  eiCatCd - 수출입 구분
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.eiCatCd"
                  />
                </p>
                <p class="p_params">
                  frtResult - R/A No 조회결과
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.frtResult"
                  />
                </p>
                <p class="p_params">
                  reqRno - 요청 관리 번호
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.reqRno"
                  />
                </p>
                <p class="p_params">
                  bkgClose - 부킹 가능여부
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.bkgClose"
                  />
                </p>
                <p class="p_params">
                  raTsParam - T/S PARAMETER
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.raTsParam"
                  />
                </p>
                <p class="p_params">
                  polNm - 출발지명
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.polNm"
                  />
                </p>
                <p class="p_params">
                  podNm - 도착지명
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.podNm"
                  />
                </p>
                <p class="p_params">
                  startDt - 운임 시작일
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.startDt"
                  />
                </p>
                <p class="p_params">
                  endDt - 운임 종료일
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop.endDt"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('FreSurchargePop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">운임확인(2) 팝업</td>
              <td>SCHD010P071</td>
              <td>
                <p class="p_params">
                  porCtrCd - 출발 국가 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.porCtrCd"
                  />
                </p>
                <p class="p_params">
                  porPlcCd - 출발 항구 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.porPlcCd"
                  />
                </p>
                <p class="p_params">
                  dlyCtrCd - 도착 국가 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.dlyCtrCd"
                  />
                </p>
                <p class="p_params">
                  dlyPlcCd - 도착 항구 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.dlyPlcCd"
                  />
                </p>
                <p class="p_params">
                  etd - 출항 일자
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.etd"
                  />
                </p>
                <p class="p_params">
                  frtAppNo - 운임 APPLICATION 번호
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.frtAppNo"
                  />
                </p>
                <!-- <p class="p_params">vslCd - 선박 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.vslCd"
                  />
                </p>
                <p class="p_params">voyNo - 항차번호
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.voyNo"
                  />
                </p>
                <p class="p_params">rteCd - 항로 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.rteCd"
                  />
                </p> -->
                <p class="p_params">
                  eiCatCd - 수출입 구분
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.eiCatCd"
                  />
                </p>
                <p class="p_params">
                  frtResult - R/A No 조회결과
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.frtResult"
                  />
                </p>
                <p class="p_params">
                  reqRno - 요청 관리 번호
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.reqRno"
                  />
                </p>
                <p class="p_params">
                  bkgClose - 부킹 가능여부
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.bkgClose"
                  />
                </p>
                <p class="p_params">
                  raTsParam - T/S PARAMETER
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.raTsParam"
                  />
                </p>
                <p class="p_params">
                  polNm - 출발지명
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.polNm"
                  />
                </p>
                <p class="p_params">
                  podNm - 도착지명
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.podNm"
                  />
                </p>
              <!-- <p class="p_params">startDt - 운임 시작일
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.startDt"
                  />
                </p>
                <p class="p_params">endDt - 운임 종료일
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FreSurchargePop2.endDt"
                  />
                </p> -->
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('FreSurchargePop2')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">운임문의 팝업</td>
              <td>SCHD010P080</td>
              <td>
                <p class="p_params">
                  porCtrCd - 출발 국가 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.RequestQuotePop.porCtrCd"
                  />
                </p>
                <p class="p_params">
                  porPlcCd - 출발 항구 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.RequestQuotePop.porPlcCd"
                  />
                </p>
                <p class="p_params">
                  dlyCtrCd - 도착 국가 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.RequestQuotePop.dlyCtrCd"
                  />
                </p>
                <p class="p_params">
                  dlyPlcCd - 도착 항구 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.RequestQuotePop.dlyPlcCd"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('RequestQuotePop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">PortTerminalCY 팝업</td>
              <td>SCHD010P050</td>
              <td>
                <p class="p_params">
                  asPortCd - 지역 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.PortTerminalCYPop.asPortCd"
                  />
                </p>
                <p class="p_params">
                  icdPlcCd - ICD 지역 코드
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.PortTerminalCYPop.icdPlcCd"
                  />
                </p>
                <p class="p_params">
                  asSvcLang - 서비스 언어
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.PortTerminalCYPop.asSvcLang"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('PortTerminalCYPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">선명/항차 조회 팝업</td>
              <td>VoyVslSearchPop</td>
              <td>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('VoyVslSearchPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Vessel Inquiry 조회 팝업</td>
              <td>CSCT060G061</td>
              <td>
                <p class="p_params">
                  vslNm - 선박 명
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.VesselInquiryPop.vslNm"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('VesselInquiryPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Booking Cancel Fee 정산</td>
              <td>CSCT060G061</td>
              <td>
                <p class="p_params">
                  Booking No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingCancelFeePop.bkgNo"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BookingCancelFeePop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">선적 취소 요청서 팝업</td>
              <td>ONEX020P120</td>
              <td>
                <p class="p_params">
                  Bl No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BookingCancelRequestPop.blNo"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BookingCancelRequestPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">컨테이너 셔틀 요청서 팝업</td>
              <td>ONEX020P130</td>
              <td>
                <p class="p_params">
                  Bl No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.ContainerShuttlePop.blNo"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('ContainerShuttlePop')">
                  팝업
                </button>
              </td>
              <td>[TEST] blNo: BLW0006494A (컨테이너 여러개)</td>
            </tr>
            <tr>
              <td class="temp_name">COD 요청서 팝업</td>
              <td>ONEX010P070</td>
              <td>
                <p class="p_params">
                  Bl No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.CodRequestPop.blNo"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('CodRequestPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Full 컨테이너 반출 요청서 팝업</td>
              <td>ONEX010P095</td>
              <td>
                <p class="p_params">
                  Booking No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.FullContainerOutReqPop.bkgNo"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('FullContainerOutReqPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">제3국 소재 Shipper LOI 첨부 팝업</td>
              <td>ONEX050P190</td>
              <td>
                <p class="p_params">
                  BL No. (없으면 SR No)
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.IndemnityReqPop.blNo"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('IndemnityReqPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">컨테이너 번호변경 요청서 팝업</td>
              <td>ONEX010P100</td>
              <td>
                <p class="p_params">
                  BL No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.CntrNoCorrectReqPop.blNo"
                  />
                </p>
              </td><td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('CntrNoCorrectReqPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Shipback 요청서 팝업</td>
              <td>ONEX010P080</td>
              <td>
                <p class="p_params">
                  BL No.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.ShipbackReqPop.blNo"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('ShipbackReqPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">수입 업무 담당자 팝업</td>
              <td>ONIM010P020</td>
              <td>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.ImportManagerPop.blNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('ImportManagerPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">출발지/도착지 스케줄 정보</td>
              <td>ONEX010P040</td>
              <td>
                <p class="p_params">
                  Booking No.
                  <input type="text" class="input_txt" v-model="popupParams.PorDlySchedulePop.bkgNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('PorDlySchedulePop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">선적지 Surrender 문의 팝업</td>
              <td>ONIM050P020</td>
              <td>
                <p class="p_params">
                  Bl No.
                  <input type="text" class="input_txt" v-model="popupParams.SurrenderReqEmailPop.blNo" />
                </p>
                <p class="p_params">
                  voyNo
                  <input type="text" class="input_txt" v-model="popupParams.SurrenderReqEmailPop.voyNo" />
                </p>
                <p class="p_params">
                  vslNm
                  <input type="text" class="input_txt" v-model="popupParams.SurrenderReqEmailPop.vslNm" />
                </p>
                <p class="p_params">
                  podPortCd
                  <input type="text" class="input_txt" v-model="popupParams.SurrenderReqEmailPop.podPortCd" />
                </p>
                <p class="p_params">
                  surrEmailyn
                  <input type="text" class="input_txt" v-model="popupParams.SurrenderReqEmailPop.surrEmailyn" />
                </p>
                <p class="p_params">
                  doStsCd
                  <input type="text" class="input_txt" v-model="popupParams.SurrenderReqEmailPop.doStsCd" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('SurrenderReqEmailPop')">팝업</button>
              </td>
              <td>[As-is]surrEmailyn가 Y이거나(이미 Surrender문의를 했거나) doStsCd가 1 또는 2(B/L발행, 접수처리)일 경우 Surrender 문의를 할 수 없음.</td>
            </tr>
            <tr>
              <td class="temp_name">O B/L 재발행 팝업</td>
              <td>ONEX070G110</td>
              <td>
                <p class="p_params">
                  kind
                  <input type="text" class="input_txt" v-model="popupParams.OriginalBLReIssuePop.kind" />
                </p>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.OriginalBLReIssuePop.blNo" />
                </p>
                <p class="p_params">
                  reqCatCd
                  <input type="text" class="input_txt" v-model="popupParams.OriginalBLReIssuePop.reqCatCd" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('OriginalBLReIssuePop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">B/L 첨부파일 팝업</td>
              <td>ONEX050P170</td>
              <td>
                <p class="p_params">
                  type
                  <input type="text" class="input_txt" v-model="popupParams.BLAttachPop.type" />
                </p>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.BLAttachPop.blNo" />
                </p>
                <p class="p_params">
                  도착지 (이란인 경우 'IR' 기입)
                  <input type="text" class="input_txt" v-model="popupParams.BLAttachPop.podCtrCd" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BLAttachPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">SHA M/F Data 팝업</td>
              <td>ONEX050P060</td>
              <td>
                <p class="p_params">
                  type
                  <input type="text" class="input_txt" v-model="popupParams.ShaMfDataPop.type" />
                </p>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.ShaMfDataPop.blNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('ShaMfDataPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Payment 등록 팝업</td>
              <td>ONIM099P060</td>
              <td>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.PaymentRegPop.blNo" />
                </p>
                <p class="p_params">
                  linkPayNo
                  <input type="text" class="input_txt" v-model="popupParams.PaymentRegPop.linkPayNo" />
                </p>
                <p class="p_params">
                  linkYn
                  <input type="text" class="input_txt" v-model="popupParams.PaymentRegPop.linkYn" />
                </p>
                <p class="p_params">
                  payStsCd
                  <input type="text" class="input_txt" v-model="popupParams.PaymentRegPop.payStsCd" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('PaymentRegPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">상해 부킹 Cancel 팝업</td>
              <td>ONEX020P070</td>
              <td>
                <p class="p_params">
                  Booking No.
                  <input type="text" class="input_txt" v-model="popupParams.SHASRCancelPop.bkgNo" />
                </p>
                <p class="p_params">
                  S/R No.
                  <input type="text" class="input_txt" v-model="popupParams.SHASRCancelPop.srRno" />
                </p>
                <p class="p_params">
                  출발항 코드
                  <input type="text" class="input_txt" v-model="popupParams.SHASRCancelPop.polPortCd" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('SHASRCancelPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">DG Entry 팝업</td>
              <td>ONIM010P080</td>
              <td>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.DGEntryPop.blNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('DGEntryPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">OOG Entry 팝업</td>
              <td>ONIM010P090</td>
              <td>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.OOGEntryPop.blNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('OOGEntryPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">RF Entry 팝업</td>
              <td>ONIM010P010</td>
              <td>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.RFEntryPop.blNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('RFEntryPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">긴급양하 신청 팝업</td>
              <td>ONIM010P070</td>
              <td>
                <p class="p_params">
                  B/L No. (blNo)
                  <input type="text" class="input_txt" v-model="popupParams.EmergencyShippingReqPop.blNo" />
                </p>
                <p class="p_params">
                  Port Cd (portCd)
                  <input type="text" class="input_txt" v-model="popupParams.EmergencyShippingReqPop.portCd" />
                </p>
                <p class="p_params">
                  수출입 구분 코드 (eiCatCd)
                  <input type="text" class="input_txt" v-model="popupParams.EmergencyShippingReqPop.eiCatCd" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('EmergencyShippingReqPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">B/L 발행 팝업</td>
              <td>ONEX070G040</td>
              <td>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.BLPaymentPop.blNoArr" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BLPaymentPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">D/O 발행 팝업</td>
              <td>ONIM099P010</td>
              <td>
                <p class="p_params">
                  B/L No.
                  <input type="text" class="input_txt" v-model="popupParams.DOPaymentPop.blNoArr" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('DOPaymentPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">C/A 요청 팝업 (B/L 수정)</td>
              <td>ONEX060P012</td>
              <td>
                <p class="p_params">
                  B/L No. (blNo)
                  <input type="text" class="input_txt" v-model="popupParams.CARequestPop.blNo" />
                </p>
                <p class="p_params">
                  B/L 발생 여부 (blIssYn)
                  <input type="text" class="input_txt" v-model="popupParams.CARequestPop.blIssYn" />
                </p>
                <p class="p_params">
                  Surrender 여부 (surrYn)
                  <input type="text" class="input_txt" v-model="popupParams.CARequestPop.surrYn" />
                </p>
                <p class="p_params">
                  MF 마감 여부 (mfClosDtYn)
                  <input type="text" class="input_txt" v-model="popupParams.CARequestPop.mfClosDtYn" />
                </p>
                <p class="p_params">
                  임시 C/A No. (tempCaNo)
                  <input type="text" class="input_txt" v-model="popupParams.CARequestPop.tempCaNo" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('CARequestPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">이란 업로드 팝업</td>
              <td></td>
              <td>
                <p class="p_params">
                  요청 관리 번호 (reqRno)
                  <input type="text" class="input_txt" v-model="popupParams.IranUploadPop.reqRno" />
                </p>
                <p class="p_params">
                  Container 순번 (count)
                  <input type="text" class="input_txt" v-model="popupParams.IranUploadPop.count" />
                </p>
                <p class="p_params">
                  업로드 타입 (type)
                  <input type="text" class="input_txt" v-model="popupParams.IranUploadPop.type" />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('IranUploadPop')">팝업</button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">Surrend Request 팝업</td>
              <td>ONEX070G090</td>
              <td>
                <p class="p_params">
                  Bl No Array
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.SurrenderRequestPop.blNoArr"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('SurrenderRequestPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="temp_name">BLContainerCgoPop</td>
              <td></td>
              <td>
                <p class="p_params">
                  Booking no.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BLContainerCgoPop.bkgNo"
                  />
                </p>
                <p class="p_params">
                  B/L no.
                  <input
                    type="text"
                    class="input_txt"
                    v-model="popupParams.BLContainerCgoPop.blNo"
                  />
                </p>
              </td>
              <td>
                <button class="button blue lg mt10 layer_open" @click="openPopup('BLContainerCgoPop')">
                  팝업
                </button>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'

/* trans */
import OogLoiPop from '@/pages/trans/popup/OogLoiPop'/* ON DECK 선적 관련 안내 팝업 */
import DgOogCertiPop from '@/pages/trans/popup/DgOogCertiPop'/* DG/OOG Certificate 팝업 */
import SrPickUpPlacePop from '@/pages/trans/popup/SrPickUpPlacePop'/* Pick up Place 선택 팝업 */
import UnnoPop from '@/pages/trans/popup/UnnoPop'/* 스케쥴 Unno 검색 팝업 */
import BookingCancelPop from '@/pages/trans/popup/BookingCancelPop' /* 부킹취소 팝업 */
import PickUpSearchPop from '@/pages/trans/popup/PickUpSearchPop'/* Pick up Place 선택 팝업 */
import TransCompanyPop from '@/pages/trans/popup/TransCompanyPop'/* 운송사 선택 팝업 */
import ContainerPop from '@/pages/trans/popup/ContainerPop'/* 컨테이너 정보 팝업 */
import DangerDocReqPop from '@/pages/trans/popup/DangerDocReqPop'/* 위험물반입신고 요청서 팝업 */
import BookingVOCPop from '@/pages/trans/popup/BookingVOCPop' /* 불만수집 팝업 */
import BookingCallPop from '@/pages/trans/popup/BookingCallPop' /* 불러오기 팝업 */
import CaHistoryPop from '@/pages/trans/popup/CaHistoryPop'/* CA HISTORY 팝업 */
import DemDetCalculatorPop from '@/pages/trans/popup/DemDetCalculatorPop'/* DEM/DET 계산기 팝업 */
import RfDgRequestPop from '@/pages/trans/popup/RfDgRequestPop'/* RF 위험물 사전승인번호 선택 팝업 */
import FareRegisReqPop from '@/pages/trans/popup/FareRegisReqPop'/* 운임 등록 요청 팝업 */
import ShippingLogPop from '@/pages/trans/popup/ShippingLogPop'
import BLPaymentAccountlessPop from '@/pages/trans/popup/BLPaymentAccountlessPop'/* 미정산 내역 팝업 */
import ShippingLogBLDetailPop from '@/pages/trans/popup/ShippingLogBLDetailPop' /* 선적로그 상세(C/A DETAIL) 팝업 */
import ExportManagerPop from '@/pages/trans/popup/ExportManagerPop'/* 수출 담당자 팝업 */
import VgmPop from '@/pages/trans/popup/VgmPop'/* Vgm 팝업 */
import TaxTemplatePop from '@/pages/trans/popup/TaxTemplatePop'/* Vgm 팝업 */
import BookingCombineDetailPop from '@/pages/trans/popup/BookingCombineDetailPop'/* Booking Combine 팝업 */
import BookingSplitDetailPop from '@/pages/trans/popup/BookingSplitDetailPop'/* Booking Split 팝업 */
import BookingCancelFeePop from '@/pages/trans/popup/BookingCancelFeePop'/* Booking Cancel Fee 팝업 */
import BookingCancelRequestPop from '@/pages/trans/popup/BookingCancelRequestPop'/* 선적 취소 요청서 팝업 */
import ContainerShuttlePop from '@/pages/trans/popup/ContainerShuttlePop'/* 컨테이너 셔틀 요청서 팝업 */
import CodRequestPop from '@/pages/trans/popup/CodRequestPop'/* COD 요청서 팝업 */
import FullContainerOutReqPop from '@/pages/trans/popup/FullContainerOutReqPop'/* Full 컨테이너 반출 요청서 팝업 */
import IndemnityReqPop from '@/pages/trans/popup/IndemnityReqPop'/* 제3국 소재 Shipper LOI 첨부 팝업 */
import ImportManagerPop from '@/pages/trans/popup/ImportManagerPop'/* 수입 업무 담당자 팝업 */
import CntrNoCorrectReqPop from '@/pages/trans/popup/CntrNoCorrectReqPop'/* 컨테이너 번호변경 요청서 팝업 */
import ShipbackReqPop from '@/pages/trans/popup/ShipbackReqPop'/* Shipback 요청서 팝업 */
import PorDlySchedulePop from '@/pages/trans/popup/PorDlySchedulePop'/* 출발지/도착지 스케줄 정보 */
import SurrenderReqEmailPop from '@/pages/trans/popup/SurrenderReqEmailPop'/* 선적지 Surrender 문의 Alert 팝업 */
import OriginalBLReIssuePop from '@/pages/trans/popup/OriginalBLReIssuePop'/* O B/L 재발행 팝업 */
import BLAttachPop from '@/pages/trans/popup/BLAttachPop'/* B/L 첨부파일 팝업 */
import ShaMfDataPop from '@/pages/trans/popup/ShaMfDataPop'/* SHA M/F Data 팝업 */
import PaymentRegPop from '@/pages/trans/popup/PaymentRegPop'/* Payment 등록 팝업 */
import SHASRCancelPop from '@/pages/trans/popup/SHASRCancelPop'/* SHA 부킹 캔슬 팝업 */
import DGEntryPop from '@/pages/trans/popup/DGEntryPop'/* DG Entry 팝업 */
import OOGEntryPop from '@/pages/trans/popup/OOGEntryPop'/* OOG Entry 팝업 */
import RFEntryPop from '@/pages/trans/popup/RFEntryPop'/* RF Entry 팝업 */
import EmergencyShippingReqPop from '@/pages/trans/popup/EmergencyShippingReqPop'/* 긴급양하 신청 팝업 */
import BLPaymentPop from '@/pages/trans/popup/BLPaymentPop'/* B/L발행 팝업 */
import DOPaymentPop from '@/pages/trans/popup/DOPaymentPop'/* D/O발행 팝업 */
import CARequestPop from '@/pages/trans/popup/CARequestPop'/* C/A 요청 팝업 (B/L 수정) */
import IranUploadPop from '@/pages/trans/popup/IranUploadPop'/* 이란 업로드 팝업 */
import SurrenderRequestPop from '@/pages/trans/popup/SurrenderRequestPop'/* Surrender 요청서 팝업 */

/* schedule */
import ScheduleLegPop from '@/pages/schedule/popup/ScheduleLegPop'/* 스케줄 조회 팝업 */
import EmailSchedulePop from '@/pages/schedule/popup/EmailSchedulePop'/* 스케쥴 구독 팝업 */
import VesselScheduleInfoPop from '@/pages/schedule/popup/VesselScheduleInfoPop'/* 선박정보 및 스케줄 팝업 */
import PortLocationPop from '@/pages/schedule/popup/PortLocationPop'/* 지도 팝업 */
import RoutePop from '@/pages/schedule/popup/RoutePop'/* Route 팝업 */
import BusinessNumberPop from '@/pages/user/popup/BusinessNumberPop'/* 선박정보 및 스케줄 팝업 */
import FreSurchargePop from '@/pages/schedule/popup/FreSurchargePop'/* 운임확인(1) 팝업 */
import FreSurchargePop2 from '@/pages/schedule/popup/FreSurchargePop2'/* 운임확인(2) 팝업 */
import RequestQuotePop from '@/pages/schedule/popup/RequestQuotePop'/* 운임문의 팝업 */
import PortTerminalCYPop from '@/pages/schedule/popup/PortTerminalCYPop'/* PortTerminalCYPop 팝업 */
import VoyVslSearchPop from '@/pages/schedule/popup/VoyVslSearchPop'/* VoyVslSearchPop 팝업 */

import FreeTimePrintPop from '@/pages/settle/FreeTimePrintPop'/* Free Time Print 팝업 */
import OfficeAgentPop from '@/pages/settle/OfficeAgentPop'/* Office/Agent 팝업 */
import FreeTimeExtensReqPop from '@/pages/settle/popup/FreeTimeExtensReqPop'/* Free Time 연장요청 팝업 */

/* common */
import VesselInquiryPop from '@/pages/common/popup/VesselInquiryPop'/* Vessel Inquiry 조회 팝업 */

export default {
    name: 'SamplePopUpList',
    components: {
      VgmPop,
      ScheduleLegPop,
      EmailSchedulePop,
      OogLoiPop,
      DgOogCertiPop,
      WinLayerPop,
      SrPickUpPlacePop,
      UnnoPop,
      PickUpSearchPop,
      TransCompanyPop,
      ContainerPop,
      BookingCancelPop,
      DangerDocReqPop,
      VesselScheduleInfoPop,
      BookingCallPop,
      BookingVOCPop,
      CaHistoryPop,
      DemDetCalculatorPop,
      RfDgRequestPop,
      BusinessNumberPop,
      FareRegisReqPop,
      ShippingLogPop,
      BLPaymentAccountlessPop,
      ShippingLogBLDetailPop,
      ExportManagerPop,
      FreeTimePrintPop,
      OfficeAgentPop,
      FreeTimeExtensReqPop,
      PortLocationPop,
      RoutePop,
      FreSurchargePop,
      FreSurchargePop2,
      RequestQuotePop,
      PortTerminalCYPop,
      VoyVslSearchPop,
      TaxTemplatePop,
      BookingCombineDetailPop,
      BookingSplitDetailPop,
      VesselInquiryPop,
      BookingCancelFeePop,
      BookingCancelRequestPop,
      ContainerShuttlePop,
      ImportManagerPop,
      CodRequestPop,
      FullContainerOutReqPop,
      IndemnityReqPop,
      CntrNoCorrectReqPop,
      ShipbackReqPop,
      PorDlySchedulePop,
      SurrenderReqEmailPop,
      OriginalBLReIssuePop,
      BLAttachPop,
      ShaMfDataPop,
      PaymentRegPop,
      SHASRCancelPop,
      DGEntryPop,
      OOGEntryPop,
      RFEntryPop,
      EmergencyShippingReqPop,
      BLPaymentPop,
      DOPaymentPop,
      CARequestPop,
      IranUploadPop,
      SurrenderRequestPop,
      BLContainerCgoPop: () => import('@/pages/trans/popup/BLContainerCgoPop')
    },
    data () {
      return {
        parentInfo: {},
        customComponent: null,
        popupParams: {
          PickUpSearchPop: {
            selectRow: '1',
            cntrSize: '20',
            cntrType: 'GP',
            polPortCd: 'PUS',
            pickupDate: '20210915',
            cyCd: ''
          },
          SrPickUpPlacePop: {
            selectRow: '0',
            cntrSize: '',
            cntrType: '',
            pickUpDate: '20210701',
            hidPolPortCode: 'TAO'
          },
          UnnoPop: {
            unno: '1975',
            cntrTypCd: 'GP'
          },
          ScheduleLegPop: {
            ScheduleLegPop: 'Y',
            polCtrCd: 'KR',
            polPlcCd: 'INC',
            podCtrCd: 'JP',
            podPlcCd: 'NGO',
            rfYn: 'Y'
          },
          EmailSchedulePop: {
            polCtrCd: 'KR',
            polPlcCd: 'INC',
            polPlcNm: 'Incheon',

            podCtrCd: 'HK',
            podPlcCd: 'HKG',
            podPlcNm: 'Hong Kong',

            eiCatCd: 'O'
          },
          BookingCancelPop: {
            bkgNo: 'KR03065053',
            cboTerm: '01',
            lclCancelYn: 'N',
            cancelChargeYn: '',
            overUi: ''
          },
          DgOogCertiPop: {
            taskCatCd: 'S', /* 업무 구분코드 ( B>Booking 등록 / S=>SR 등록 ) */
            // type 분기 설명
            // Booking 등록 => DG/OOG 구분값( 'D' = DG / 'O' = OOG )
            // SR 등록 => 'SR' (SR 등록 시 DG(위험물) Certificate 파일만 첨부)
            type: 'SR',
            // ==================== Booking 등록 ====================
            reqRno: '162838',
            count: 1,
            dgUpLoadFlag: '',
            bkgNo: '',
            scgAppNo: '',
            // ======================= SR 등록 =======================
            blNo: 'PUS2197968'
          },
          OogLoiPop: {
            kmdVslCheck: 'Y'
          },
          DangerDocReqPop: {
            bkgNo: 'KR02955367'
          },
          VesselScheduleInfoPop: {
            vslCd: 'JSOR',
            voyNo: '2111S',
            polPortCd: 'PUS',
            podPortCd: 'HKG',
            bound: 'O',
            polTrmlCd: 'HBCT'
          },
          VgmPop: {
            blNo: 'PUSE013769',
            methCd: '03',
            type: 'BL',
            uiFlag: 'SR'
          },
          DangerDocData: {
            bkgNo: 'KR02955367',
            kind: 'BK'
          },
          BookingVOCPop: {
            docNo: 'KR0002'
          },
          CaHistoryPop: {
            blNo: 'PUSE518590'
          },
          DemDetCalculatorPop: {
            containerList: [
              {
                cgoTypCd: '',
                cntrQty: '2',
                cntrSzCd: '20',
                cntrTypCd: 'GP',
                pcupReqDt: '2021-11-20'
              }
            ],
            etd: '2021-11-25',
            ctrCd: 'JP',
            portCd: 'AXT'
          },
          RfDgRequestPop: {
            userId: '로그인한 사용자'
          },
          BusinessNumberPop: {
            bsnNm: '',
            bzrgNo: '',
            cstNo: '',
            addr: '',
            faxNo: '',
            telNo: ''
          },
          ShippingLogPop: {
            bkgNo: 'KR02955367',
            blNo: 'INC3335590'
          },
          ShippingLogBLDetailPop: {
            blNo: 'INC3335590' //INC3335590, PUSE013769, INC3335590, PUSE013769
          },
          BLPaymentAccountlessPop: {
            blNo: 'PUS705974'
          },
          FreeTimePrintPop: {
            blNo: 'INC3421106',
            demDetCatCd: '01',
            eiCatCd: 'O'
          },
          OfficeAgentPop: {
            portCd: 'CHB'
          },
          FreeTimeExtensReqPop: {
            blNo: 'INC3421106',
            eiCatCd: 'O',
            sr: '1'
          },
          ExportManagerPop: {
            blNo: 'SHAH660150',
            vslCd: 'JSOR',
            voyNo: '2111S',
            polPortCd: 'PUS',
            podPortCd: 'HKG',
            bound: 'O',
            polTrmlCd: 'HBCT',
            anStatus: 'N'
          },
          PortLocationPop: {
            catCd: 'P',
            portCd: 'TKD'
          },
          RoutePop: {
            rteCd: 'KST',
            vesselNm: 'KMTC NAGOYA'
          },
          FreSurchargePop: {
            porCtrCd: 'KR',
            porPlcCd: 'PUS',
            dlyCtrCd: 'HK',
            dlyPlcCd: 'HKG',
            polNm: 'BUSAN,KOREA',
            podNm: 'HONG KONG',
            startDt: '20211018',
            endDt: '20211117',
            etd: '20211029',
            frtAppNo: 'KR0653477',
            vslCd: 'KTO',
            voyNo: '2120S',
            rteCd: 'KVX',
            eiCatCd: 'O',
            frtResult: 'Y',
            reqRno: '',
            bkgClose: 'N',
            raTsParam: '',
            frtEstnYn: 'Y'
          },
          FreSurchargePop2: {
            porCtrCd: 'KR',
            porPlcCd: 'INC',
            dlyCtrCd: 'JP',
            dlyPlcCd: 'NGO',
            polNm: 'INCHEON,KOREA',
            podNm: 'NAGOYA,JAPAN',
            startDt: '20211018',
            endDt: '20211117',
            etd: '20211027',
            frtAppNo: '',
            vslCd: 'KTO',
            vslNm: 'KOTA TAMPAN',
            voyNo: '2129E',
            rteCd: 'JEK4',
            eiCatCd: 'O',
            frtResult: 'A',
            reqRno: '000000014716',
            bkgClose: 'N',
            raTsParam: ''
          },
          RequestQuotePop: {
            porCtrCd: 'KR',
            porPlcCd: 'INC',
            dlyCtrCd: 'JP',
            dlyPlcCd: 'NGO'
          },
          PortTerminalCYPop: {
            asPortCd: 'PUS',
            icdPlcCd: 'BGK',
            asSvcLang: '01'
          },
          VoyVslSearchPop: {
          },
          TaxTemplatePop: {
            tplRno: 'KMTCAIR|20210719170833',
            tplCatCd: '07',
            templateNm: 'CDF',
            rcivNm: '주성씨앤에어(주)',
            rcivAddr: '서울특별시 중구 필동로 47 3층(필동3가, 주성빌딩)',
            rcivBzrgNo: '104812612739',
            rcivPicNm: '1058150707이혜진',
            rcivCtfc: '027889610',
            emlAddr: ''
          },
          BookingCombineDetailPop: {
            bkgNo: 'KR02955607'
          },
          BookingSplitDetailPop: {
            bkgNo: 'KR02955607'
          },
          VesselInquiryPop: {
            vslNm: ''
          },
          BookingCancelFeePop: {
            bkgNo: 'KR03152171',
            containerList: [
              {
                cntrNo: 'KMTU392092',
                cgoTypCd: '',
                cntrQty: '2',
                cntrSzCd: '20',
                cntrTypCd: 'GP',
                pcupReqDt: '2021-11-20'
              },
              {
                cntrNo: 'KMTU392093',
                cgoTypCd: '',
                cntrQty: '2',
                cntrSzCd: '20',
                cntrTypCd: 'GP',
                pcupReqDt: '2021-11-20'
              }
            ]
          },
          BookingCancelRequestPop: {
            blNo: 'BLW0006494A'
          },
          ContainerShuttlePop: {
            blNo: 'PUSE039143'
          },
          ImportManagerPop: {
            blNo: 'AMD0004017'
          },
          CodRequestPop: {
            blNo: 'PUSE039143'
          },
          FullContainerOutReqPop: {
            bkgNo: 'KR03161289'
          },
          IndemnityReqPop: {
            blNo: 'PUSE029133'
          },
          CntrNoCorrectReqPop: {
            blNo: 'PUSE039143'
          },
          ShipbackReqPop: {
            blNo: 'INC701165'
          },
          PorDlySchedulePop: {
            bkgNo: 'KR02955595',
            kind: '02'
          },
          SurrenderReqEmailPop: {
            blNo: 'PUSE015822',
            voyNo: '2110S',
            vslNm: 'KMTC JAKARTA',
            podPortCd: 'HKG',
            surrEmailyn: '',
            doStsCd: ''
          },
          OriginalBLReIssuePop: {
            kind: 'BL',
            blNo: 'PUSE015822',
            reqCatCd: '08'
          },
          BLAttachPop: {
            type: 'BL',
            blNo: 'PUSE015931',
            podCtrCd: 'IR'
          },
          ShaMfDataPop: {
            // type: 'BL',
            // blNo: 'PUS703229'
            type: 'SR',
            blNo: 'PUSE021872'
          },
          PaymentRegPop: {
            blNo: 'SHAG933264',
            linkPayNo: '',
            linkYn: '',
            payStsCd: ''
          },
          SHASRCancelPop: {
            bkgNo: 'CN04497402',
            srRno: 'SHAH166626',
            polPortCd: 'SHA'
          },
          FareRegisReqPop: {
            blNo: 'PUSE021946',
            eiCatCd: 'E'
          },
          DGEntryPop: {
            blNo: 'UKB0349476'
          },
          OOGEntryPop: {
            blNo: 'YOK0500297'
          },
          RFEntryPop: {
            blNo: 'SHAG492171'
          },
          EmergencyShippingReqPop: {
            blNo: 'HPH0849697',
            portCd: 'INC',
            eiCatCd: 'I'
          },
          BLPaymentPop: {
            dpId: '',
            dmdCatCd: 'R',
            //dmdCatCd: 'R', //화주구분 : 말화주(M), 청구화주(C), 영수화주(R)
            btnType: 'BL', //FREIGHT: 운임수납, TAX_INVOICE: 계산서발행, BL: BL 발행
            hasNotPaid: true, //미정산 금액 존재여부
            hasNotTaxInvoice: false, //세금계산서 미발행 건 존재 여부
            blList: [
              {
                blNo: 'PUSE022727',
                krw: 5000,
                usd: 0,
                exRate: 1.2,
                currency: 'KRW+USD',
                obrdDt: '', //TODO
                eiCatCd: 'O',
                cntrList: []
              },
              {
                blNo: 'PUSE024987',
                krw: 5000,
                usd: 0,
                exRate: 1.5,
                currency: 'KRW+USD',
                obrdDt: '', //TODO
                eiCatCd: 'O',
                cntrList: []
              }
            ]
          },
          DOPaymentPop: {
            dpId: '',
            dmdCatCd: 'R',
            //dmdCatCd: 'R', //화주구분 : 말화주(M), 청구화주(C), 영수화주(R)
            btnType: 'BL', //FREIGHT: 운임수납, TAX_INVOICE: 계산서발행, BL: BL 발행
            hasNotPaid: true, //미정산 금액 존재여부
            hasNotTaxInvoice: false, //세금계산서 미발행 건 존재 여부
            blList: [
              {
                blNo: 'PUSE022727',
                krw: 5000,
                usd: 0,
                exRate: 1.2,
                currency: 'KRW+USD',
                obrdDt: '', //TODO
                eiCatCd: 'O',
                cntrList: []
              },
              {
                blNo: 'PUSE024987',
                krw: 5000,
                usd: 0,
                exRate: 1.5,
                currency: 'KRW+USD',
                obrdDt: '', //TODO
                eiCatCd: 'O',
                cntrList: []
              }
            ]
          },
          CARequestPop: {
            blNo: 'PUSE017894',
            blIssYn: 'Y',
            surrYn: 'N',
            mfClosDtYn: 'N',
            tempCaNo: ''
          },
          IranUploadPop: {
            reqRno: 'KR03161492',
            count: 0,
            type: '01'
          },
          SurrenderRequestPop: {
            blNoArr: ['PUSE039143']
          },
          BLContainerCgoPop: {
            blNo: 'PUSF194174',
            bkgNo: 'KR03224482',
            modifyFlag: 'I',
            type: 'BL'
          }
        }
      }
    },
    computed: {
      ...rootComputed
    },
    methods: {
      // param
      // compNm : Import한 컴포넌트 명
      openPopup (compNm) {
        if (this.customComponent !== null) {
          this.customComponent = null
          this.$ekmtcCommon.layerClose()
        }

        this.parentInfo = this.popupParams[compNm]
        console.log('=====================================')
        console.log(compNm)
        console.log('-------------------------------------')
        console.log(this.parentInfo)
        console.log('-------------------------------------')
        this.customComponent = compNm

        this.$ekmtcCommon.layerOpen('.sample_popup_list')
      },
      closePopup (obj) {
        console.log('======= closePopup ==============================')
        console.log(obj)
        if (this.customComponent === 'BusinessNumberPop') {
          this.popupParams.BusinessNumberPop.bsnNm = obj.bsnNm
          this.popupParams.BusinessNumberPop.bzrgNo = obj.bzrgNo
          this.popupParams.BusinessNumberPop.cstNo = obj.cstNo
          this.popupParams.BusinessNumberPop.addr = obj.addr
          this.popupParams.BusinessNumberPop.faxNo = obj.faxNo
          this.popupParams.BusinessNumberPop.telNo = obj.telNo
          this.customComponent = null
          this.$ekmtcCommon.layerClose('.sample_popup_list')
        } else {
          if (obj !== undefined && obj.negoYN === 'Y') {
            this.customComponent = null
            this.$ekmtcCommon.layerClose('.sample_popup_list')
            console.log('>>>>>> RequestQuotePop')
            this.customComponent = 'RequestQuotePop'
            this.$ekmtcCommon.layerOpen('.sample_popup_list')
          } else {
            this.customComponent = null
            this.$ekmtcCommon.layerClose('.sample_popup_list')
          }
        }
      }
    }
}
</script>

<style>
  .temp_name {
    font-weight: bold;
    font-size: 1.1em;
  }
  .tbl_type,.tbl_type th,.tbl_type td { border:0;}
  .tbl_type { width:100%; border-bottom:1px solid #e1e3eb; font-family:'돋움',dotum; font-size:12px; text-align:center; border-collapse:collapse }
  .tbl_type caption { display:none }
  .tbl_type tfoot { background-color:#f5f7f9; font-weight:bold }
  .tbl_type th { padding:7px 0 4px; border-top:1px solid #e1e3eb; border-right:1px solid #e1e3eb; border-left:1px solid #e1e3eb; background-color:#f6f9ff; color:#075bb9; font-family:'돋움',dotum; font-size:12px; font-weight:bold; }

  .tbl_type td { padding:3px 10px 2px; border:1px solid #e1e3eb; color:#4c4c4c; text-align:left; }
  .tbl_type td.ranking { font-weight:bold }
  .tbl_type td a { color:#F2084F; }
  .tbl_type td span.keep::after   { content: '유지';  color:#fff; background-color:#FFD168; }
  .tbl_type td span.change::after { content: '변경';  color:#fff; background-color:#73BDEE; }
  .tbl_type td span.new::after    { content: '신규';  color:#fff; background-color:#ABF3B3; }
  .tbl_type td span.yellow::after { margin-left:5px; width:45px; letter-spacing:-1px; content: 'SB없이'; color:#777; background-color:#F9F900; }

  .tbl_type td.ok span { width:20px; }
  .tbl_type td.ok span.ok::after   { content: '○';  color:#F1084F; width:20px; text-align:center; background-color:#fff; }
  .tbl_type td.del a { text-decoration: line-through; }
  .tbl_type td input.input_txt { width: 100px; float:right; position: relative;}
  .tbl_type td span.txt_fr { width: 100px; float:right; position: relative; display: inline-block;}
  .tbl_type td .p_params {height: 30px; line-height: 30px;}
</style>
