<template>
  <div class="popup_wrap" style="width:800px;"><!--  popup_wrap  -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont" ><!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONIM010P090.001') }}</h1> <!-- B/L RF Entry -->
      <div class="content_box"><!-- content_box -->
        <table class="tbl_col">
          <colgroup>
            <col width="10%">
            <col width="22%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="10%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONIM010P090.002') }}</th> <!-- SEQ -->
              <th>{{ $t('msg.ONIM010P090.003') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.ONIM010P090.004') }}</th> <!-- Size -->
              <th>{{ $t('msg.ONIM010P090.005') }}</th> <!-- Type -->
              <th>{{ $t('msg.ONIM010P090.006') }}</th> <!-- CGO -->
              <th>{{ $t('msg.ONIM010P090.007') }}</th> <!-- Plug -->
              <th>{{ $t('msg.ONIM010P090.008') }}</th> <!-- Temp -->
              <th>{{ $t('msg.ONIM010P090.009') }}</th> <!-- C/F -->
            </tr>
          </thead>
          <tbody v-if="items.length > 0">
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.cntrNo }}</td>
              <td>{{ item.cntrSzCd }}</td>
              <td>{{ item.cntrTypCd }}</td>
              <td>{{ item.cgoTypCd }}</td>
              <td>
                <input type="checkbox" :checked="item.rfSlecUseYn == 'Y'" disabled="disabled" name="plug" id="plug">
                <label for="plug"><span class="offscreen">chk</span></label>
              </td>
              <td>{{ item.rfSetupTmpr }}</td>
              <td>{{ item.rfTmprUnitCd }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8">{{ $t('msg.ONIM010P090.012') }}</td> <!-- No Data. -->
            </tr>
          </tbody>
        </table>
        <p class="txt_desc asterisk">{{ $t('msg.ONIM010P090.010') }}</p> <!-- 상기 정보는 참조용 이며, 정확한 정보는 선적지 SHIPPER 와 확인 바랍니다. -->
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <!--<button class="button blue lg">확인</button>-->
      <button class="button gray lg" @click="$emit('close')">{{ $t('msg.ONIM010P090.011') }}</button> <!-- 닫기 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import specialCgo from '@/api/rest/trans/specialCgo'

export default {
  name: 'RFEntryPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: ''
        }
      }
    }
  },
  data () {
    return {
      items: [],
      formData: {
        blNo: '',
        flag: 'RH'
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.formData.blNo = this.parentInfo.blNo
      const result = await specialCgo.findSpecialCgoInfoList(this.formData)
      this.items = result.data
    }
  }
}
</script>
