<template>
  <div class="popup_wrap" style="width:800px;"><!--  popup_wrap  -->
      <button class="layer_close" @click="$emit('close')">close</button>
      <div class="popup_cont" ><!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONIM010P080.001') }}</h1> <!-- B/L D/G Entry -->
      <div class="content_box"><!-- content_box -->
        <table class="tbl_col">
          <colgroup>
            <col width="10%">
            <col width="35%">
            <col width="12%">
            <col width="14%">
            <col width="14%">
            <col width="15%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONIM010P080.002') }}</th> <!-- SEQ -->
              <th>{{ $t('msg.ONIM010P080.003') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.ONIM010P080.004') }}</th> <!-- Size -->
              <th>{{ $t('msg.ONIM010P080.005') }}</th> <!-- Type -->
              <th>{{ $t('msg.ONIM010P080.006') }}</th> <!-- UNNO -->
              <th>{{ $t('msg.ONIM010P080.007') }}</th> <!-- IMO Class -->
            </tr>
          </thead>
          <tbody v-if="items.length > 0">
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.cntrNo }}</td>
              <td>{{ item.cntrSzCd }}</td>
              <td>{{ item.cntrTypCd }}</td>
              <td>{{ item.imdunNo }}</td>
              <td>{{ item.imoClasCd }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6" style="text-align:center">{{ $t('msg.ONIM010P080.010') }}</td> <!-- No Data. -->
            </tr>
          </tbody>
        </table>
        <p class="txt_desc asterisk">{{ $t('msg.ONIM010P080.008') }}</p> <!-- 상기 정보는 참조용 이며, 정확한 정보는 선적지 SHIPPER 와 확인 바랍니다. -->
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <!--<button class="button blue lg">확인</button>-->
        <button class="button gray lg" @click="$emit('close')">{{ $t('msg.ONIM010P080.009') }}</button> <!-- 닫기 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import specialCgo from '@/api/rest/trans/specialCgo'

export default {
  name: 'DGEntryPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: ''
        }
      }
    }
  },
  data () {
    return {
      items: [],
      formData: {
        blNo: '',
        flag: 'HZ'
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.formData.blNo = this.parentInfo.blNo
      const result = await specialCgo.findSpecialCgoInfoList(this.formData)
      this.items = result.data
    }
  }
}
</script>
