var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM010P090.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P090.002")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P090.003")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P090.004")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P090.005")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P090.006")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P090.007")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P090.008")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P090.009")))]),
              ]),
            ]),
            _vm.items.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.items, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(index + 1))]),
                      _c("td", [_vm._v(_vm._s(item.cntrNo))]),
                      _c("td", [_vm._v(_vm._s(item.cntrSzCd))]),
                      _c("td", [_vm._v(_vm._s(item.cntrTypCd))]),
                      _c("td", [_vm._v(_vm._s(item.cgoTypCd))]),
                      _c("td", [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            disabled: "disabled",
                            name: "plug",
                            id: "plug",
                          },
                          domProps: { checked: item.rfSlecUseYn == "Y" },
                        }),
                        _vm._m(1, true),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.rfSetupTmpr))]),
                      _c("td", [_vm._v(_vm._s(item.rfTmprUnitCd))]),
                    ])
                  }),
                  0
                )
              : _c("tbody", [
                  _c("tr", [
                    _c("td", { attrs: { colspan: "8" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONIM010P090.012"))),
                    ]),
                  ]),
                ]),
          ]),
          _c("p", { staticClass: "txt_desc asterisk" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONIM010P090.010"))),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "button",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONIM010P090.011")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "22%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "plug" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("chk")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }