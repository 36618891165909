
<template>
  <div class="content widfix">
    <div class="location">
      <!-- location -->
      <ul>
        <li>
          <a
            href="http://52.231.38.202/pubcom/UTIL030G010.html#"
            class="home"
          >home</a>
        </li>
        <li><a href="http://52.231.38.202/pubcom/UTIL030G010.html#">{{ $t('msg.MYIN010G010.038') }}</a></li><!-- 회원가입 -->
        <li>{{ $t('msg.MYIN010G010.014') }} <!-- 약관동의 --></li>
      </ul>
    </div><!-- location -->
    <form id="frm">
      <h1 class="page_title">
        {{ $t('msg.MYIN010G010.014') }} <!-- 약관동의 -->
      </h1>

      <div class="content_box mt10">
        <!-- content_box -->

        <p class="color_black mt5">
          {{ $t('msg.MYIN010G010.015') }} <!-- 약관동의 -->
          <!-- 가입을 원하시면 아래의 ‘서비스약관’을 반드시 읽고 ‘동의’ 체크를 해주세요. -->
        </p>

        <p class="agree_check all">
          <b-form-checkbox
            v-model="check.all"
            class="agree_check all"
            @change="checkboxControl('all')"
          >
            {{ $t('msg.MYIN010G010.016') }} <!-- 약관동의 -->
            <!-- 전체 동의합니다. -->
          </b-form-checkbox>
        </p>

        <p class="agree_check mt15">
          <b-form-checkbox
            v-model="check.a"
            class="agree_check mt15"
            @change="checkboxControl('a')"
          >
            <span class="color_red">[{{ $t('msg.MYIN010G010.017') }}] </span>{{ $t('msg.MYIN010G010.018') }} <!-- [필수] 개인정보수집동의-->
          </b-form-checkbox>
        </p>

        <div class="agree_box">
          <p>
            {{ $t('approval.001') }}
            <!-- 고려해운 주식회사(이하 ‘고려해운’이라 합니다)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 회원가입, 상담, 서비스 제공 등을 위하여 아래와 같은 고객님의 정보를 수집하고자 하며, 이에 다음과 같은 사항에 동의하시는 고객 분들에 한하여 당사의 회원 가입을 받고 있습니다. -->
          </p>
          <p class="tit mt20">
            {{ $t('approval.002') }}
            <!-- 1. 수집하는 개인정보 항목 -->
          </p>
          <ul>
            <li>
              {{ $t('approval.003') }}
              <!-- 회원가입을 위하여 필요한 개인정보 (필수) : 아이디, 비밀번호, 홈페이지 접속지역, 성명, 휴대전화번호, 이메일, 회사명, 사업자등록번호, 회사주소, 회원구분, 처리업무, 전화번호, FAX번호, 자동알림 서비스 신청 -->
            </li>
            <li>
              {{ $t('approval.004') }}
              <!-- 서비스 이용과정이나 정보처리 과정에서 자동으로 생성되는 정보 (자동) : 고객님의 브라우저 종류, 접속로그, 접속 IP정보, 서비스 이용기록, 쿠키 등 -->
            </li>
          </ul>
          <p class="tit mt20">
            {{ $t('approval.005') }}
            <!-- 2. 개인정보 수집 및 이용 목적 -->
          </p>
          <ul>
            <li>
              {{ $t('approval.006') }}
              <!-- 고객님을 고려해운의 회원으로서 관리하려는 목적으로서, 회원제 서비스의 제공, 본인 확인, 당사 서비스의 부정한 이용과 비인가 이용의 방지, 고객님의 가입의사 확인, 서비스 이용에 따른 기록보존, 불만사항 처리, 고지사항 전달을 하고자 합니다. -->
            </li>
            <li>
              {{ $t('approval.007') }}
              <!-- 고객님과 관련된 서비스 제공 및 그에 따른 정산을 위한 목적으로서, 서비스 및 콘텐츠 제공, 대금 결제, 고객님께 최적화된 서비스 제공 및 기능 개선 등에 활용하고자 합니다. -->
            </li>
          </ul>
          <p class="tit mt20">
            {{ $t('approval.008') }}
            <!-- 3. 개인정보의 보유 및 이용기간 -->
          </p>
          <ul>
            <li>
              {{ $t('approval.009') }}
              <!-- 고객님의 동의 하에 수집된 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면 지체 없이 파기됩니다. 다만, 개인정보의 수집 및 이용 목적이 달성된 경우에도, 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령에 의하여 보존될 필요성이 있는 경우 및 사전에 보유기간을 명시하거나 고지한 경우에는 그에 따라 개인정보를 보관할 수 있습니다. -->
            </li>
          </ul>
          <p class="tit mt20">
            {{ $t('approval.010') }}
            <!-- 4. 동의하지 않았을 경우 불이익 -->
          </p>
          <ul>
            <li>
              {{ $t('approval.011') }}
              <!-- 고객님께서는 개인정보 수집·이용에 대한 동의를 거부하실 수 있으나, 위 정보는 서비스 제공에 필수적인 정보이므로 동의를 거부하실 경우 회원가입, 서비스 이용 등이 불가합니다. -->
            </li>
          </ul>
          <p class="mt20">
            {{ $t('approval.032') }}
            <!-- 위의 개인정보수집에 관한 사항을 모두 읽고 인식하였으며, 이에 동의합니다. -->
          </p>
        </div>

        <p class="agree_check">
          <b-form-checkbox
            v-model="check.b"
            class="agree_check mt15"
            @change="checkboxControl('b')"
          >
            <span>{{ $t('approval.012') }}</span>
          </b-form-checkbox>
        </p>
        <div class="agree_box">
          <p class="tit">
            {{ $t('approval.013') }}
            <!-- 1. 수집하는 개인정보 항목 -->
          </p>
          <ul>
            <li>
              {{ $t('approval.014') }}
              <!-- 기존 서비스 개선, 신규서비스 개발 및 마케팅·광고 등에 활용하기 위한 정보 (선택) : 성명, 휴대전화번호, 이메일, 회사명, 선적 아이템, 선적국가, 실화주 등 최근 선적 현황, 선적패턴 -->
            </li>
          </ul>
          <p class="tit mt20">
            {{ $t('approval.015') }}
            <!-- 2. 개인정보 수집 및 이용 목적 -->
          </p>
          <ul>
            <li>
              {{ $t('approval.016') }}
              <!-- 고객님에게 기존 서비스 개선, 신규 서비스 개발 및 마케팅·광고 등에 활용하고자 하는 목적으로서, 고객만족 조사, 서비스 개발·연구, 신규 서비스 연구·개발·특화, 이벤트·프로모션 등 마케팅 정보 제공 및 경품 배송, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 고객님의 서비스 이용에 대한 분석 및 통계, 서비스 정보의 추천, 시장 트렌드 유추, 시장조사, 통계분석자료 활용 등을 하고자 합니다. -->
            </li>
          </ul>
          <p class="tit mt20">
            {{ $t('approval.017') }}
            <!-- 3. 개인정보의 보유 및 이용기간 -->
          </p>
          <ul>
            <li>
              {{ $t('approval.018') }}
              <!-- 고객님의 동의 하에 수집된 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면 지체 없이 파기됩니다. 다만, 개인정보의 수집 및 이용 목적이 달성된 경우에도, 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령에 의하여 보존될 필요성이 있는 경우 및 사전에 보유기간을 명시하거나 고지한 경우에는 그에 따라 개인정보를 보관할 수 있습니다. -->
            </li>
          </ul>
          <p class="tit mt20">
            {{ $t('approval.019') }}
            <!-- 4. 동의하지 않았을 경우 불이익 -->
          </p>
          <ul>
            <li>
              {{ $t('approval.020') }}
              <!-- 고객님께서는 개인정보 수집·이용에 대한 동의를 거부하실 수 있으며, 동의를 거부하실 경우 이벤트&nbsp;프로모션 안내 및 혜택 서비스를 받으실 수 없습니다.  -->
            </li>
          </ul>
          <p class="mt20">
            {{ $t('approval.029') }}
            <!-- 위의 개인정보수집에 관한 사항을 모두 읽고 인식하였으며, 이에 동의합니다. (선택) -->
          </p>
        </div>

        <p class="agree_check">
          <b-form-checkbox
            v-model="check.c"
            class="agree_check mt15"
            @change="checkboxControl('c')"
          >
            <span>{{ $t('approval.021') }}</span>
            <!-- 마케팅 정보 수신 동의 (선택) -->
          </b-form-checkbox>
        </p>
        <div class="agree_box">
          <p class="tit">
            {{ $t('approval.022') }}
            <!-- 1. 마케팅 정보 수신 동의시 아래와 같은 다양한 서비스 및 혜택 정보를 이메일, 문자메시지 등을 통하여 보내드립니다. -->
          </p>
          <ul>
            <li>{{ $t('approval.023') }}</li> <!-- 고객 관심 구간 등 데이터 기반 맞춤 서비스 제공 -->
            <li>{{ $t('approval.024') }}</li> <!-- 뉴스레터, 특가 운임 정보, 신규 항로, 서비스, 기능 등 소식 제공 -->
            <li>{{ $t('approval.025') }}</li> <!-- 할인, 쿠폰, 추가 적립행사, 프로모션 등 이벤트 안내 -->
            <li>{{ $t('approval.026') }}</li> <!-- 혜택, 각종 이벤트 정보 등 광고성 정보 -->
          </ul>
          <p class="tit mt20">
            {{ $t('approval.027') }}
            <!-- 2. 고객님은 마케팅 정보 수신 동의 이후에라도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용할 수 있으나, 특가 운임 정보, 혜택 등 당사의 마케팅 정보를 수신하지 못할 수 있습니다. -->
          </p>
          <p class="mt20">
            {{ $t('approval.030') }}
            <!-- 위 마케팅 정보 수신에 동의합니다. (선택) -->
          </p>
        </div>
      </div><!-- content_box // -->
      <div class="flex_box mt10">
        <button
          class="button blue lg ml_auto mr5"
          type="button"
          v-if="$route.params.checkView === 'fromProfile' || $route.params.checkView === 'beforeProfileHeader' || $route.params.checkView === 'selectInProfile'
            || $route.params.checkView === 'beforeProfile'"
          @click.prevent="saveProfileInfo"
        >
          {{ $t('msg.MYIN050T010.056') }}
          <!-- 확인 -->
        </button>

        <button
          class="button blue lg ml_auto mr5"
          type="button"
          v-else-if="$route.params.checkView === 'changeProfile'"
          @click.prevent="changeProfileAlert"
        >
          {{ $t('msg.MYIN050T010.056') }}
          <!-- 확인 -->
        </button>

        <button
          class="button blue lg ml_auto mr5"
          type="button"
          v-else
          @click.prevent="joinMemberForm"
        >
          {{ $t('msg.MYIN050T010.056') }}
          <!-- 확인 -->
        </button>

        <button
          class="button gray lg"
          v-if="$route.params.checkView === 'fromProfile' || $route.params.checkView === 'selectInProfile'"
          @click.prevent="openCheckAlert($t('msg.MYIN010G010.057'),backToProfile,false)"
        >
          {{ $t('msg.ONEX010P100.033') }}
          <!-- 취소 -->
        </button>

        <button
          class="button gray lg"
          v-else
          @click.prevent="$router.push(
            '/main'
          )"
        >
          {{ $t('msg.ONEX010P100.033') }}
          <!-- 취소 -->
        </button>
      </div>
    </form>
    <EHeaderUtil v-show="false" ref="EHeaderUtil" />
  </div>
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'
import store from '@/store/index'
import member from '@/api/rest/user/member'
import ProfileApi from '@/api/rest/user/profile'
import AuthApi from '@/api/services/auth'

export default {
  name: 'LoginApproval',
  components: {
    EHeaderUtil: () => import('@/components/layout/EHeaderUtil')
  },
  data () {
    return {
      agreeall: false,
      agreea1: false,
      agreea2: false,
      agreea3: false,

      agreea11: false,
      agreea22: false,
      agreea33: false,

      messageFlag: 1,
      modalShow: false,
      check: {
        all: false,
        a: false,
        b: false,
        c: false
      },
      loginResp: null,
      beforeLoginResp: null,
      req: {}
    }
  },
  computed: {
    ...rootComputed,
    requireLogin () {
      return this.$store.getters['auth/requireLogin']
    },
    compName () {
      return this.$store.getters['auth/compName']
    },
    isEkmtc () {
      return sessionStorage.getItem('loginId').indexOf('EKMTC_') !== -1
    }
  },
  created () {
  },
  mounted () {
    // header 로그인창 컨트롤
    this.$el.addEventListener('mousedown', this.stopProp)
    document.body.addEventListener('mousedown', this.changeLoginBoxStatus)
  },
  methods: {
    ...rootMethods,
    changeLoginBoxStatus () {
      this.$store.commit('auth/requireLogin', false)
    },
    stopProp (e) {
      e.stopPropagation()
    },
    checkboxControl (type) {
      if (type !== 'all') {
        if (this.check.a && this.check.b && this.check.c) {
          this.check.all = true
        } else {
          this.check.all = false
        }
      } else {
        this.check.a = this.check.all
        this.check.b = this.check.all
        this.check.c = this.check.all
      }
    },
    openCheckAlert (msg, onConfirm, flag) {
      let obj = {
        alertType: flag === true ? 'normal' : 'simple',
        onConfirm: onConfirm,
        showXclose: true,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: this.$t('msg.ADMN030G020.043'),
        customConfirmBtnClass: 'button blue lg',
        customConfirmBtnText: this.$t('msg.ADMN030G020.042'),
        useConfirmBtn: true,
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
      // this.$Simplert.open(obj)
    },
    setMsgFlag () {
        if (this.check.a) {
          if (!this.$route.params.checkView) {
            this.$router.push(
                {
                  name: 'join-member-form',
                  params: {
                    agreea1: this.check.a,
                    agreea2: this.check.b,
                    agreea3: this.check.c
                  }
            })
          } else {
            if (this.$route.params.profile === 'N') {
                // if (this.$route.params) {
                  const approval = {
                    mdtyPsnlInfCltnCnstYn: this.check.a ? 'Y' : 'N',
                    choPsnlInfCltnCnstYn: this.check.b ? 'Y' : 'N',
                    recvCnstYn: this.check.c ? 'Y' : 'N'
                  }
                  // this.$route.params.req.profile = { ...this.$route.params.req.profile, ...approval }
                  this.req = this.$_.clone(this.$route.params.req)
                  this.req.profile.mdtyPsnlInfCltnCnstYn = approval.mdtyPsnlInfCltnCnstYn
                  this.req.profile.choPsnlInfCltnCnstYn = approval.choPsnlInfCltnCnstYn
                  this.req.profile.recvCnstYn = approval.recvCnstYn

                  let obj = {
                    onConfirm: this.saveProfile,
                    customCloseBtnClass: 'button blue lg',
                    customCloseBtnText: this.$i18n.t('msg.MYIN050T010.057'),
                    customConfirmBtnText: this.$i18n.t('msg.MYIN050T010.056'),
                    useConfirmBtn: true,
                    customConfirmBtnClass: 'button blue lg',
                    message: this.$i18n.t('msg.MYIN050T010.058'),
                    type: 'info'
                  }
                  this.$ekmtcCommon.alert(obj)
                // }
            } else {
                const params = {
                  mdtyPsnlInfCltnCnstYn: this.check.a ? 'Y' : 'N',
                  choPsnlInfCltnCnstYn: this.check.b ? 'Y' : 'N',
                  recvCnstYn: this.check.c ? 'Y' : 'N',
                  compName: this.compName,
                  userName: this.auth.userName,
                  userId: this.$route.params.profile.userId,
                  loginId: sessionStorage.getItem('loginId')
              }
              this.updateMemberApproval(params)
            }
          }
        }
    },
    checkValiation () {
        let isOk = true
        let alertMsg = ''
        let rtnVal = ''

        if (!this.check.a) { // 필수 no 체크
          this.messageFlag = 1
          alertMsg = this.$t('msg.MYIN010G010.023') //'개인정보수집동의서(필수)에 동의하지 않으면 당사 서비스를 이용할 수 없습니다.당사 서비스 이용을 위해서는 개인정보수집동의서(필수)에 동의 부탁드립니다.'
          isOk = false
        } else { // 필수 체크
            if ((this.check.b === true) && (this.check.c === false)) {
              this.messageFlag = 3
              alertMsg = this.$t('msg.MYIN010G010.024') //'귀하께서 마케팅 정보 수신 동의(선택)를 하지 않았습니다. 이벤트•프로모션 안내 및 각종 혜택 서비스 등 마케팅 정보를 수신하지 못할 수 있습니다. 계속진행 하시겠습니까?'
              isOk = false
            } else if ((this.check.b === false) && (this.check.c === true)) {
              this.messageFlag = 2
              alertMsg = this.$t('msg.MYIN010G010.025') // '이벤트•프로모션 안내 및 각종 혜택 서비스 등 마케팅 정보 수신을 위해서는 상기 개인정보수집동의서(선택)에도 동의를 하셔야 합니다. 마케팅 정보 수신을 위해서는 상기 개인정보수입동의서(선택) 동의 후 진행 바랍니다.'
              isOk = false
            } else if ((this.check.b === false) && (this.check.c === false)) {
              this.messageFlag = 4
              alertMsg = this.$t('msg.MYIN010G010.026') //'귀하께서 개인정보수집동의서(선택) 및 마케팅 정보 수신(선택)에 동의를 하지 않았습니다. 이벤트•프로모션 안내 및 각종 혜택 서비스 등 마케팅 정보를 수신하지 못할 수 있습니다. 계속진행 하시겠습니까?'
              isOk = false
            } else {
              // 모두 동의
              isOk = true
            }
        }

        rtnVal = {
          msg: alertMsg,
          isOk: isOk,
          messageFlag: this.messageFlag
        }

        return rtnVal
      },

      joinMemberForm () {
        const rtnValue = this.checkValiation()

        if (!rtnValue.isOk) {
          this.openCheckAlert(rtnValue.msg, this.setMsgFlag, true)
        } else {
            this.$router.push(
            {
              name: 'join-member-form',
              params: {
                agreea1: this.check.a,
                agreea2: this.check.b,
                agreea3: this.check.c
              }
            })
        }
      },
      saveProfileInfo () {
        const rtnValue = this.checkValiation()

        if (!rtnValue.isOk) {
          this.openCheckAlert(rtnValue.msg, this.setMsgFlag, true)
        } else {
          if (this.$route.params.profile === 'N') {
              // if (this.$route.params) {
                const approval = {
                  mdtyPsnlInfCltnCnstYn: this.check.a ? 'Y' : 'N',
                  choPsnlInfCltnCnstYn: this.check.b ? 'Y' : 'N',
                  recvCnstYn: this.check.c ? 'Y' : 'N'
                }

                // this.$route.params.req.profile = { ...this.$route.params.req.profile, ...approval }
                  this.req = this.$_.clone(this.$route.params.req)
                  this.req.profile.mdtyPsnlInfCltnCnstYn = approval.mdtyPsnlInfCltnCnstYn
                  this.req.profile.choPsnlInfCltnCnstYn = approval.choPsnlInfCltnCnstYn
                  this.req.profile.recvCnstYn = approval.recvCnstYn

                let obj = {
                  onConfirm: this.saveProfile,
                  customCloseBtnClass: 'button blue lg',
                  customCloseBtnText: this.$i18n.t('msg.MYIN050T010.057'),
                  customConfirmBtnText: this.$i18n.t('msg.MYIN050T010.056'),
                  useConfirmBtn: true,
                  customConfirmBtnClass: 'button blue lg',
                  message: this.$i18n.t('msg.MYIN050T010.058'),
                  type: 'info'
                }
                this.$ekmtcCommon.alert(obj)
              // }
          } else {
              const params = {
                  mdtyPsnlInfCltnCnstYn: this.check.a ? 'Y' : 'N',
                  choPsnlInfCltnCnstYn: this.check.b ? 'Y' : 'N',
                  recvCnstYn: this.check.c ? 'Y' : 'N',
                  compName: this.compName,
                  userName: this.auth.userName,
                  userId: this.$route.params.profile.userId,
                  loginId: sessionStorage.getItem('loginId')
              }
              this.updateMemberApproval(params)
          }
        }
      },
      async updateMemberApproval (param) {
        // console.log('@@@@@ updateMemberApproval param ', param)

        const params = param

        if (this.$route.params && this.$route.params !== 'fromProfile') {
          params.userCtrCd = this.$route.params.profile.userCtrCd
          params.userId = this.$route.params.profile.userId
        }

        await member.updateMemberApproval(params)
                .then(res => {
                  if (res.data.count === 1) {
                    this.openCheckAlert(this.$t('tem.CMAT013'), () => {
                      if (this.$route.params.checkView === 'selectInProfile') {
                        this.$router.push(
                        {
                          name: 'profile',
                          params: {
                            checkView: 'updateProfile',
                            profileIdx: this.$route.params.profileIdx,
                            profileData: this.$route.params.profile,
                            profile: 'update'
                          }
                        })
                      } else if (this.$route.params.checkView === 'beforeProfileHeader') {
                        this.$refs.EHeaderUtil.login('APPR', this.$route.params.loginInfo)
                      } else {
                        setTimeout(() => {
                          this.$router.push('/main').catch(() => {})
                        }, 300)
                      }
                    }, false)
                  }
                })
      },
      handleOk () {
        window.location.href = '/sample/join-member-form'
      },
      saveProfile () {
        ProfileApi.saveProfile(this.req)
          .then((response) => {
            if (this.req.profile.useYn === 'Y') {
              let selectedProfile = response.data
              selectedProfile.tmpEiCatCd = ''
              store.commit('auth/selectedProfile', selectedProfile)
              window.scrollTo(0, 0)
              this.profileList = []
              this.changeProfile(selectedProfile)
            } else {
              this.customAlert(this.$i18n.t('msg.ONIM099P070.005'))
            }
          })
          .catch((e) => {
            console.log(e)
          })
      },
      async changeProfile (selectedProfile) {
        let req = {}
        let preUserId = ''

        if (this.$route.params.checkView === 'changeProfile') {
          preUserId = this.$route.params.profile.preUserId
          selectedProfile = this.$route.params.profile

          req = {
            userId: this.$route.params.profile.userId,
            jwt: this.$store.state.auth.loginInfo.jwt
          }
        } else {
          preUserId = selectedProfile.preUserId

          req = {
            userId: selectedProfile.userId,
            jwt: this.$store.state.auth.loginInfo.jwt
          }
        }

        // GA dataLayer 추가
        window.dataLayer[0].user_profile = selectedProfile.picNm
        window.dataLayer[0].ga_user_id = selectedProfile.userId
        window.dataLayer[0].id = selectedProfile.preUserId

        window.dataLayer.push({
          event: 'profile_change',
          ga_user_id: selectedProfile.userId,
          user_contact_region: selectedProfile.userCtrCd,
          user_member_type: selectedProfile.cstCatCd,
          user_pic: selectedProfile.eiCatCd,
          is_login: 'Y',
          user_profile: selectedProfile.picNm,
          id: selectedProfile.preUserId
        })

        const params = {
                  mdtyPsnlInfCltnCnstYn: this.check.a ? 'Y' : 'N',
                  choPsnlInfCltnCnstYn: this.check.b ? 'Y' : 'N',
                  recvCnstYn: this.check.c ? 'Y' : 'N',
                  compName: this.compName,
                  userName: this.auth.userName,
                  userId: this.$route.params.profile.userId,
                  loginId: sessionStorage.getItem('loginId')
              }

        await this.updateMemberApproval(params)

        // profile pre_user_id 로 변경
        if (!this.isEmpty(preUserId) &&
            sessionStorage.getItem('loginId') !== preUserId) {
          sessionStorage.setItem('loginId', preUserId)
          // [[ 로그인 추가 프로필 변경 시 user_id가 변경되면 재 로그인...
          let upperForm = {
              userId: preUserId,
              userPwd: preUserId
            }
          await AuthApi.logInfo(upperForm).then(loginResp => {
            let respCode = loginResp.headers.respcode
            if (respCode === 'C0000') {
              this.beforeLoginResp = loginResp.data
              let cstCd = null
              if (upperForm.userId.indexOf('EKMTC_') === -1) {
                sessionStorage.setItem('cstCd', loginResp.data.cstCd)
              } else {
                sessionStorage.setItem('cstCd', 'KMT013')
              }
              sessionStorage.setItem('loginId', upperForm.userId)
              sessionStorage.setItem('logCatCd', loginResp.data.logCatCd)

              req.jwt = this.beforeLoginResp.data.jwt
            }
          }).catch(e => {
            console.log(e)
          })
          // 로그인 추가 ]]
        }

        await AuthApi.profileLogin(req)
          .then((resp) => {
            if (this.isEmpty(this.beforeLoginResp)) {
            this.beforeLoginResp = JSON.parse(
              JSON.stringify(this.$store.state.auth.loginInfo)
            )
            }
            this.loginResp = resp
            this.saveLoginData(selectedProfile)
          })
          .catch((e) => {
            console.log(e)
          })
      },
      saveLoginData (selectedProfile) {
        let respCode = this.loginResp.headers.respcode
        let respMsg = this.$i18n.t(`js.auth.${respCode}`)
        let jwt = this.isEkmtc
          ? this.beforeLoginResp.jwt
          : this.loginResp.data.jwt

        if (respCode === 'C0000') {
          this.saveLogin({
            user: this.loginResp.data,
            jwt: jwt,
            autoLogin: true,
            profile: selectedProfile,
            loginInfo: this.beforeLoginResp
          })
            .then((r) => {
              this.$store.commit('updatePersonalizedFlag')
              this.$store.commit('updateIsOpenPersonalized', true)
              setTimeout(() => {
                this.$router.push('/main').catch(() => {})
              }, 300)
            })
            .catch((r) => {
              console.log(r)
            })
        } else {
          console.log('프로필저장 후 프로필로그인 실패')
        }
      },
      isEmpty (value) {
        if (
          value === '' ||
          value === null ||
          value === undefined ||
          (value != null &&
            typeof value === 'object' &&
            !Object.keys(value).length)
        ) {
          return true
        } else {
          return false
        }
      },
      backToProfile () {
        setTimeout(() => {
            this.$router.push(
              {
                name: 'profile',
                params: {
                    checkView: 'fromLogAppv',
                    profile: 'back',
                    profileData: this.$route.params.checkView === 'selectInProfile' ? this.$route.params.profile : this.$route.params.req.profile
                }
              })
          }, 100)
      },
      changeProfileAlert () {
        const rtnValue = this.checkValiation()

        if (!rtnValue.isOk) {
          if (rtnValue.messageFlag !== 1) {
            this.openCheckAlert(rtnValue.msg, this.changeProfile, true)
          } else {
            this.openCheckAlert(rtnValue.msg, null, true)
          }
        } else {
          let obj = {
              onConfirm: this.changeProfile,
              customCloseBtnClass: 'button blue lg',
              customCloseBtnText: this.$i18n.t('msg.ONIM010P070.008'), // 취소
              customConfirmBtnText: this.$i18n.t('msg.MYIN050T010.056'), // 확인
              useConfirmBtn: true,
              customConfirmBtnClass: 'button blue lg',
              message: this.$i18n.t('msg.MYIN050T010.076'), // 프로필을 교체하시겠습니까? // WMH
              type: 'info'
            }
            this.$ekmtcCommon.alert(obj)
        }
      }
  }
}
</script>
